import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { showError, authHeader, subDomain, ExternalDomain, LoggedOutDetails } from '../../../common/Common';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { SiteFooter } from '../../layout/SiteFooter';
import { GetPaymentLinksById, GetTextActiveByPaymentId, GetEmailActiveByPaymentId, GetPaymentIntent, UpdatePaymentLinkExpiry, GetEmbedLinkDocuSign, SaveSignedStatus, UpdateAutoPay, GetPolicyFees, GetCustomerDetailbyAisAccountNumber, MakeAISPayment, GetAllRetriveAccountInfo, GetPFEmbedLinkDocuSign, CheckDocumentSigningStatus } from '../../../services/PaymentServices';
import { GetAISResponseByPaymentId } from '../../../services/AISServices';
import { GetUserProfile, UpdateEmailForInsured, UpdateCommPhoneNumberForInsured } from '../../../services/UserServices';
import { Error } from '../../../common/Setting';
import { FlowGroups, PartnerId } from '../../../common/Enum';
import validator from 'validator';
import $ from 'jquery';
import { LoaderContents } from "../../layout/LoaderContents";
import { CustomCurrencyFormat, DateFormat, stripePromise } from "../../../common/Setting";
import CurrencyFormat from 'react-currency-format';
import { Elements } from "@stripe/react-stripe-js";
import Modal from 'react-modal';
import CheckoutForm from './CheckoutForm';
import AgavePaymentPage from './AgavePaymentPage';
import { GetStripeCustomerNonAuthorized } from '../../../services/PaymentServices';
import { Buffer } from 'buffer';

class MakePayment extends Component {
    constructor(props) {
        super(props);
        this.IsAutoPay = this.IsAutoPay.bind(this);
        this.state = {
            UserDetails: authHeader(),
            queryParams: new URLSearchParams(window.location.search),
            mdl: [],
            CreditCardExpiryMonth: "",
            Errors: {},
            SaveLoaderStatus: false,
            IsAutoPay: true,
            UpdateAutoPayMdl: {
                IsAutoPay: true,
                PaymentId: ''
            },
            UserProfileMdl: [],
            fullName: "",
            PaymentAmountOptions: 1,
            apr: null,
            PFCoverageType: null,
            DBCoverageType: null,
            address: null,
            policyEffectiveDate: null,
            termLength: 0,
            pmtAmount: null,
            phoneNumber: null,
            SubDomain: subDomain(),
            IsTextActive: false,
            IsEmailActive: false,
            IsTermsandConditions: false,
            PFIsTermsandConditions: false,
            PIFIsTermsandConditions: false,
            clientSecret: '',
            transferGroup: '',
            customerId: '',
            isExpired: false,
            isPaid: false,
            RemainingBalance: null,
            RemainingPayments: null,
            LoaderStatus: false,
            CustomerDetailMdl: {},
            createdByName: null,
            premiumFinanceAgreementLink: null,
            totalpremium: null,
            url: "",
            DownPaymentPaidStatus: false,
            ispremiumfinance: false,
            PFAMonthlyAmount: null,
            PFATotalOfPayments: null,
            PFACurrentAPR: null,
            PFATotalPremiumAmount: null,
            PFATotalDownPayAmount: null,
            paymentProcessId: null,
            fppUrl: "",
            achMemoId: Math.random().toString().substring(2, 8),
            urlEncodedValue: "",
            SelectedType: null,
            isdirectbilling: false,
            DBIsTermsandConditions: false,
            DBDisclosureAgreementIsTermsandConditions: false,
            DBPaymentAmount: null,
            DBServiceFee: 0,
            DBDownPayAmount: null,
            DBTermLength: 0,
            DBReport: null,
            PFPaymentAmount: null,
            PFDownPayAmount: null,
            EmbedLink: null,
            PFTermLength: 0,
            PFReport: null,
            PFAPR: 0,
            isMultiplePolicies: false,
            AllCompanyData: [],
            selectedpolicy: [],
            paymentmode: null,
            checked: true,
            checkedItems: {},
            showModal: false,
            modalMessage: "",
            isPFPaid: false,
            isDBPaid: false,
            isPaidInFull: false,
            isPFShow: false,
            isDBShow: false,
            LoggedOutDetails: LoggedOutDetails(),
            isPayFull: false,
            isDownPayment: false,
            isMonthlyPayment: false,
            showIframe: false,
            agreementStatus: "You have not yet agreed to the terms.",
            docuSignUrl: "",
            isSignedAgreement: false,
            dbdocuSignUrl: "",
            dbisSignedAgreement: false,
            DBDocusignLoaderStatus: false,
            ServiceFee: 5,
            isDirectBilling: false,
            isPremiumFinance: false,
            AllPolicyTaxes: [],
            TaxesAmount: 0,
            scheduleDate: '',
            paymentAmount: '',
            otherAmount: '',
            AlreadyAutoPay: false,
            billingAccountNumber: null,
            linknotexists: false,
            insuredId: null,
            paymentAccount: {
                aisAccountNumber: '',
                paymentAccountType: null,
                cardToken: '',
                cardAccountNumber: '',
                bankAbaNumber: ''
            },
            isAISAutoPay: null,
            totalPolicyPremium: null,
            subDomainId: null,
            isReturn: false,
            emailFormCompleted: false,
            DBDisclosurePath: null,
            isCancelled: false,
            emailValid: true,
            phoneNumberValid: true,
            isSelectedAccount: false,
            isSelectedPaymentAmount: false,
            selectedPaymentMethod: "CARD",
            modifiedDate: null,
            isAlredyPFPaid: false,
            techFee: 0.035,
            calculatedTechFee: 0,
            deficiencyAmount: 0,
            cancelledDate: null,
            PFMonthlyPayment: null
        }
        this.iframeRef = React.createRef();
        this.handleChangePaymentData = this.handleChangePaymentData.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleAmountChange = this.handleAmountChange.bind(this);
        this.handleOtherAmountChange = this.handleOtherAmountChange.bind(this);
        this.handleOtherBlur = this.handleOtherBlur.bind(this);
    }

    // this is a onload method
    componentDidMount() {
         this.setState({ LoaderStatus: true });
        let pmtAmt;
        $("input[name='phoneNumber']").keyup(function () {
            console.log("asdfghrd")
            $(this).val($(this).val().replace(/^(\d{3})(\d{3})(\d+)$/, "($1)$2-$3"));
        });
        if (this.state.queryParams.has("PaymentId")) {
            UpdatePaymentLinkExpiry(this.state.queryParams.get("PaymentId")).then(data => { });

            GetPaymentLinksById(this.state.queryParams.get("PaymentId")).then(async data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        this.setState({ linknotexists: false });
                        let res = response.data;
                        console.log("res======>", res);
                        this.setState({ mdl: res });
                        pmtAmt = res.dbDownPayAmount != null ? (res.dbDownPayAmount).toFixed(2) : 0;
                        this.setState({
                            pmtAmount: pmtAmt, totalpremium: res.paymentAmount, isSignedAgreement: res.isSignedAgreement, fullName: res.name, isExpired: res.isExpired, isPaid: res.paymentStatus, phoneNumber: res.phoneNumber, apr: res.apr, termLength: res.termLength,
                            address: res.address, policyEffectiveDate: res.policyEffectiveDate, createdByName: res.createdByName, url: res.subDomain, paymentProcessId: res.paymentProcessId, isCancelled: res.isCancelled, isStetson: res.isStetson,
                            DBPaymentAmount: res.dbPaymentAmount, DBDownPayAmount: res.dbDownPayAmount, DBTermLength: res.dbTermLength, DBReport: res.dbReport, subDomainId: res.subDomainId, isReturn: res.isReturn, agencyName: res.agencyName, PIFServiceFee: res.pifServiceFee,
                            PFPaymentAmount: res.pfPaymentAmount, PFDownPayAmount: res.pfDownPayAmount, PFTermLength: res.pfTermLength, PFReport: res.pfReport, PFAPR: res.pfapr, billingAccountNumber: res.billingAccountNumber, DBServiceFee: res.dbServiceFee, isProcessing: res.isProcessing,
                            isPFShow: res.isPFPaid, isDBShow: res.isDBPaid, isPaidInFull: res.isPaidInFull, EmbedLink: res.embedLink, DBCoverageType: res.dbCoverageType, earnedPremiumPercentage: res.mepPercentage, showIframe: false, isFinanceProPF: res.isFinanceProPF,
                            PFCoverageType: res.pfCoverageType, isDirectBilling: res.isDirectBilling, isPremiumFinance: res.isPremiumFinance, DBEmbedLink: res.dbembedLink, isSignedAgreement: res.dbisSignedAgreement, totalPolicyPremium: res.paymentAmount, insuredId: res.createdFor, DBDisclosurePath: res.dbDisclosurePath,
                            modifiedDate: res.modifiedDate, isScheduled: res.isScheduled, isAlredyPFPaid: res.isPFPaid, isPaidToday: res.isPaidToday, deficiencyAmount: res.deficiencyAmount, cancelledDate: res.cancelledDate, PFMonthlyPayment: res.pfMonthlyPayment
                        });
                        if (res.isPFPaid) {
                            this.setState({ totalpremium: (res.pfPaymentAmount * res.termLength) });
                        }
                        else if (res.isDBPaid) {
                            this.setState({ totalpremium: ((res.dbPaymentAmount - res.deficiencyAmount) * res.termLength) });
                        }
                        else if (res.isPaidInFull || res.paymentStatus) {
                            this.setState({ totalpremium: (res.remainingBalance) });
                        }
                        else {
                            this.setState({ totalpremium: (res.paymentAmount) });
                        }
                        if (res.subDomainId == '3') {
                            this.setState({ PIFServiceFee: 5 });
                        }

                        if (res.paymentStatus) {
                            this.setState({ RemainingBalance: res.remainingBalance, RemainingPayments: res.remainingPayments });
                            if (res.isPFPaid) {
                                this.setState({ PIFServiceFee: 5 });
                            }
                        }
                        else {
                            this.setState({ RemainingBalance: res.paymentAmount, RemainingPayments: res.termLength });
                        }
                        this.setState({ emailFormCompleted: (res.subDomain == "rt" && res.paymentStatus) ? true : false });
                        if (res.paymentStatus) {
                            if (res.billingAccountNumber) {
                                const requestModel = {
                                    BillingAccountNumber: res.billingAccountNumber.toString(),
                                    ConsumerReferenceNumber: "",
                                    SubDomainId: res.subDomainId
                                };
                                const resp = await GetAllRetriveAccountInfo(requestModel);
                                const response1 = resp.data?.data?.uiBillingAccountInfo[0];
                                const isAutoPay = response1?.autoPaySetup == "YES";
                                this.setState({ isAISAutoPay: isAutoPay });
                                var customerPaymentDetail = await GetCustomerDetailbyAisAccountNumber(res.billingAccountNumber.toString())
                                this.setState({
                                    paymentAccount: {
                                        aisAccountNumber: customerPaymentDetail.data?.primaryCustomerId,
                                        paymentAccountType: customerPaymentDetail.data?.paymentType,
                                        cardAccountNumber: customerPaymentDetail.data?.ccNumber,
                                        cardToken: customerPaymentDetail.data?.token,
                                        bankAbaNumber: customerPaymentDetail.data?.achRoutingNumber,
                                        last4: (customerPaymentDetail.data?.paymentType == 2 ? customerPaymentDetail.data?.achAccountNumber?.slice(-4) : customerPaymentDetail.data?.ccNumber?.slice(-4))
                                    }
                                })
                            }
                        }
                        if (!res.paymentStatus) {
                            let docuSignMdl = {
                                PaymentId: this.state.queryParams.get("PaymentId"),
                                Name: res.name,
                                EmailAddress: res.emailAddress,
                                EmbedLink: null,
                                ClickwrapId: null,
                                Pfordb: null,
                                SubDomainId: res.subDomainId
                            }
                            if ((JSON.parse(localStorage.getItem("LoggedOutDetails"))?.isFinanceProPF && !JSON.parse(localStorage.getItem("LoggedOutDetails"))?.isAISDB) || (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl == "knight") || (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl == "rt")) {
                                if (!res.isSignedAgreement && sessionStorage.getItem('documentSigned') != 'true') {
                                    let paymentLinkMdl = {
                                        PaymentId: this.state.queryParams.get("PaymentId"),
                                        Name: res.name,
                                        EmailAddress: res.emailAddress,
                                        SubDomainId: res.subDomainId
                                    }
                                    let response = await GetPFEmbedLinkDocuSign(paymentLinkMdl);
                                    if (response.message !== Error) {
                                        if (response.status === true && data) {
                                            this.setState({ docuSignUrl: response.data });
                                        } else {
                                            showError(response?.message);
                                        }
                                    }
                                }
                            }
                            if ((JSON.parse(localStorage.getItem("LoggedOutDetails"))?.isFinanceProPF && JSON.parse(localStorage.getItem("LoggedOutDetails"))?.isAISDB) || (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl == "knight") || (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl == "rt")) {
                                if (!res.dbIsSignedAgreement) {
                                    docuSignMdl.EmbedLink = res.dbembedLink;
                                    docuSignMdl.ClickwrapId = res.dbClickwrapId;
                                    docuSignMdl.Pfordb = false;
                                    let response = await GetEmbedLinkDocuSign(docuSignMdl);
                                    if (response.message !== Error) {
                                        if (response.status === true) {
                                            this.setState({ dbdocuSignUrl: response.data });
                                        } else {
                                            showError(response.message);
                                        }
                                    }
                                }
                            }
                        }

                        this.setState(Object.assign(this.state.mdl, { emailAddress: res.emailAddress }));
                        this.setState(Object.assign(this.state.UpdateAutoPayMdl, { IsAutoPay: res.isAutoPay, AlreadyAutoPay: res.isAutoPay }));
                        this.setState({ ispremiumfinance: res.premiumFinance, isdirectbilling: res.directBillingFinance, DownPaymentPaidStatus: res.paymentStatus });

                        if (!res.paymentStatus && !res.isProcessing) {
                            if (this.state.isSignedAgreement || sessionStorage.getItem('documentSigned') === 'true') {
                                this.setState({ PFIsTermsandConditions: true });
                                this.onButtonEvent('PayMonthly')
                            }
                        }

                        this.setState({ LoaderStatus: false });
                    } else {
                        this.setState({ linknotexists: true });
                    }
                }
            });

            GetTextActiveByPaymentId(this.state.queryParams.get("PaymentId")).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        let res = response.data;
                        this.setState({ IsTextActive: res });
                    } else {
                        showError(response.message);
                    }
                }
            })

            GetEmailActiveByPaymentId(this.state.queryParams.get("PaymentId")).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        let res = response.data;
                        this.setState({ IsEmailActive: res });
                    } else {
                        showError(response.message);
                    }
                }
            })

            GetPolicyFees(this.state.queryParams.get("PaymentId")).then(res => {
                let response = res;
                if (response.status === true) {
                    this.setState({ AllPolicyTaxes: response.data });
                    this.setAmountAndDescription(response.data);
                } else {
                    showError(response.message);
                }
            })
        }
        else {
            if (this.state.UserDetails != null) {
                this._GetUserProfile();
            }
        }

        $("input[name='phoneNumber']").keyup(function () {
            $(this).val($(this).val().replace(/^(\d{3})(\d{3})(\d+)$/, "($1)$2-$3"));
        });
        const modal = document.getElementById('modalPayment'); // Replace with your modal's ID
        if (modal) {
            modal.addEventListener('hidden.bs.modal', this.handleModalClose);
        }
    }

    componentDidUpdate() {
        if (sessionStorage.getItem("paymentStatus")) {
            sessionStorage.removeItem("paymentStatus")
            window.location.reload()
        }
    }

    componentWillUnmount() {
      // Clean up event listener
      const modal = document.getElementById('modalPayment');
      if (modal) {
        modal.removeEventListener('hidden.bs.modal', this.handleModalClose);
      }
    }

    pollForSigningStatus() {
        const Iframe = document.querySelector('iframe');
        if (Iframe && Iframe.src.includes('signing_complete')) {
            this._OpenStripePayment();
        }
    }

    setAmountAndDescription = (data) => {
        let amount = 0;
        data.forEach((item) => {
            amount += parseFloat(item.amount);
        });
        this.setState({ TaxesAmount: amount });
    }

    _GetPaymentIntent = async () => {
        const amount = this.state.pmtAmount;
        this._encodeUrlValues(amount);
        await GetPaymentIntent(amount * 100, this.state.fullName, this.state.emailAddress, this.state.phoneNumber, this.state.UpdateAutoPayMdl, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl).then((res) => {
            this.setState({ clientSecret: { clientSecret: res.clientSecret }, transferGroup: res.transferGroup, customerId: res.customerId });
            this.setState({ SaveLoaderStatus: false });
        });
    }

    _UpdateAutoPay = (updateAutoPayMdl) => {
        UpdateAutoPay(updateAutoPayMdl).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState(Object.assign(this.state.UpdateAutoPayMdl, { IsAutoPay: response.data.isAutoPay }));
                } else {
                    showError(response.message);
                }
            }
        });
    }

    _PayNext_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this.onButtonEvent("PayNext");
        };
    };

    _ModelPayment_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this._SelectPaymentMethod();

        };
    };
    _SelectPaymentMethod = () => {
        var result = this.Validation();
        if ((this.state.subDomain == 'rt' && result && this.state.updatedEmail ) && !this.state.DownPaymentPaidStatus && !this.state.isPFPaid) {
            window.$('#modalEmailConfirm').modal('show');
        } else {
            if ((this.state.phoneNumberValid && this.state.emailValid) || this.state.DownPaymentPaidStatus) {                              
                this.handleSubmit();
                this.setState({ isAgavePayment: !this.state.isPFPaid || !this.state.isStetson })
                this.setState({ clientSecret: '' });
                this.setState({ SaveLoaderStatus: true });
                this._GetPaymentIntent();
                if (this.state.isDBPaid) {
                    if (result) {
                        window.$('#modalPayment').modal('show');
                    }
                }
                else {
                    window.$('#modalPayment').modal('show');
                }
            }
        }
    }
    //for select payment amount option
    onChangePaymentAmountOption = (event) => {
        let SelectedOption = parseInt(event.target.value);
        this.setState({ PaymentAmountOption: SelectedOption });
    }
    Validation = () => {
        let errors = {};
        let count = 0;
        if (this.state.UpdateAutoPayMdl.IsAutoPay) {
            if (!this.state.IsTextActive && !this.state.IsEmailActive && !this.state.isPaidInFull) {
                errors['IsEmpty_IsTextorEmailActive'] = true;
                errors['IsTextorEmailActiveEmptyMsg'] = "Please select either Email or Text to receive notifications for AutoPay.";
                count++;
            }
        }
        if (!this.state.DBDisclosureAgreementIsTermsandConditions && !this.state.isPaidInFull) {
                errors['IsEmpty_DBDisclosureAgreementIsTermsandConditions'] = true;
                errors['DBDisclosureAgreementIsTermsandConditionsEmptyMsg'] = "Please agree to the terms and conditions";
                count++;
        }
        if ((this.state.subDomain.toLowerCase() === "rt" && !this.state.isPFPaid && !this.state.updatedEmail) || (this.state.isPayFull && this.state.subDomain.toLowerCase() !== "geico" && !this.state.updatedEmail && this.validateEmail())) {
            this.state.emailValid = false;
            count++;
        }
        else if (!this.state.emailValid && !this.state.isPFPaid) {
            this.state.emailValid = false;
            count++;
        }
        else {
            this.state.emailValid = true;
        }

        if (this.state.IsTextActive && this.state.UpdateAutoPayMdl.IsAutoPay) {
            if (!this.state.phoneNumberValid) {
                this.state.textValid = false;
                count++;
            }
        }
        else {
            this.state.textValid = true;
        }

        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        } else {
        return false;
        }
    }

    // get user profile
    _GetUserProfile = async () => {
        GetUserProfile().then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    if (response.data != null) {
                        this.setState({ UserProfileMdl: response.data });
                        this.setState({ mdl: response.data.userInfoMdl });
                        this.setState(Object.assign(this.state.mdl, { emailAddress: response.data.email }));
                        this.setState({ fullName: response.data.userInfoMdl.fullName });
                    }
                }
                else {
                    showError(response.message);
                }
            }
        });
    }
    //validateEmail
    validateEmail = () => {
        const { updatedEmail } = this.state;
        let isValid = true;
        if (updatedEmail == null || updatedEmail == "") {
            this.setState({ emailError: '' })
        }
        else {
            if (!validator.isEmail(updatedEmail)) {
                this.setState({
                    emailError: 'Please enter a valid email address',
                    emailValid: false
                });
                isValid = false;
            } else {
                this.setState({
                    emailError: '',
                    mdl: {
                        ...this.state.mdl,
                        emailAddress: updatedEmail
                    }
                });
            }
        }

        return isValid;
    };

    //validatePhoneNumber
    validatePhoneNumber = () => {
        const { updatedPhoneNumber } = this.state;
        let isValid = true;
        if (updatedPhoneNumber == null || updatedPhoneNumber == "") {
            this.setState({ phoneNumberError: '' })
        }
        else {
            if (updatedPhoneNumber.length <= 13) {
                this.setState({
                    phoneNumberError: 'Please enter a valid phone number'
                });
                isValid = false;
            } else {
                this.setState({
                    phoneNumberError: '',
                    mdl: {
                        ...this.state.mdl,
                        phoneNumber: updatedPhoneNumber
                    }
                });
            }
        }

        return isValid;
    };

    //validateEmail
    handleSubmit = () => {
        if (this.state.updatedEmail == null || this.state.updatedEmail == "") {
            this.setState({
                emailFormCompleted: true
            });
        }
        else if (this.validateEmail()) {
            if (this.state.IsTextActive && this.validatePhoneNumber()) {
                this._UpdateCommPhoneNumberForInsured();
            }
            this._UpdateEmailForInsured();
            this.setState({
                emailFormCompleted: true, mdl: {
                    ...this.state.mdl,
                    emailAddress: this.state.updatedEmail
                }
            });
        }
    };

    //UpdateEmailForInsured
    _UpdateEmailForInsured = () => {
        UpdateEmailForInsured(this.state.updatedEmail, this.state.queryParams.get("PaymentId")).then(data => {
            console.log("Insured Email Updated Successfully");
        });
    };

    //UpdateCommPhoneNumberForInsured
    _UpdateCommPhoneNumberForInsured = () => {
        UpdateCommPhoneNumberForInsured(this.state.updatedPhoneNumber, this.state.queryParams.get("PaymentId")).then(data => {
            console.log("Insured CommPhoneNumber Updated Successfully");
        });
    };

    // data bind
    handleChangePaymentData(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(Object.assign(this.state.mdl, { [name]: value }));
    }
    //for select payment type
    onChangeValue = (event) => {
        let PaymentType = parseInt(event.target.value);
        this.setState({ Errors: {} });
    }


    IsAutoPay = (event) => {

        this.setState(Object.assign(this.state.UpdateAutoPayMdl, { IsAutoPay: !this.state.IsAutoPay }));
        this.setState(Object.assign(this.state.UpdateAutoPayMdl, { PaymentId: this.state.queryParams.get("PaymentId") }));
        this._UpdateAutoPay(this.state.UpdateAutoPayMdl);
    }
    IsTextActive = (event) => {
        if (event.target.checked) {
            this.setState({ IsTextActive: true, phoneNumberValid: false, updatedPhoneNumber: null });
            this.setState(Object.assign(this.state.Errors, { IsEmpty_IsTextorEmailActive: false }));
        }
        else {
            this.setState({ IsTextActive: false, phoneNumberValid: true });
        }
    }

    IsEmailActive = (event) => {
        if (event.target.checked) {
            this.setState({ IsEmailActive: true });
            this.setState(Object.assign(this.state.Errors, { IsEmpty_IsTextorEmailActive: false }));
        }
        else {
            this.setState({ IsEmailActive: false });
        }
    }

    IsTermsandConditions = (event) => {
        if (event.target.checked) {
            window.$('#modalTermsandConditions').modal('show');
            //this.setState({ IsTermsandConditions: true });
        }
        else {
            this.setState({ IsTermsandConditions: false });
        }
    }

    handleAccountSelect(e) {
        const selectedAccount = e.target.value;
        if (selectedAccount) {
            this.setState({ isSelectedAccount: true });
        }
        else {
            this.setState({ isSelectedAccount: false });
        }
    }

    // Validation function for required fields
    validatePaymentForm = () => {
        let isValid = true;
        let errorMessage = '';
        if (!this.state.isSelectedAccount) {
            errorMessage = 'Please select a valid payment account.';
            isValid = false;
        } else if (!this.state.scheduleDate) {
            errorMessage = 'Please select a schedule date.';
            isValid = false;
        } else if (!this.state.isSelectedPaymentAmount) {
            errorMessage = 'Please select a payment amount option.';
            isValid = false;
        } else if (this.state.paymentAmount === 'other' && (!this.state.otherAmount || this.state.otherAmount <= 5)) {
            errorMessage = 'Please enter a valid other amount.';
            isValid = false;
        }

        this.setState({ errorMessage });
        return isValid;
    };

    _MakeAISPayment = async () => {
        if (!this.validatePaymentForm()) {
            return;
        }
        // Gather the necessary data from the state
        document.getElementById("next-payment-btn").disabled = true;
        const isAgavePayment = true;
        const confirmationNumber = Math.floor((Math.random() * 1000000) + 1);
        const subDomainUrl = (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl)
        const paymentDetails = {
            PaymentReferenceNumber: confirmationNumber.toString(),
            PaymentId: this.state.queryParams.get("PaymentId"),
            aisAccountNumber: this.state.paymentAccount.aisAccountNumber,
            paymentAccountType: this.state.paymentAccount.paymentAccountType == 1 ? "CARD" : "ACH" ,
            CardNumber: this.state.paymentAccount.cardAccountNumber,
            cardToken: this.state.paymentAccount.cardToken,
            BankRoutingNumber: this.state.paymentAccount.bankAbaNumber,
            last4: this.state.paymentAccount.last4,
            PaymentPostDate: this.state.scheduleDate,
            isDBPaid: this.state.paymentAmount === 'due',
            isMonthlyPayment: this.state.paymentAmount === 'other',
            isPaidInFull: this.state.paymentAmount === 'outstanding',
            PolicyNumber: this.state.mdl.policyNumberDB,
            SubDomainId: (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId).toString(),
            paymentAmount: (this.state.paymentAmount === 'due'
                ? this.state.DBPaymentAmount + this.state.DBServiceFee 
                : this.state.paymentAmount === 'outstanding' 
                ? this.state.totalpremium + this.state.DBServiceFee + this.state.deficiencyAmount
                : this.state.otherAmount+ this.state.DBServiceFee)
        };
        MakeAISPayment(paymentDetails);

        let params1 = {
            pN: paymentDetails.PolicyNumber,
            fN: this.state.fullName,
            paymentId: paymentDetails.PaymentId,
            cN: confirmationNumber,
            cId: this.state.customerId,
            iAp: this.state.isAutoPay,
            isAgavePayment: isAgavePayment,
            uId: this.state.mdl?.createdFor,
            last4: paymentDetails.last4,
            paymentType: paymentDetails.paymentAccountType == "CARD" ? "card" : "us_bank_account",
            bankAccountNumber: paymentDetails.bankAccountNumber,
            bankRoutingNumber: paymentDetails.bankRoutingNumber,
            amount: paymentDetails.paymentAmount,
            iSld: new Date(this.state.scheduleDate) > new Date(),
            sldDate: this.state.scheduleDate,
            calculatedTechFee: paymentDetails.paymentAccountType == "CARD" ? (paymentDetails.paymentAmount * 0.035).toFixed(2) : 0,
            isMonthlyPayment: paymentDetails.isDBPaid || paymentDetails.isMonthlyPayment || paymentDetails.isPaidInFull,

        };
        let queryString = Object.keys(params1)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params1[key])}`)
            .join("&");
        if (subDomainUrl.toLowerCase() === "xgen") {
            window.location.href = `${window.location.origin}/payment-status?${queryString}`
        }
        else {
            window.location.href = `${window.location.origin}/${subDomainUrl.trim().toLowerCase()}/payment-status?${queryString}`
        }
        document.getElementById("next-payment-btn").disabled = true;
    }

    handleDateChange(event) {
        if (this.state.isAISAutoPay) {
            const today = new Date().toISOString().split('T')[0];
            this.setState({ scheduleDate: today });
        } else {
            this.setState({ scheduleDate: event.target.value });
        }
    }

    handleAmountChange(event, pm, process) {
        if (process === "PFMonthly") {
            this.setState({ paymentmode: "PF" });
            this.setState({ isPFPaid: true, isDBPaid: false, isPaidInFull: false });
            this.setState({ isPayFull: false, isDownPayment: true, isMonthlyPayment: false, pmtAmount: this.state.PFDownPayAmount + this.state.ServiceFee, isSelectedPaymentAmount: true });
            if (this.state.DownPaymentPaidStatus === true) {
                this.setState({ isPayFull: false, isDownPayment: false, isMonthlyPayment: true, pmtAmount: this.state.PFPaymentAmount + this.state.ServiceFee + this.state.deficiencyAmount });
            }
        }
        else if (process === "DBMonthly") {
            this.setState({ SelectedType: "DirectBill" });
            this.setState({ isPFPaid: false });
            this.setState({ isDBPaid: true });
            this.setState({ isPaidInFull: false });
            this.setState({ pmtAmount: (this.state.DBDownPayAmount), isSelectedPaymentAmount: true });
            this.setState({ isPayFull: false });
            this.setState({ isDownPayment: true });
            this.setState({ isMonthlyPayment: false });
            if (this.state.DownPaymentPaidStatus === true) {
                this.setState({ pmtAmount: this.state.DBPaymentAmount  });
                this.setState({ paymentAmount: this.state.DBPaymentAmount });
                this.setState({ isPayFull: false });
                this.setState({ isDownPayment: false });
                this.setState({ isMonthlyPayment: true });
            }
        }
        else if (process === "PIF") {
            this.setState({ paymentmode: "PIF" });
            this.setState({ SelectedType: "" });
            this.setState({ isPFPaid: false });
            this.setState({ isDBPaid: false });
            this.setState({ isPaidInFull: true });
            this.setState({ isPayFull: true });
            this.setState({ isDownPayment: false });
            this.setState({ isMonthlyPayment: false, isSelectedPaymentAmount: true });
            //this.setState({ Errors: {} });
            if (["rt", "shieldknot"].includes(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl)) {
                this.setState({ pmtAmount: (this.state.totalpremium + this.state.PIFServiceFee + this.state.deficiencyAmount) });
            } else {
                this.setState({ pmtAmount: (this.state.totalpremium + this.state.deficiencyAmount) });
            }
        }
        this.setState({ paymentAmount: event.target.value, otherAmount: '', errorMessage: '', pmtAmount: pm, isSelectedPaymentAmount: true });
    }

    handleOtherAmountChange(event, pm) {
        const value = parseFloat(event.target.value);
        console.log("value==>", value);

        this.setState({ isSelectedPaymentAmount: true })
        if (value > this.state.totalpremium + this.state.DBServiceFee) {
            this.setState({ errorMessage: 'Other amount should not exceed the total balance due.', isSelectedPaymentAmount: false });
        } else {
            this.setState({ otherAmount: value, errorMessage: '', pmtAmount: value, isSelectedPaymentAmount: true });
        }
    }

    handleOtherBlur(event, pm) {
        const value = parseFloat(event.target.value);
        const minAmount = parseFloat(0.95 * this.state.DBPaymentAmount + (this.state.DBServiceFee));
        console.log("this.state.DBPaymentAmount==>", this.state.DBPaymentAmount);
        console.log("this.state.DBServiceFee==>", this.state.DBServiceFee);

        console.log("minAmount==>", minAmount);
        this.setState({ isSelectedPaymentAmount: true });
        if (value < minAmount) {
            console.log("minAmount==>", minAmount);

            this.setState({ errorMessage: 'Other amount must be at least 95% of the required payment.', isSelectedPaymentAmount: false });
        } else {
            this.setState({ otherAmount: value, errorMessage: '', pmtAmount: value, isSelectedPaymentAmount: true });
        }
    }

    PFIsTermsandConditions = (event) => {
        if (event.target.checked) {
            this.setState({ PFIsTermsandConditions: true });
        }
        else {
            this.setState({ PFIsTermsandConditions: false });
        }
    }
    PIFIsTermsandConditions = (event) => {
        if (event.target.checked) {
            this.setState({ PIFIsTermsandConditions: true });
        }
        else {
            this.setState({ PIFIsTermsandConditions: false });
        }
    }
    DBIsTermsandConditions = (event) => {
        if (event.target.checked) {
            this.setState({ DBIsTermsandConditions: true });
        }
        else {
            this.setState({ DBIsTermsandConditions: false });
        }
    }

    DBDisclosureAgreementIsTermsandConditions = (event) => {
        if (event.target.checked) {
            this.setState({ DBDisclosureAgreementIsTermsandConditions: true });
            if (this.state.dbdocuSignUrl) {
                this.setState({ DBDocusignLoaderStatus: true });
                if ((JSON.parse(localStorage.getItem("LoggedOutDetails"))?.isFinanceProPF && JSON.parse(localStorage.getItem("LoggedOutDetails"))?.isAISDB) || (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl == "knight") || (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl == "rt")) {
                    if (this.state.dbisSignedAgreement) {
                        this.setState({ showIframe: false, DBDocusignLoaderStatus: false });
                    }
                    else {                     
                        docuSignClick.Clickwrap.render({
                            agreementUrl: this.state.dbdocuSignUrl,
                            onAgreed: () => {
                                SaveSignedStatus(this.state.queryParams.get("PaymentId"), false, true).then(data => {
                                    let response = data;
                                    this.setState({ DBDocusignLoaderStatus: false });
                                    this.setState({ dbisSignedAgreement: true });
                                });
                            }
                        }, "#db-terms-of-service");
                    }
                }
                else {
                    this.setState({ showIframe: false, DBDocusignLoaderStatus: false });
                }
            }
        }
        else {
            this.setState({ showIframe: false, DBDocusignLoaderStatus: false });
            this.setState({ DBDisclosureAgreementIsTermsandConditions: false });
        }
    }
    onTermsandConditionsAccept = () => {
        window.$('#modalTermsandConditions').modal('hide');
        this.setState({ IsTermsandConditions: true });
    }

    onAutoPayAccept = () => {
        window.$('#modalautopayack').modal('hide');
        this.setState(Object.assign(this.state.UpdateAutoPayMdl, { IsAutoPay: true }));
        this._UpdateAutoPay(this.state.UpdateAutoPayMdl);
    }

    onEmailConfirmCancel = () => {
        window.$('#modalEmailConfirm').modal('hide');
    }

    onEmailConfirm = () => {
        window.$('#modalEmailConfirm').modal('hide');
        this.handleSubmit();
        this.setState({ isAgavePayment: !this.state.isPFPaid })
        this.setState({ clientSecret: '' });
        this.setState({ SaveLoaderStatus: true });
        this._GetPaymentIntent();
        if (this.state.isDBPaid) {
            var result = this.Validation();
            var resultEmail = this.validateEmail();
            if (result && resultEmail) {
                window.$('#modalPayment').modal('show');
            }
        }
        else if (this.state.isPayFull) {
            var resultEmail = this.validateEmail();
            if (resultEmail) {
                window.$('#modalPayment').modal('show');
            }
        }
        else {
            window.$('#modalPayment').modal('show');
        }
    }

    onelectronicnoticAccept = () => {
        window.$('#modalectronicnotice').modal('hide');
    }

    onmodaldirectbillingdisclosureagreementaccept = () => {
        window.$('#modaldirectbillingdisclosureagreement').modal('hide');
    }

    handleIframeLoad = () => {
        const iframe = this.iframeRef.current;
        if (iframe && iframe.contentWindow) {
            try {
                sessionStorage.setItem('documentSigned', 'false');
                const iframeWindow = iframe.contentWindow;
                const currentUrl = iframeWindow.location.href;
                if (currentUrl.includes('signing_complete')) {
                    sessionStorage.setItem('documentSigned', 'true');
                    window.location.reload();
                }
            } catch (error) {
                console.warn("Unable to access iframe URL due to cross-origin restrictions");
            }
        }
        this.setState({ LoaderStatus: false })
    }

    onButtonEvent = async (NextStep) => {
        if (NextStep === 'NameBack') {
            $('.step-makepayment-paymentType').removeClass('form-selected').addClass('form-dismiss');
            $('.step-makepayment-fullname').removeClass('form-dismiss').addClass('form-selected');
            $('.step-makepayment-fullname').addClass('form-selected-Out');
        }
        if (NextStep === 'PayNext') {
            if (this.state.DownPaymentPaidStatus === true) {
                this.setState({ pmtAmount: this.state.mdl.monthlyAmount });
            }
            $('.step-makepayment-paymentType').removeClass('form-selected').addClass('form-dismiss');
            $('.step-makepayment-pay').removeClass('form-dismiss').addClass('form-selected');
        }
        if (NextStep === 'PayFull') {
            
            let errors = {};
            let count = 0;
            if (!this.state.PIFIsTermsandConditions) {
                errors['IsEmpty_PIFIsTermsandConditions'] = true;
                errors['PIFIsTermsandConditionsEmptyMsg'] = "Please agree to terms and conditions";
                count++;
            }
            this.setState({ paymentmode: "PIF" });
            this.setState({ SelectedType: "" });
            this.setState({ isPFPaid: false });
            this.setState({ isDBPaid: false });
            this.setState({ isPaidInFull: true });
            this.setState({ Errors: errors });
            if (count === 0) {
                this.setState({ isPayFull: true });
                this.setState({ isDownPayment: false });
                this.setState({ isMonthlyPayment: false });
                if (["rt", "shieldknot"].includes(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl)) {
                    this.setState({ pmtAmount: (this.state.totalpremium + this.state.PIFServiceFee) });
                } else {
                    this.setState({ pmtAmount: this.state.totalpremium });
                }
                $('.step-makepayment-paymentType').removeClass('form-selected').addClass('form-dismiss');
                $('.payment-details-form').removeClass('form-dismiss').addClass('form-selected PayFull');
                return true;
            }
        }
        if (NextStep === 'DirectBill') {
            this.setState({ SelectedType: "DirectBill" });
            let errors = {};
            let count = 0;
            if (!this.state.DBIsTermsandConditions) {
                errors['IsEmpty_DBIsTermsandConditions'] = true;
                errors['DBIsTermsandConditionsEmptyMsg'] = "Please agree to terms and conditions";
                count++;
            }
            this.setState({ isPFPaid: false });
            this.setState({ isDBPaid: true });
            this.setState({ isPaidInFull: false });
            this.setState({ DBTermLength: this.state.DBTermLength });
            this.setState({ Errors: errors });
            if (count === 0) {
                this.setState({ pmtAmount: this.state.DBDownPayAmount });
                this.setState({ isPayFull: false });
                this.setState({ isDownPayment: true });
                this.setState({ isMonthlyPayment: false });
                if (this.state.DownPaymentPaidStatus === true) {
                    this.setState({ pmtAmount: this.state.DBPaymentAmount + this.state.DBServiceFee });
                    this.setState({ isPayFull: false });
                    this.setState({ isDownPayment: false });
                    this.setState({ isMonthlyPayment: true });
                }
                $('.step-makepayment-paymentType').removeClass('form-selected').addClass('form-dismiss');
                $('.step-makepayment-pay').removeClass('form-dismiss').addClass('form-selected');
                return true;
            }
        }
        if (NextStep === 'PayMonthly') {
            this.setState({ SelectedType: "PF" });
            this.setState({ paymentmode: "PF" });
            let errors = {};
            let count = 0;
            if (!this.state.PFIsTermsandConditions) {
                errors['IsEmpty_PFIsTermsandConditions'] = true;
                errors['PFIsTermsandConditionsEmptyMsg'] = "Please agree to terms and conditions";
                count++;
            }

            this.setState({ isPFPaid: true, isDBPaid: false, isPaidInFull: false, Errors: errors });
            if (count === 0) {
                this.setState({ isPayFull: false, isDownPayment: true, isMonthlyPayment: false });
                if (this.state.DownPaymentPaidStatus === true) {
                    this.setState({ isPayFull: false, isDownPayment: false, isMonthlyPayment: true });
                }

                if (this.state.docuSignUrl) {
                    this.setState({ LoaderStatus: true });
                    if ((JSON.parse(localStorage.getItem("LoggedOutDetails"))?.isFinanceProPF && !JSON.parse(localStorage.getItem("LoggedOutDetails"))?.isAISDB) || (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl == "knight") || (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl == "rt")) {
                        if (this.state.isSignedAgreement || sessionStorage.getItem('documentSigned') === 'true') {
                            this._OpenStripePayment();
                            this.setState({ showIframe: false, LoaderStatus: false });
                        }
                        else {
                            this.setState({ showIframe: true });
                        }
                    }
                    else {
                        this._OpenStripePayment();
                        this.setState({ showIframe: false, LoaderStatus: false });
                    }
                }
                else {
                    this.setState({ LoaderStatus: true });
                    this._OpenStripePayment();
                }
                return true;
            }


        }
        if (NextStep === 'NextPaymentType') {
            $('.step-makepayment-fullname').removeClass('form-selected').addClass('form-dismiss');
            $('.step-makepayment-paymentType').removeClass('form-dismiss').addClass('form-selected');

        }
        if (NextStep === 'PaymentTypeBack') {
            this.setState({ IsTextActive: false, IsEmailActive: false, updatedEmail:'' });
            this.setState({ IsTermsandConditions: false });
            this.setState({ PFIsTermsandConditions: false });
            this.setState({ PIFIsTermsandConditions: false });
            this.setState({ DBIsTermsandConditions: false }); 
            this.setState({ DBDisclosureAgreementIsTermsandConditions: false });
            this.setState(Object.assign(this.state.Errors, { IsEmpty_IsTermsandConditions: false }));
            this.setState(Object.assign(this.state.Errors, { IsEmpty_IsTextorEmailActive: false }));
            this.setState({ isDBPaid: false, isPFPaid: false, isPaidInFull: false });
            $(".step-makepayment-pay").removeClass("PayFull");
            $(".step-makepayment-pay").removeClass("PF");
            $('.step-makepayment-pay').removeClass('form-selected').addClass('form-dismiss');
            $('.step-makepayment-paymentType').removeClass('form-dismiss').addClass('form-selected');
            $('.step-makepayment-paymentType').addClass('form-selected-Out');

        }

    }
    _OpenStripePayment = () => {
        this.setState({
            pmtAmount: this.state.PFDownPayAmount
        }, () => {
            if (this.state.DownPaymentPaidStatus === true) {
                this.setState({ 
                    pmtAmount: this.state.PFPaymentAmount
                }, () => {
                    this._SelectPaymentMethod();
                });
            } else {
                this._SelectPaymentMethod();
            }
        });
        this.setState({ showIframe: false, LoaderStatus: false });
    }

    ExpireLink = () => {
        return (
            <>
                <div className="container form-space p-space">
                    <div className="card">
                        <div className="Paymentcard">
                            <h1 className="pt-2 pb-2 text-center">This link has expired</h1>
                            <p className="text-center">If you have any questions regarding this policy, please contact us at (314) 237-0085.</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    
    PolicyCancelled = () => {
        return (
            <>
                <div className="container form-space p-space">
                    <div className="card">
                        <div className="Paymentcard">
                            <h1 className="pt-2 pb-2 text-center">This policy has been cancelled</h1>
                            <p className="text-center">If you have any questions regarding this policy, please contact us at (314) 237-0085.</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    LinkIsPolicyCancelled = () => {
        return (
            <>
                <div className="container form-space p-space">
                    <div className="card">
                        <div className="Paymentcard">
                            <h1 className="pt-2 pb-2 text-center">This Payment link has been cancelled</h1>
                            {/*<p className="text-center">If you have any questions regarding this policy, please contact us at (314) 237-0085.</p>*/}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    showPFAgreement = () => {
        return (
            <div id="ds-pf-service" style={{ width: '100%', height: '700px' }}>
                {this.state.showIframe && this.state.docuSignUrl && (
                    <iframe
                        src={this.state.docuSignUrl}
                        width="100%"
                        height="100%"
                        onLoad={this.handleIframeLoad}
                        onClick={() => this.pollForSigningStatus }
                        ref={this.iframeRef}
                    ></iframe>
                )}
            </div>
        );
    }

    LinkNotFound = () => {
        return (
            <>
            <div className="container form-space p-space">
                <div className="card">
                    <div className="Paymentcard">
                        <h1 className="pt-2 pb-2 text-center">Does Not Exist !</h1>
                        <p className="text-center">The payment link does not exist.</p>
                    </div>
                </div>
            </div>
            </>
        );
    }
    
    PaymentInProgress = () => {
        return (
            <>
            <div className="container form-space p-space">
                <div className="card">
                        <div className="Paymentcard">
                            <h1 className="pt-2 pb-2 text-center">Your Payment Is Being Processed</h1>
                            <p className="text-center">We are currently processing your last
                                payment. Once this payment has posted,
                                usually within 24 hours, you can make
                                additional payments to your policy. </p>
                    </div>
                </div>
            </div>
            </>
        );
    }
    
    PaymentPaid = () => {
        return (
            <>
                <div className="container form-space p-space">
                    <div className="card">
                        <div className="Paymentcard">
                            <h1 className="pt-2 pb-2 text-center">Your Payment Has Already Been Made</h1>
                            <p className="text-center">This payment has already been paid successfully. No further action is required on this payment link.</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    PaymentProcessing = () => {
        return (
            <>
                <div className="container form-space p-space">
                    <div className="card">
                        <div className="Paymentcard">
                            <h1 className="pt-2 pb-2 text-center">Already Processing!</h1>
                            <p className="text-center">The payment is pending clearing ACH.</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    openTermsAndConditionsModal = (event) => {
        event.preventDefault();
        event.stopPropagation();
        window.$('#modalTermsandConditions').modal('show');
    };

    openautopayackModal = (event) => {
        event.preventDefault();
        event.stopPropagation();
        window.$('#modalautopayack').modal('show');
    };
    openelectronicnoticeModal = (event) => {
        event.preventDefault();
        event.stopPropagation();
        window.$('#modalectronicnotice').modal('show');
    };

    openmodaldirectbillingdisclosureagreement = (event) => {
        event.preventDefault();
        event.stopPropagation();
        window.$('#modaldirectbillingdisclosureagreement').modal('show');
    };

    PaymentRefund = () => {
        return (
            <>
                <div className="card card-body dashboard-card-newDesign">
                <h1 className="pt-2 pb-2 text-center">Refund Initiated </h1>
                <p className="text-center">You are due a refund of {CustomCurrencyFormat(this.state.RemainingBalance * -1)}. You will receive this refund via check within 7 - 10 days. If you have any questions please call us <a href="tel:(800)624-6308">800-624-6308</a></p>
                </div>
            </>
        );
    }
    StripePaymentForm = () => (
        <Elements stripe={stripePromise}>
            <PaymentForm />
        </Elements>
    );

    _encodeUrlValues = (amount) => {
        var urlparamenters = {
            amount: amount,
            paymentId: this.state.paymentId,
            id: this.state.achMemoId,
            policyNumber: this.state.isPaid ? (this.state.isDirectBilling ? this.state.mdl.policyNumberDB : this.state.mdl.policyNumberFP) : (this.state.isPFPaid ? this.state.mdl.policyNumberFP : this.state.mdl.policyNumberDB),
            fullName: this.state.fullName,
            invoiceNumber: this.state.mdl.invoiceNumber
        };
        const jsonString = JSON.stringify(urlparamenters)
        this.state.urlEncodedValue = Buffer.from(jsonString).toString('base64');
    };

    toggleModal = (message) => {
        this.setState(prevState => ({
            showModal: !prevState.showModal,
            modalMessage: message || ""
        }), () => {
            setTimeout(this.closeModal, 2000);
        });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    onClickHandler = () => {
        const hiddenElement = document.querySelector(".section-policytaxes");
        hiddenElement.className.indexOf("collapse show") > -1 ? hiddenElement.classList.remove("show") : hiddenElement.classList.add("show");
    };

    handleEmailChange = (e) => {
        const email = e.target.value;
        if (email) {
            const isValid = validator.isEmail(email);
            this.setState({
                updatedEmail: email,
                emailValid: isValid,
            });
        } else {
            this.setState({ emailValid: true, updatedEmail: "" });
        }
    };

    handlePhoneNumberChange = (e) => {
        const phoneNumber = e.target.value;
        if (phoneNumber) {
            const pattern = new RegExp(/^((\+0?1\s)?)\(?\d{3}\)?[\s.\s]\d{3}[\s.-]\d{4}$/);
            const isValid = (pattern.test(phoneNumber)) && phoneNumber.length >= 13;
            this.setState({
                updatedPhoneNumber: phoneNumber.replace(/^(\d{3})(\d{3})(\d+)$/, "($1) $2-$3"),
                phoneNumberValid: isValid,
            });
        } else {
            this.setState({ phoneNumberValid: false, updatedPhoneNumber: "" });
        }
    };

    handlePaymentMethodSelect = (method) => {
        this.setState({ selectedPaymentMethod: method });
    };
    
    handleModalClose = () => {
        this.setState({ selectedPaymentMethod: "CARD" });
    };
    
    render() {
        const paymentDetail = {
            policyNumber: this.state.isPaid ? (this.state.isDirectBilling ? this.state.mdl.policyNumberDB : this.state.mdl.policyNumberFP) : (this.state.isPFPaid ? this.state.mdl.policyNumberFP : this.state.mdl.policyNumberDB),
            textActive: this.state.IsTextActive,
            emailActive: this.state.IsEmailActive,
            paymentId: this.state.mdl.paymentId,
            fullName: this.state.fullName,
            transferGroup: this.state.transferGroup,
            subDomainUrl: (window.location.pathname.replace(/^\/([^\/]*).*$/, '$1') === "make-payment" ? "Xgen" : window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')),
            color: this.state.color,
            hoverFocus: this.state.hoverFocus,
            subdomainId: (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId)?.toString(),
            customerId: this.state.customerId,
            isAutoPay: this.state.UpdateAutoPayMdl.IsAutoPay,
            isIframe: false,
            invoiceNumber: this.state.invoiceNumber,
            isPFPaid: this.state.isPFPaid,
            isDBPaid: this.state.isDBPaid,
            isPaidInFull: this.state.isPaidInFull,
            isPayFull: this.state.isPayFull,
            isDownPayment: this.state.isDownPayment,
            isMonthlyPayment: this.state.isMonthlyPayment,
            amount: this.state.pmtAmount,
            isAgavePayment: (this.state.paymentProcessId == 2 && !this.state.isPFPaid && !this.state.isStetson )? true : false,
            insuredId: this.state.mdl?.createdFor,
            calculatedTechFee: (this.state.pmtAmount * 0.035).toFixed(2)
        }
        var Button = ExternalDomain(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutColor, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutHoverFocus, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId);
        const today = new Date(Date.UTC(
          new Date().getUTCFullYear(),
          new Date().getUTCMonth(), 
          new Date().getUTCDate()
        ));
        const cancelledDate = new Date(this.state.cancelledDate);
        const threeDaysInMs = 3 * 24 * 60 * 60 * 1000;
        const policyCancelled = this.state.isCancelled && (this.state.cancelledDate ? (today - cancelledDate > threeDaysInMs) : false)
        
        return (
            <>
                <div id="ds-terms-of-service"></div>
                <div id="db-terms-of-service"></div>
                {console.log("111111111111", (this.state.isScheduled || this.state.isPaidToday) && !this.state.isProcessing && !this.state.isCancelled && !(this.state.isAlredyPFPaid || parseInt(this.state.RemainingBalance) == 0 || parseInt(this.state.totalpremium) == 0))}
                {console.log("isScheduled", this.state.isScheduled)}
                {console.log("isPaidToday", this.state.isPaidToday)}

                {this.state.UserDetails !== null ?
                    <div className="main-wrapper-newDesign logged-in-payment-card">
                        <InternalLayoutHeader />	 {/*internal header user wise */}
                        <>
                            {this.state.showIframe && !this.state.isProcessing && this.showPFAgreement()}
                            {!policyCancelled && !this.state.isPaidToday && !this.state.isScheduled && !this.state.isExpired && !this.state.isProcessing && !this.state.showIframe && parseInt(this.state.RemainingBalance) > 0 && !this.state.isAlredyPFPaid && this.PaymentForm()}
                            {this.state.isExpired && !this.state.isPaidToday && !this.state.isScheduled && !this.state.isProcessing && !this.state.isAlredyPFPaid && this.ExpireLink()}
                            {this.state.linknotexists && !this.state.isProcessing && this.LinkNotFound()}
                            {(!policyCancelled && !this.state.isProcessing && (this.state.isAlredyPFPaid || parseInt(this.state.RemainingBalance) == 0 || parseInt(this.state.totalpremium) == 0)) && this.PaymentPaid()}
                            {(!policyCancelled && this.state.isProcessing && !this.state.isAlredyPFPaid) && this.PaymentProcessing()}
                            {(!this.state.isProcessing && parseInt(this.state.RemainingBalance) < 0) && this.PaymentRefund()}
                            {policyCancelled && !this.state.isPaidToday && !this.state.isAlredyPFPaid && this.state.subDomainId == 2 && this.PolicyCancelled()}
                            {policyCancelled && !this.state.isAlredyPFPaid && this.state.subDomainId == 3 && this.LinkIsPolicyCancelled()}
                            {(this.state.isScheduled || this.state.isPaidToday) && !this.state.isProcessing && !policyCancelled && !(this.state.isAlredyPFPaid || parseInt(this.state.RemainingBalance) == 0 || parseInt(this.state.totalpremium) == 0) && (parseInt(this.state.RemainingBalance) >= 0) && this.PaymentInProgress()}
                        </>
                        <SiteFooter />  {/*this call for site footer */}
                    </div>
                    :
                    <>

                        <div className="main-wrapper-newDesign not-logged-in-payment-card">
                            {/*<InternalLayoutHeader />	 /!*internal header user wise *!/*/}
                            <>
                                {this.state.showIframe && !this.state.isProcessing && this.showPFAgreement()}
                                {!policyCancelled && !this.state.isPaidToday && !this.state.isScheduled && !this.state.isExpired && !this.state.isProcessing && !this.state.showIframe && parseInt(this.state.RemainingBalance) > 0 && !this.state.isAlredyPFPaid && this.PaymentForm()}
                                {this.state.isExpired && !this.state.isPaidToday && !this.state.isScheduled && !this.state.isProcessing && !this.state.isAlredyPFPaid && this.ExpireLink()}
                                {this.state.linknotexists && !this.state.isProcessing && this.LinkNotFound()}
                                {(!policyCancelled && !this.state.isProcessing && (this.state.isAlredyPFPaid || parseInt(this.state.RemainingBalance) == 0 || parseInt(this.state.totalpremium) == 0)) && this.PaymentPaid()}
                                {(!policyCancelled && this.state.isProcessing && !this.state.isAlredyPFPaid) && this.PaymentProcessing()}
                                {(!this.state.isProcessing && parseInt(this.state.RemainingBalance) < 0) && this.PaymentRefund()}
                                {policyCancelled && !this.state.isPaidToday && !this.state.isAlredyPFPaid && this.state.subDomainId == 2 && this.PolicyCancelled()}
                                {policyCancelled && !this.state.isAlredyPFPaid && this.state.subDomainId == 3 && this.LinkIsPolicyCancelled()}
                                {(this.state.isScheduled || this.state.isPaidToday) && !this.state.isProcessing && !policyCancelled && !(this.state.isAlredyPFPaid || parseInt(this.state.RemainingBalance) == 0 || parseInt(this.state.totalpremium) == 0) && (parseInt(this.state.RemainingBalance) >= 0) && this.PaymentInProgress()}
                            </>
                            <SiteFooter />  {/*this call for site footer */}
                        </div>
                    </>
                }
                <div className="modal fade" tabIndex="-1" id="modalPayment" aria-labelledby="modal Payment methode selection" aria-hidden="true">
                    <div className={"modal-dialog modal-dialog-centered " + ((this.state.isPFPaid || this.state.paymentProcessId == 1 || this.state.isStetson)? '' : 'fppwidth')}>
                        {/*<div className="modal-dialog modal-dialog-centered">*/}
                        <div className="modal-content">
                            <div className="modal-header justify-content-center pt-4 border-0">
                                <h5 className="modal-title-special">How would you like to make your payment?</h5>
                                {(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId == 3) &&
                                    <h6 className="modal-title-special">Bank Account or Credit Card</h6>
                                }
                                {(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId != 3) &&
                                    <h6 className="modal-title-special">ACH or Credit Card</h6>
                                }
                                {this.state.isCancelled && this.state.cancelledDate && (this.state.cancelledDate ? (today - cancelledDate < threeDaysInMs) : true) &&
                                    <>
                                    <h6 className="text-danger mt-2"> By making a payment, you acknowledge that Direct Billing Solutions, LLC (or its affiliate) will submit a request for reinstatement of your policy on your behalf. Acceptance of the request and reinstatement of the policy is at the sole discretion of the insurance company. Direct Billing Solutions, LLC (or its affiliates) cannot guarantee reinstatement.</h6>
                                    </>    
                                }
                                <button type="button" id="btncloseMdl" className="btn-close position-absolute" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="">
                                    { /*loader show befoure load contents*/}
                                    {this.state.SaveLoaderStatus && <LoaderContents />}
                                </div>
                                <div className="mx - auto mb-3 row Invoicedetails">
                                    {/*<div className="col-6"><span><b>Invoice Number</b> {this.state.mdl.invoiceNumber ?? ''}</span></div>*/}
                                    { (this.state.isPFPaid == true && this.state.subDomainId == 2) ?
                                        <div className="pull-right"><span><b>Amount</b> {CustomCurrencyFormat(this.state.pmtAmount) ?? ''}</span></div> :
                                        <div className="pull-right"><span><b>Amount</b> {CustomCurrencyFormat(this.state.pmtAmount) ?? ''}</span></div>
                                        
                                    }
                                    {this.state.selectedPaymentMethod === "CARD" &&
                                    <>
                                        <div className="pull-right">
                                            {
                                                (this.state.isPFPaid == true && this.state.subDomainId == 2) ?
                                                <span><b>Credit Card Fee</b> {CustomCurrencyFormat(this.state.pmtAmount * this.state.techFee) ?? ''}</span> :
                                                <span><b>Credit Card Fee</b> {CustomCurrencyFormat(this.state.pmtAmount * this.state.techFee) ?? ''}</span>
                                            }
                                        </div>
                                        {
                                            (this.state.subDomainId == 3) &&
                                            <div className="pull-right">
                                                <span><b>Service Fee</b> {CustomCurrencyFormat(5) ?? ''}</span>
                                            </div>
                                        }
                                        {
                                            (!this.state.isPaidInFull && this.state.paymentProcessId == 2) &&  
                                            <div className="pull-right">
                                                <span><b>Service Fee</b> {CustomCurrencyFormat(5) ?? ''}</span>
                                            </div>
                                        }
                                    </>
                                    }
                                    {this.state.selectedPaymentMethod === "ACH"  &&
                                        <div className="pull-right">
                                            <span><b>Service Fee</b> {CustomCurrencyFormat(5) ?? ''}</span>
                                        </div>
                                    }
                                </div>
                                <div className="w-100 m-auto modal-size" id="frmpaymentstatus">
                                    <div className="mx-auto mb-3" >
                                        <div className='row payment-element-form mb-0'>
                                            <div className="col-md-12 col-sm-12 col-12 p-0 position-relative">
                                                {this.state.clientSecret &&
                                                    <Elements stripe={stripePromise} options={this.state.clientSecret}>
                                                        <CheckoutForm props={paymentDetail}
                                                        onPaymentMethodSelect={this.handlePaymentMethodSelect}/>
                                                    </Elements>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.showModal}
                    onRequestClose={this.closeModal}
                    contentLabel="Modal"
                    className="modal-content-DirectBill"
                    overlayClassName=""
                >
                    <div>{this.state.modalMessage}</div>
                </Modal>
            </>
        );
        

    }
    PaymentForm = () => {

        const tableStyle = {
            width: '100%',
            borderCollapse: 'collapse',
            fontFamily: 'Arial, sans-serif',
            fontSize: '14px',
        };

        const cellStyle = {
            border: '1px solid black',
            padding: '2px',
        };

        const headerCellStyle = {
            border: '1px solid black',
            padding: '2px',
            backgroundColor: '#dce6f2',
        };
        var Button = ExternalDomain(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutColor, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutHoverFocus, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId);
        const today = new Date(Date.UTC(
          new Date().getUTCFullYear(),
          new Date().getUTCMonth(), 
          new Date().getUTCDate()
        ));
        const cancelledDate = new Date(this.state.cancelledDate);
        const threeDaysInMs = 3 * 24 * 60 * 60 * 1000;
        return (
            <>
                <form
                    className="position-relative m-auto step-makepayment-paymentType fullHeight form-selected dashboard-card-newDesign">
                    {((!this.state.DownPaymentPaidStatus) || ((this.state.paymentProcessId == 1 || this.state.isStetson) ? true : false)) &&
                        <>
                            <fieldset className="step-form flex-wrap px-3 row payment-form-field fullHeight"
                                      data-field="paymentType">
                                <>
                                    <div className="pt20px">
                                        {this.state.LoaderStatus && <LoaderContents/>}
                                    </div>
                                {((this.state.ispremiumfinance && this.state.DownPaymentPaidStatus && this.state.isPFShow) || (this.state.ispremiumfinance && !this.state.DownPaymentPaidStatus && !this.state.isPFShow)) && (this.state.earnedPremiumPercentage != 100) && (this.state.subDomain !== 'rt' || this.state.totalpremium > 500) &&
                                        <>
                                            <div Id="premiumFianceSection"
                                                 className={"form-floating input-wrapper-payment payment-wrapper-input col card premiumFianceSection make-payment-card"}>
                                                <h4 className="label-lower">Premium Finance</h4>
                                                <hr/>
                                                <div className="make-payment-body">
                                                    {this.state.mdl.monthlyAmount === 0.00 &&
                                                        <div className="make-payment-inner">
                                                            <p className="card-text">Paid in Full</p>
                                                        </div>
                                                    }
                                                    {this.state.mdl.monthlyAmount !== 0.00 &&
                                                        <>
                                                            <div className="make-payment-inner">
                                                                <p className={`make-payment-header ${this.state.subDomain.toLowerCase() === "rt" ? "mb-0" : ""}`}>{JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLabel2}</p>
                                                                <span className="value"> {CustomCurrencyFormat(this.state.PFPaymentAmount)}</span>
                                                                {this.state.subDomain.toLowerCase() === "rt" &&
                                                                <>
                                                                    <div className="make-payment-inner">
                                                                        <div className="payment-description">
                                                                            <p className="payment-date">per mo. for {this.state.isPFPaid ? this.state.termLength : this.state.PFTermLength} mo.</p>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                    {this.state.DownPaymentPaidStatus === false &&
                                                        <>
                                                            <div className="make-payment-inner">
                                                                <p className="make-payment-header">{JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLabel1}</p>
                                                                <span
                                                                    className="value"> {CustomCurrencyFormat(this.state.PFDownPayAmount)}</span>
                                                            </div>
                                                        </>
                                                    }

                                                    {this.state.PFAPR !== 0.00 && this.state.PFAPR !== null && this.state.subDomainId === "2" &&
                                                        <>
                                                            <div className="make-payment-inner">
                                                                <div className="payment-description">
                                                                    <p className="make-payment-header">APR </p>
                                                                    <p className="payment-date">per mo.
                                                                        for {this.state.isPFPaid ? this.state.termLength : this.state.PFTermLength} mo.</p>
                                                                </div>
                                                                <span
                                                                    className="value"> {this.state.PFAPR}% ({CustomCurrencyFormat(this.state.PFMonthlyPayment)})</span>
                                                            </div>
                                                        </>
                                                    }
                                                    {this.state.PFAPR !== 0.00 && this.state.PFAPR !== null && this.state.subDomainId !== "2" &&
                                                        <>
                                                            <div className="make-payment-inner">
                                                                <p className="make-payment-header">APR </p>
                                                                <span className="value"> {this.state.PFAPR}%</span>
                                                            </div>
                                                        </>
                                                    }
                                                    {this.state.subDomain.toLowerCase() !== "rt" && 
                                                        <>
                                                            <div className="make-payment-inner">
                                                                <div className="payment-description">
                                                                    <p className="make-payment-header mb-0">Installment Fee </p>
                                                                    <p className="payment-date">per mo.
                                                                        for {this.state.isPFPaid ? this.state.termLength : this.state.PFTermLength} mo.</p>
                                                                </div>
                                                                <span
                                                                    className="value"> {CustomCurrencyFormat(this.state.ServiceFee)}</span>
                                                            </div>
                                                        </>
                                                    }
                                                    
                                                </div>
                                                <div className="make-payment-bottom">
                                                    {this.state.Errors['IsEmpty_PFIsTermsandConditions'] === true ? <div
                                                        className="has-err-msg">{this.state.Errors['PFIsTermsandConditionsEmptyMsg']}</div> : ''}
                                                    <div className='input-wrapper-radio remove-bg payment-card-terms'>
                                                        <div className="col-12 TermsandConditions-modal-check">

                                                            <>
                                                                <input type="checkbox" id="radio-Active"
                                                                       name="PFIsTermsandConditions"
                                                                       checked={this.state.PFIsTermsandConditions}
                                                                       required
                                                                       className={(this.state.Errors['IsEmpty_PFIsTermsandConditions'] === true ? ' has-err form-check-input form-control mx-1' : 'form-check-input form-control mx-1')}
                                                                       onChange={this.PFIsTermsandConditions}/>
                                                            </>
                                                            <label className="form-check-label">
                                                                <h6 className="mb-0 AutoPay-label-text">You agree to
                                                                    the <a href=""
                                                                           onClick={this.openTermsAndConditionsModal}
                                                                           style={{textDecoration: 'underline'}}>terms
                                                                        and conditions</a>.</h6>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <button type="button" className="btn-newDesign make-payment-btn"
                                                            onClick={() => this.onButtonEvent('PayMonthly')}
                                                            disabled={""}>
                                                        <span className="label">Pay monthly</span>
                                                        <svg className="icon-arrow after">
                                                            <use xlinkHref="#arrow"></use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    }
                                {((this.state.isdirectbilling && this.state.DownPaymentPaidStatus && this.state.isDBShow) || (this.state.isdirectbilling && !this.state.DownPaymentPaidStatus && !this.state.isDBShow)) && (this.state.subDomain !== 'rt' || this.state.earnedPremiumPercentage != 100 && (this.state.subDomain !== 'rt' || this.state.totalpremium - this.state.DBDownPayAmount > 250 && this.state.totalpremium < 50000)) &&
                                        <>
                                            <div id="direct-billing-section"
                                                 className="form-floating input-wrapper-payment payment-wrapper-input col card make-payment-card">
                                                <h4 className="label-lower">Installment Option</h4>
                                                <hr/>
                                                <div className="make-payment-body">
                                                    {this.state.mdl.monthlyAmount !== 0.00 &&
                                                        <>
                                                            <div className="make-payment-inner mt-2">
                                                                <p className="make-payment-header mb-0">{JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLabel2}</p>
                                                                <span className="value"> {CustomCurrencyFormat(this.state.DBPaymentAmount)}</span>
                                                                <div className="payment-description">
                                                                    <p className="payment-date">per mo. for {this.state.isDBPaid ? this.state.termLength : this.state.DBTermLength} mo.</p>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    {this.state.DownPaymentPaidStatus === false &&
                                                        <>
                                                            <div className="make-payment-inner">
                                                                <p className="make-payment-header">{JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLabel1}</p>
                                                                <span
                                                                    className="value">{CustomCurrencyFormat(this.state.DBDownPayAmount)}</span>
                                                            </div>
                                                        </>
                                                    }
                                            <>
                                                        <div className="make-payment-inner mt-2">
                                                            <div className="payment-description">
                                                                <p className="make-payment-header mb-0">Installment Fee </p>
                                                                <div className="payment-description">
                                                                    <p className="payment-date">per mo.
                                                                        for {this.state.isDBPaid ? this.state.termLength : this.state.DBTermLength} mo.</p>
                                                                </div>
                                                            </div>
                                                            <span
                                                                className="value"> {CustomCurrencyFormat(this.state.DBServiceFee)}</span>
                                                        </div>
                                            </>
                                                </div>
                                                <div className="make-payment-bottom">
                                                    {this.state.Errors['IsEmpty_DBIsTermsandConditions'] === true ? <div
                                                        className="has-err-msg">{this.state.Errors['DBIsTermsandConditionsEmptyMsg']}</div> : ''}
                                                    <div className='input-wrapper-radio remove-bg'>
                                                        <div className="col-12 TermsandConditions-modal-check">
                                                            <input type="checkbox" id="radio-Active"
                                                                   name="DBIsTermsandConditions"
                                                                   checked={this.state.DBIsTermsandConditions} required
                                                                   className={(this.state.Errors['IsEmpty_DBIsTermsandConditions'] === true ? ' has-err form-check-input form-control mx-1' : 'form-check-input form-control mx-1')}
                                                                   onChange={this.DBIsTermsandConditions}/>
                                                            <label className="form-check-label">
                                                                <h6 className="mb-0 AutoPay-label-text">You agree to
                                                                    the <a href=""
                                                                           onClick={this.openTermsAndConditionsModal}
                                                                           style={{textDecoration: 'underline'}}>terms
                                                                        and conditions</a>.</h6>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <button type="button" className="btn-newDesign make-payment-btn"
                                                            onClick={() => this.onButtonEvent('DirectBill')}>
                                                        <span className="label">Pay Direct</span>
                                                        <svg className="icon-arrow after">
                                                            <use xlinkHref="#arrow"></use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    <div id="pay-in-full"
                                         className="form-floating input-wrapper-payment payment-wrapper-input col card payinFull make-payment-card">
                                        <h4 className="label-lower">Pay In Full</h4>
                                        <hr/>
                                        <div className="make-payment-body">
                                            <div className="make-payment-inner">
                                                <p className="make-payment-header">Due Today</p>
                                                <span
                                                    className="value"> {CustomCurrencyFormat(this.state.totalpremium)}
                                                </span>
                                                 {this.state.subDomainId === "2" && 
                                                     <>
                                                         <p className="make-payment-header">ACH Fee</p>
                                                         <span
                                                             className="value">$5 
                                                         </span>
                                                     </>
                                                 }
                                            </div>
                                            {(this.state.PIFServiceFee > 0) &&
                                                <div className="make-payment-inner">
                                                    <p className="make-payment-header">Service Fee</p>
                                                    <span
                                                        className="value">{CustomCurrencyFormat(this.state.PIFServiceFee)}</span>
                                                </div>
                                            }
                                        </div>
                                        <div className="make-payment-bottom">
                                            {this.state.Errors['IsEmpty_PIFIsTermsandConditions'] === true ? <div
                                                className="has-err-msg">{this.state.Errors['PIFIsTermsandConditionsEmptyMsg']}</div> : ''}
                                            <div className='input-wrapper-radio remove-bg'>
                                                <div className="col-12 TermsandConditions-modal-check">

                                                    <>
                                                        <input type="checkbox" id="radio-Active"
                                                               name="PIFIsTermsandConditions"
                                                               checked={this.state.PIFIsTermsandConditions} required
                                                               className={(this.state.Errors['IsEmpty_PIFIsTermsandConditions'] === true ? ' has-err form-check-input form-control mx-1' : 'form-check-input form-control mx-1')}
                                                               onChange={this.PIFIsTermsandConditions}/>
                                                    </>

                                                    <label className="form-check-label">
                                                        <h6 className="mb-0 AutoPay-label-text">You agree to the <a
                                                            href="" onClick={this.openTermsAndConditionsModal}
                                                            style={{textDecoration: 'underline'}}>terms and
                                                            conditions</a>.</h6>
                                                    </label>
                                                </div>
                                            </div>
                                            <button type="button" className="btn-newDesign make-payment-btn"
                                                    onClick={() => this.onButtonEvent('PayFull')} disabled={""}>
                                                <span className="label">Pay in full</span>
                                                <svg className="icon-arrow after">
                                                    <use xlinkHref="#arrow"></use>
                                                </svg>
                                            </button>
                                        </div>

                                    </div>
                                </>


                            </fieldset>
                        </>
                    }

                    {((this.state.DownPaymentPaidStatus && (!parseInt(this.state.totalpremium)) == 0) && (this.state.paymentProcessId == 2 ? true : false) && !this.state.isStetson) &&
                        <>
                            <fieldset
                                className="step-form flex-wrap px-3 row payment-form-field payment-form payment-account-card"
                                data-field="paymentType">
                                <div className="form-group">
                                    {this.state.isCancelled && (this.state.cancelledDate ? (today - cancelledDate < threeDaysInMs) : true) &&
                                        <>
                                            <h6 className="text-danger mt-2"> By making a payment, you acknowledge that
                                                Direct Billing Solutions, LLC (or its affiliate) will submit a request
                                                for reinstatement of your policy on your behalf.</h6>
                                            <h6 className="text-danger">Acceptance of the request and reinstatement of
                                                the policy is at the sole discretion of the insurance company. </h6>
                                            <h6 className="text-danger"> Direct Billing Solutions, LLC (or its
                                                affiliates) cannot guarantee reinstatement.</h6>
                                        </>
                                    }
                                    <label htmlFor="paymentAccount">Payment Account</label>
                                    <select id="paymentAccount" onChange={(e) => this.handleAccountSelect(e)}>
                                        <option value="">Select an account</option>
                                        <option value={this.state.paymentAccount}>
                                            Last 4 Digit: {this.state.paymentAccount.last4}
                                        </option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="scheduleDate">Schedule Date</label>
                                    <input type="date" id="scheduleDate" value={this.state.scheduleDate}
                                           onChange={this.handleDateChange}/>
                                </div>

                                <div className="form-group">
                                    <label>Payment Amount</label>
                                    <div className="radio-group">
                                        <div className="input-wrapper-radio-newDesign">

                                            {((this.state.ispremiumfinance && this.state.DownPaymentPaidStatus && this.state.isPFShow) || (this.state.ispremiumfinance && !this.state.DownPaymentPaidStatus && !this.state.isPFShow)) && (this.state.earnedPremiumPercentage != 100) &&
                                                <>
                                                    <label htmlFor="due">Payment Amount Due:</label>
                                                    <div className="paid-inner">
                                                        <p>{CustomCurrencyFormat(this.state.PFPaymentAmount + this.state.ServiceFee)}</p>
                                                        <input
                                                            type="radio"
                                                            id="due"
                                                            name="paymentAmount"
                                                            value="due"
                                                            checked={this.state.paymentAmount === 'due'}
                                                            onChange={(e) => this.handleAmountChange(e, this.state.PFPaymentAmount + this.state.ServiceFee, "PFMonthly")}
                                                            className={'form-check-input-newDesign form-control'}/>
                                                    </div>
                                                </>
                                            }
                                            {(((this.state.isdirectbilling && this.state.DownPaymentPaidStatus && this.state.isDBShow) || (this.state.isdirectbilling && !this.state.DownPaymentPaidStatus && !this.state.isDBShow)) && (this.state.subDomain !== 'rt' || this.state.earnedPremiumPercentage != 100)) &&
                                                <>
                                                    <label htmlFor="due">Payment Amount Due:</label>
                                                    <div className="paid-inner">
                                                        <p>
                                                            {CustomCurrencyFormat(
                                                                this.state.DBPaymentAmount + this.state.DBServiceFee +
                                                                //this.state.deficiencyAmount +
                                                                (this.state.isSelectedAccount ?
                                                                        (this.state.paymentAccount?.bankAbaNumber === null ?
                                                                        ((this.state.DBPaymentAmount + this.state.DBServiceFee) * this.state.techFee) :
                                                                                0
                                                                        ) : 0
                                                                )
                                                            )}
                                                        </p>
                                                        <input
                                                            type="radio"
                                                            id="due"
                                                            name="paymentAmount"
                                                            value="due"
                                                            checked={this.state.paymentAmount === 'due'}
                                                            onChange={(e) => this.handleAmountChange(e, this.state.DBPaymentAmount+ this.state.DBServiceFee , "DBMonthly")}
                                                            className={'form-check-input-newDesign form-control'}/>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className="input-wrapper-radio-newDesign">
                                            <label htmlFor="outstanding">Total Outstanding Balance: </label>
                                        <div className="paid-inner">
                                            
                                                <p>
                                                    {CustomCurrencyFormat(
                                                        this.state.totalpremium +
                                                        this.state.DBServiceFee +
                                                        this.state.deficiencyAmount +
                                                        (this.state.isSelectedAccount ?
                                                                (this.state.paymentAccount?.bankAbaNumber === null ?
                                                                ((this.state.totalpremium + this.state.DBServiceFee + this.state.deficiencyAmount) * this.state.techFee) :
                                                                        0
                                                                ) : 0
                                                        )
                                                    )}
                                                </p>
                                                <input
                                                    type="radio"
                                                    id="outstanding"
                                                    name="paymentAmount"
                                                    value="outstanding"
                                                    checked={this.state.paymentAmount === 'outstanding'}
                                                onChange={(e) => this.handleAmountChange(e, this.state.totalpremium + this.state.DBServiceFee + this.state.deficiencyAmount, "PIF")}
                                                    className={'form-check-input-newDesign form-control'}/>
                                            </div>
                                        </div>
                                        <div className="input-wrapper-radio-newDesign">
                                            <label htmlFor="other">Other Amount</label>
                                            <div className="paid-inner">
                                            <p></p>
                                                <input
                                                    type="radio"
                                                    id="other"
                                                    name="paymentAmount"
                                                    value="other"
                                                    checked={this.state.paymentAmount === 'other'}
                                                    onChange={(e) => this.handleAmountChange(e, this.state.totalpremium + this.state.DBServiceFee)}
                                                    className={'form-check-input-newDesign form-control'}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {this.state.paymentAmount === 'other' && (
                                    <div className="form-group">
                                        <label htmlFor="otherAmount">Enter Other Amount</label>
                                        <input
                                            type="number"
                                            id="otherAmount"
                                            value={this.state.otherAmount}
                                            onChange={(e) => {
                                                const otherAmount = parseFloat(parseFloat(this.state.otherAmount).toFixed(2)) || 0;
                                                const DBServiceFee = parseFloat(parseFloat(this.state.DBServiceFee).toFixed(2)) || 0;
                                                this.handleOtherAmountChange(e, otherAmount + DBServiceFee);
                                            }}
                                            onBlur={(e) => {
                                                const otherAmount = parseFloat(parseFloat(this.state.otherAmount).toFixed(2)) || 0;
                                                const DBServiceFee = parseFloat(parseFloat(this.state.DBServiceFee).toFixed(2)) || 0;
                                                this.handleOtherBlur(e, otherAmount + DBServiceFee);
                                            }}
                                            min="0"
                                            max={this.state.totalpremium + this.state.DBServiceFee}
                                        />
                                        {/*{this.state.errorMessage && (*/}
                                        {/*    <div className="has-err-msg">{this.state.errorMessage}</div>*/}
                                        {/*)}*/}
                                    </div>
                                )}
                                {this.state.errorMessage && (
                                    <div className="has-err-msg">{this.state.errorMessage}</div>
                                )}
                                {((this.state.isdirectbilling && this.state.DownPaymentPaidStatus && !this.state.isPFShow) || (this.state.isdirectbilling && !this.state.DownPaymentPaidStatus && !this.state.isDBShow) && !this.state.isStetson)
                                    ? (<button id="next-payment-btn" className="btn btn-newDesign next-btn"
                                               type="button" onClick={() => this._MakeAISPayment()}> Next </button>) : (
                                        <button id="next-payment-btn" className="btn btn-newDesign next-btn"
                                                type="button"
                                                onClick={() => this._SelectPaymentMethod()}> Next </button>
                                               )}
                                <div className="mb-2"></div>
                                {((this.state.ispremiumfinance && this.state.DownPaymentPaidStatus && this.state.isPFShow) || (this.state.ispremiumfinance && !this.state.DownPaymentPaidStatus && !this.state.isPFShow)) && (this.state.earnedPremiumPercentage != 100) &&
                                    <>
                                        <div className="input-wrapper-radio-newDesign paid-bottom mt-2 mb-0">
                                            <label htmlFor="Installment Amount" className="fw-bold">Installment
                                                Amount:</label>
                                            {CustomCurrencyFormat(this.state.PFPaymentAmount)}
                                        </div>
                                        <div className="input-wrapper-radio-newDesign paid-bottom mt-2 mb-0">
                                            <label htmlFor="Installment Fee" className="fw-bold">Installment
                                                Fee:</label>
                                            {CustomCurrencyFormat(5)}
                                        </div>
                                    </>
                                }
                                {(((this.state.isdirectbilling && this.state.DownPaymentPaidStatus && this.state.isDBShow) || (this.state.isdirectbilling && !this.state.DownPaymentPaidStatus && !this.state.isDBShow)) && (this.state.subDomain !== 'rt' || this.state.earnedPremiumPercentage != 100)) &&
                                    <>
                                        <div className="input-wrapper-radio-newDesign paid-bottom mt-2 mb-0">
                                            <label htmlFor="Installment Amount" className="fw-bold">Installment
                                                Amount:</label>
                                            <p>{CustomCurrencyFormat(this.state.DBPaymentAmount)}</p>
                                        </div>
                                        <div className="input-wrapper-radio-newDesign paid-bottom mt-2 mb-0">
                                            <label htmlFor="Installment Fee" className="fw-bold">Installment
                                                Fee:</label>
                                            <p>{CustomCurrencyFormat(this.state.DBServiceFee)}</p>
                                        </div>
                                    </>
                                }                                
                                {this.state.AllPolicyTaxes.filter(items => items.amount !== 0).map((items, index) => (
                                    <div className="input-wrapper-radio-newDesign mt-2 mb-0" key={index}>
                                        <label htmlFor={items.description}
                                               className="fw-bold">{items.description}:</label>
                                        <p>{CustomCurrencyFormat(this.state.isReturn ? -Math.abs(items.amount) : items.amount)}</p>
                                    </div>
                                ))}
                                {this.state.isSelectedAccount && (
                                this.state.paymentAccount?.bankAbaNumber === null ? (
                                <>
                                    <div className="input-wrapper-radio-newDesign mt-2 mb-0">
                                        <label htmlFor="Credit Card Fee"
                                                className="fw-bold">Credit Card Fee:</label>
                                        <p>{CustomCurrencyFormat(
                                            this.state.paymentAmount === 'outstanding' ?
                                                ((this.state.totalpremium + this.state.DBServiceFee + this.state.deficiencyAmount) * this.state.techFee) :
                                                this.state.paymentAmount === 'other' ?
                                                    (!isNaN(this.state.otherAmount) ?
                                                        ((this.state.otherAmount + this.state.DBServiceFee) * this.state.techFee) :
                                                        (this.state.DBServiceFee * this.state.techFee)) :
                                                        ((this.state.DBPaymentAmount + this.state.DBServiceFee) * this.state.techFee)
                                        )}</p>
                                    </div>
                                    <div className="input-wrapper-radio-newDesign mt-2 mb-0">
                                        {/*<label htmlFor="Service Fee"*/}
                                        {/*    className="fw-bold">Service Fee:</label>*/}
                                        {/*<p>{CustomCurrencyFormat(5)}</p>*/}
                                    </div>
                                </>
                                ) : (
                                    <div className="input-wrapper-radio-newDesign mt-2 mb-0">
                                        {/*<label htmlFor="ACH Fee"*/}
                                        {/*        className="fw-bold">Service Fee:</label>*/}
                                        {/*<p>{CustomCurrencyFormat(5)}</p>*/}
                                    </div>
                                )
                                )}


                                <p className="description-payment">
                                    Please call Customer Service at (314) 576-0007 for assistance.
                                </p>

                                {this.state.isAISAutoPay && (
                                    <p className="autopay-note fw-bold mt-3">
                                        You are currently enrolled in AutoPay, and future-dated payments cannot be
                                        scheduled while AutoPay is active.
                                    </p>
                                )}
                            </fieldset>

                        </>
                    }

                </form>
                <>
                    <form
                        className="step-form-wrapper-newDesign position-relative w-75 step-makepayment-pay payment-details-form form-dismiss"
                        onKeyDown={this._ModelPayment_EnterButton}>
                        <fieldset className="step-form flex-wrap px-3 row payment-form-field fullHeight"
                                  data-field="verify" data-next="payement" style={{boxSizing: 'content-box'}}>
                            <div className="pt20px">
                                {this.state.DBDocusignLoaderStatus && <LoaderContents/>}
                            </div>
                            <div id="payment-details"
                                 className="form-floating payment-wrapper-input col card make-payment-card">
                                <h4 className="label-lower">Payment Details</h4>
                                <hr/>
                                <div className="make-payment-body">
                                    <div className="make-payment-inner">
                                        <p className="make-payment-header">Insured Name: </p>
                                        <span className="value">{this.state.fullName ?? ""}</span>
                                    </div>
                                    <div className="make-payment-inner">
                                        <p className="make-payment-header">Address: </p>
                                        <span className="value">
                                            {this.state.address ?? ""}
                                            {this.state.mdl.addressLine2 ? `, ${this.state.mdl.addressLine2}` : ''}
                                            {this.state.mdl.addressLine3 ? `, ${this.state.mdl.addressLine3}` : ''}
                                        </span>
                                    </div>
                                    <div className="make-payment-inner">
                                        {!([2, 3].includes(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId)) &&
                                            <>
                                                <p className="make-payment-header">General Agent: </p>
                                                <span className="value">{this.state.createdByName ?? ""}</span>
                                            </>
                                        }
                                        {(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId == 2) &&
                                            <>
                                                <p className="make-payment-header">General Agent: </p>
                                                <span
                                                    className="value">{this.state.agencyName?.trim() ? this.state.agencyName : this.state.createdByName}</span>
                                            </>
                                        }
                                        {(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId == 3) &&
                                            <>
                                                <p className="make-payment-header">GEICO Agent: </p>
                                                <span className="value">{this.state.createdByName ?? ""}</span>
                                            </>
                                        }
                                    </div>
                                    <div className="make-payment-inner">
                                        {this.state.isPFPaid &&
                                            <>
                                                <p className="make-payment-header">Coverage Type: </p>
                                                <span className="value">{this.state.PFCoverageType ?? ""}</span>
                                            </>
                                        }
                                        {this.state.isDBPaid &&
                                            <>
                                                <p className="make-payment-header">Coverage Type: </p>
                                                <span className="value">{this.state.DBCoverageType ?? ""}</span>
                                            </>
                                        }
                                        {([3, 5, 6].includes(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId) && this.state.isPaidInFull) &&
                                            <>
                                                <p className="make-payment-header">Coverage Type: </p>
                                                <span className="value">{this.state.PFCoverageType ?? ""}</span>
                                            </>
                                        }
                                        {((JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId === 2) && this.state.isPaidInFull) &&
                                            <>
                                                <p className="make-payment-header">Coverage Type: </p>
                                                <span className="value">{this.state.DBCoverageType ?? ""}</span>
                                            </>
                                        }

                                        {/*{this.state.isPaidInFull &&*/}
                                        {/*    <><label><b>Coverage Type</b></label>: <label>{"Comprehensive Coverage"}</label></>*/}
                                        {/*}*/}

                                    </div>
                                    {(this.state.UserDetails !== null && this.state.UserDetails.FlowGroupId === FlowGroups.Insured) &&
                                        <div className="make-payment-inner">
                                            <p className="make-payment-header">Phone Number: </p>
                                            <span className="value">{this.state.mdl.phoneNumber ?? ""}</span>
                                        </div>
                                    }

                                    {(this.state.subDomain != 'rt' || this.state.isStetson) &&
                                        <div className="make-payment-inner">
                                            <p className="make-payment-header">Email Address: </p>
                                            <span className="value">{this.state.mdl.emailAddress ?? ""}</span>
                                        </div>
                                    }
                                    {(this.state.subDomain == 'rt' && !this.state.isStetson) &&
                                        <div className="make-payment-inner">
                                            <p className="make-payment-header">Insured's Email: </p>
                                            <input
                                                className={this.state.emailValid ? 'form-control email-box' : 'has-err form-control email-box'}
                                                type="email"
                                                value={this.state.updatedEmail}
                                                onChange={this.handleEmailChange} required id="email" placeholder="Enter your email address"/>
                                        </div>
                                    }

                                    <div className="make-payment-inner">
                                        <p className="make-payment-header">Policy Status: </p>
                                        <span className="value">{"Pending"}</span>
                                    </div>

                                    <div className="make-payment-inner">
                                        <p className="make-payment-header">Policy Effective Date: </p>
                                        <span
                                            className="value">{DateFormat(this.state.policyEffectiveDate) ?? ""}</span>
                                    </div>

                                    <div className="make-payment-inner">
                                        <p className="make-payment-header">Payment Amount: </p>
                                        <span
                                            className="value">{CustomCurrencyFormat(this.state.pmtAmount) ?? ''}</span>
                                    </div>

                                    {this.state.AllPolicyTaxes.filter(items => items.amount !== 0).map((items, index) => (
                                        <div className="make-payment-inner" key={index}>
                                            <p className="make-payment-header">{items.description}: </p>
                                            <span className="value">{CustomCurrencyFormat(items.amount) ?? ""}</span>
                                        </div>
                                    ))}
                                    {/*{(this.state.SelectedType === "DirectBill" && this.state.isDBPaid) &&*/}
                                    {/*    <>*/}
                                    {/*        <div className="mx-auto mb-12 text-align-left input-wrapper-payment agree-check remove-bg">*/}
                                    {/*            {this.state.Errors['IsEmpty_IsTermsandConditions'] === true ? <div className="has-err-msg">{this.state.Errors['IsTermsandConditionsEmptyMsg']}</div> : ''}*/}
                                    {/*            <div className='input-wrapper-radio remove-bg' style={{ background: "transparent" }}>*/}
                                    {/*                <div className="col-12 TermsandConditions-modal-check">*/}
                                    {/*                    <input type="checkbox" id="radio-Active" name="IsTermsandConditions" checked={this.state.IsTermsandConditions} required className={'form-check-input form-control' + (this.state.Errors['IsEmpty_IsTermsandConditions'] === true ? ' has-err' : '')} onChange={this.IsTermsandConditions} />*/}
                                    {/*                    <svg viewBox="0 0 21 21" className="promotion-svg">*/}
                                    {/*                        <polyline points="5 10.75 8.5 14.25 16 6"></polyline>*/}
                                    {/*                    </svg>*/}
                                    {/*                    <label className="form-check-label" style={{ "marginTop": "9px" }}>*/}
                                    {/*                        <h6 className="mb-0 AutoPay-label-text">You agree to the terms and conditions.</h6>*/}

                                    {/*                    </label>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </>*/}
                                    {/*}*/}
                                </div>
                                <div className="payment-details-bottom">
                                    {this.state.isDBPaid && ((!this.state.AlreadyAutoPay || !this.state.DownPaymentPaidStatus) && !this.state.isPaidInFull) &&
                                        <>
                                            <div className="input-wrapper-payment remove-bg">
                                                <div className='input-wrapper-radio remove-bg'
                                                     style={{background: "transparent"}}>
                                                    <div className="col-12 TermsandConditions-modal-check">
                                                        <input type="checkbox" id="radio-AutoPay" name="IsAutoPay"
                                                               checked={this.state.UpdateAutoPayMdl.IsAutoPay} required
                                                               className={'form-check-input form-control mx-1'}
                                                               onChange={this.IsAutoPay}/>
                                                        <label className="form-check-label"
                                                               style={{"marginTop": "9px"}}>
                                                            <h6 className="mb-0 AutoPay-label-text"><a href=""
                                                                                                       onClick={this.openautopayackModal}
                                                                                                       style={{textDecoration: 'underline'}}>Autopay</a>
                                                            </h6>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {/*{(this.state.UserDetails === null || this.state.UserDetails.FlowGroupId === FlowGroups.Insured) && this.state.isTextAct === false &&*/}
                                    {/*    <div className="input-wrapper-payment">*/}
                                    {/*        {this.state.Errors['IsEmpty_IsTextActive'] === true ? <div className="has-err-msg">{this.state.Errors['IsTextActiveEmptyMsg']}</div> : ''}*/}
                                    {/*        <div className='input-wrapper-radio remove-bg'>*/}
                                    {/*            <div className="col-12 promotional-modal-check">*/}
                                    {/*                <input type="checkbox" id="radio-Active" name="IsTextActive" checked={this.state.IsTextActive} required className={(this.state.Errors['IsEmpty_IsTextActive'] === true ? ' has-err form-check-input form-control' : 'form-check-input form-control')} onChange={this.IsTextActive} />*/}
                                    {/*                <label className="form-check-label" style={{ "marginTop": "2px" }}>*/}
                                    {/*                    <h6 className="mb-0 AutoPay-label-text">You agree to recieve automated promotional messages. You*/}
                                    {/*                        also agree to the <a href={this.state.url + "/termsofuse"}>  Terms of Service</a> and <a href={this.state.url + "/privacypolicy"}> Privacy Policy</a>.*/}
                                    {/*                        This agreement isn't a condition of any purchase. 4 Msgs/Month.*/}
                                    {/*                        Msg & Data rates may apply. Reply STOP to end or HELP for help. </h6>*/}
                                    {/*                </label>*/}

                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*}*/}
                                    {/*{(this.state.UserDetails !== null) && this.state.isTextAct === false &&*/}
                                    {/*    <div className="input-wrapper-payment">*/}
                                    {/*        {this.state.Errors['IsEmpty_IsTextActive'] === true ? <div className="has-err-msg">{this.state.Errors['IsTextActiveEmptyMsg']}</div> : ''}*/}
                                    {/*        <div className='input-wrapper-radio remove-bg'>*/}
                                    {/*            <div className="col-12 promotional-modal-check">*/}
                                    {/*                <input type="checkbox" id="radio-Active" name="IsTextActive" checked={this.state.IsTextActive} required className={(this.state.Errors['IsEmpty_IsTextActive'] === true ? ' has-err form-check-input form-control' : 'form-check-input form-control')} onChange={this.IsTextActive} />*/}
                                    {/*                <label className="form-check-label" style={{ "marginTop": "2px" }}>*/}
                                    {/*                    <h6 className="mb-0 AutoPay-label-text">You agree to recieve automated promotional messages. You*/}
                                    {/*                        also agree to the <a href="#">  Terms of Service</a> and <a href="#"> Privacy Policy</a>.*/}
                                    {/*                        This agreement isn't a condition of any purchase. 4 Msgs/Month.*/}
                                    {/*                        Msg & Data rates may apply. Reply STOP to end or HELP for help.</h6>*/}
                                    {/*                </label>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*}*/}
                                    {this.state.isDBPaid && ((!this.state.AlreadyAutoPay || !this.state.DownPaymentPaidStatus) && !this.state.isPaidInFull) && this.state.UpdateAutoPayMdl.IsAutoPay &&
                                        <>
                                            <div className="input-wrapper-payment remove-bg">
                                                <div className='input-wrapper-radio remove-bg'
                                                     style={{background: "transparent"}}>
                                                    <div className="col-12 TermsandConditions-modal-check">
                                                        <label className="form-check-label"
                                                               style={{"marginTop": "9px"}}>
                                                            <h6 className="mb-0 AutoPay-label-text">How would you like
                                                                to receive confirmations of automatic payment</h6>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-wrapper-payment remove-bg">
                                                {this.state.Errors['IsEmpty_IsTextorEmailActive'] === true ? <div
                                                    className="has-err-msg">{this.state.Errors['IsTextorEmailActiveEmptyMsg']}</div> : ''}
                                            </div>

                                            <div className="input-wrapper-payment remove-bg">

                                                <div className='input-wrapper-radio remove-bg'
                                                     style={{background: "transparent"}}>
                                                    <div className="col-12 TermsandConditions-modal-check">
                                                        <input type="checkbox" id="radio-AutoPay" name="IsEmailActive"
                                                               checked={this.state.IsEmailActive} required
                                                               className={'form-check-input form-control mx-1'}
                                                               onChange={this.IsEmailActive}/>
                                                        <label className="form-check-label"
                                                               style={{"marginTop": "9px"}}>
                                                            <h6 className="mb-0 AutoPay-label-text">Email </h6>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className='input-wrapper-radio remove-bg'
                                                     style={{background: "transparent"}}>
                                                    <div className="col-12 TermsandConditions-modal-check">
                                                        <input type="checkbox" id="radio-AutoPay" name="IsTextActive"
                                                               checked={this.state.IsTextActive} required
                                                               className={'form-check-input form-control mx-1'}
                                                               onChange={this.IsTextActive}/>
                                                        <label className="form-check-label"
                                                               style={{"marginTop": "9px"}}>
                                                            <h6 className="mb-0 AutoPay-label-text">Text </h6>
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>
                                            <br/>
                                            <div className="gap-1">
                                                {this.state.IsEmailActive &&
                                                    <>
                                                        <div className="input-wrapper-payment remove-bg gap-4"
                                                             style={{height: 40}}>
                                                            <div className='input-wrapper-radio remove-bg'
                                                                 style={{background: "transparent"}}>
                                                                <div className="col-6 TermsandConditions-modal-check">
                                                                    <label className="form-check-label"
                                                                           style={{"marginTop": "9px"}}>
                                                                        <h6 className="mb-0 AutoPay-label-text"><a
                                                                            href=""
                                                                            onClick={this.openelectronicnoticeModal}
                                                                            style={{textDecoration: 'underline'}}>Electronic
                                                                            Notice Acknowledgement</a></h6>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br/>
                                                    </>
                                                }
                                                {this.state.IsTextActive &&
                                                    <div className=" make-payment-inner gap-2 col-4  items-center h-10"
                                                         style={{paddingLeft: 20}}>

                                                        <input
                                                            className={this.state.phoneNumberValid ? 'form-control phone-box' : 'has-err form-control phone-box'}
                                                            type="text"
                                                            id="phoneNumber"
                                                            name="phoneNumber"
                                                            value={this.state.updatedPhoneNumber}
                                                            onChange={this.handlePhoneNumberChange}
                                                            placeholder="Enter phone number"
                                                            maxLength="14"
                                                            required
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    }

                                    {this.state.isDBPaid &&
                                        <>
                                            <br/>
                                            {this.state.Errors['IsEmpty_DBDisclosureAgreementIsTermsandConditions'] === true ?
                                                <div
                                                    className="has-err-msg">{this.state.Errors['DBDisclosureAgreementIsTermsandConditionsEmptyMsg']}</div> : ''}
                                            <div className="input-wrapper-payment input-wrapper-radio remove-bg">

                                                <div className='remove-bg'>
                                                    <div className="col-12 TermsandConditions-modal-check">
                                                        <input type="checkbox" id="radio-Active"
                                                               name="DBDisclosureAgreementIsTermsandConditions"
                                                               checked={this.state.DBDisclosureAgreementIsTermsandConditions}
                                                               required
                                                               className={(this.state.Errors['IsEmpty_DBDisclosureAgreementIsTermsandConditions'] === true ? ' has-err form-check-input form-control mx-1' : 'form-check-input form-control mx-1')}
                                                               onChange={this.DBDisclosureAgreementIsTermsandConditions}/>
                                                        <label className="form-check-label"
                                                               style={{"marginTop": "9px"}}>
                                                            <h6 className="mb-0 AutoPay-label-text">You agree to the
                                                                terms and conditions described in <a
                                                                    href={"" + this.state.DBDisclosurePath}
                                                                    target="_blank">Direct Billing Disclosure
                                                                    Agreement</a>.</h6>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="text-center d-flex justify-content-center gap-20">
                                        <button type="button" id="PayBack"
                                                className="btn-newDesign-foil make-payment-btn"
                                                onClick={() => this.onButtonEvent('PaymentTypeBack')}>
                                            <svg className="icon-arrow before">
                                                <use xlinkHref="#arrow-left"></use>
                                            </svg>
                                            <span className="label">Back</span>
                                        </button>
                                        <button type="button" id="MethodofPayment"
                                                className="btn-newDesign make-payment-btn"
                                                onClick={() => this._SelectPaymentMethod()}>
                                            <span className="label">Continue to payment</span>
                                            <svg className="icon-arrow after">
                                                <use xlinkHref="#arrow"></use>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        {(this.state.DownPaymentPaidStatus && (this.state.paymentProcessId == 2 ? true : false) && !this.state.isStetson) &&
                            <form className="payment-form">
                                <div className="form-group">
                                    {this.state.isCancelled && (this.state.cancelledDate ? (today - cancelledDate < threeDaysInMs) : true) &&
                                        <>
                                            <h6 className="text-danger mt-2"> By making a payment, you acknowledge that
                                                Direct Billing Solutions, LLC (or its affiliate) will submit a request
                                                for reinstatement of your policy on your behalf.</h6>
                                            <h6 className="text-danger">Acceptance of the request and reinstatement of
                                                the policy is at the sole discretion of the insurance company. </h6>
                                            <h6 className="text-danger"> Direct Billing Solutions, LLC (or its
                                                affiliates) cannot guarantee reinstatement.</h6>
                                        </>
                                    }
                                    <label htmlFor="paymentAccount">Payment Account</label>
                                    <select id="paymentAccount">
                                        <option value="">Select an account</option>
                                        <option value={this.state.paymentAccount}>
                                            {this.state.paymentAccount.paymentAccountType === 1
                                                ? this.state.paymentAccount.bankAbaNumber
                                                : this.state.paymentAccount.cardAccountNumber}
                                        </option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="scheduleDate">Schedule Date</label>
                                    <input type="date" id="scheduleDate" value={this.state.scheduleDate}
                                           onChange={this.handleDateChange}/>
                                </div>

                                <div className="form-group">
                                    <label>Payment Amount</label>
                                    <div className="radio-group">
                                        <div>
                                            <input
                                                type="radio"
                                                id="due"
                                                name="paymentAmount"
                                                value="due"
                                                checked={this.state.paymentAmount === 'due'}
                                                onChange={this.handleAmountChange}
                                            />
                                            <label htmlFor="due">Payment Amount Due: $1,286.47</label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                id="outstanding"
                                                name="paymentAmount"
                                                value="outstanding"
                                                checked={this.state.paymentAmount === 'outstanding'}
                                                onChange={this.handleAmountChange}
                                            />
                                            <label htmlFor="outstanding">Total Outstanding Balance: $1,286.47</label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                id="other"
                                                name="paymentAmount"
                                                value="other"
                                                checked={this.state.paymentAmount === 'other'}
                                                onChange={this.handleAmountChange}
                                            />
                                            <label htmlFor="other">Other Amount</label>
                                        </div>
                                    </div>
                                </div>

                                <button className="button-payment" type="submit"
                                        onClick={() => this._SelectPaymentMethod()}> Next
                                </button>

                                <p className="description-payment">
                                    Please call Customer Service at (314) 576-0007 for assistance.
                                </p>
                            </form>
                        }
                    </form>
                </>


                <div className="d-flex justify-content-center mt-4">
                    <svg style={{display: "none"}}>
                        <defs>
                            <symbol id="arrow-left" viewBox="0 0 35 15">
                                <title>Arrow Left</title>
                                <path id="Layer" className="s0"
                                      d="m7.73 9.14l2.17 2.17l-2.83 2.83l-7.07-7.07l7.07-7.07l2.83 2.83l-2.31 2.31l27.31 0v4l-27.17 0z"></path>
                            </symbol>
                        </defs>
                    </svg>
                    <svg style={{display: "none"}}>
                        <defs>
                            <symbol id="arrow" viewBox="0 0 35 15">
                                <title>Arrow</title>
                                <path
                                    d="M27.172 5L25 2.828 27.828 0 34.9 7.071l-7.07 7.071L25 11.314 27.314 9H0V5h27.172z "/>
                            </symbol>
                        </defs>
                    </svg>
                </div>
                <div className="modal fade" tabIndex="-1" id="modalTermsandConditions"
                     aria-labelledby="modal Terms and Condtion selection" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" >
                        <div className="modal-content">
                            <div className="modal-header pt-4 border-0">
                                <h4 className="modal-title text-center">Terms & Conditions</h4>
                                <button type="button" id="btncloseMdl" className="btn-close position-absolute"
                                        data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                {(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId) === PartnerId.rt &&
                                    <>
                                        <div>
                                            <div>
                                                <p style={{
                                                    fontSize: '20px',
                                                    fontWeight: 'bold',
                                                    display: 'inline'
                                                }}>Legal Disclaimer :</p>
                                                <p style={{fontStyle: 'italic', display: 'inline'}}>
                                                    {' '}Ryan Specialty, LLC, a Delaware limited liability company, is
                                                    the owner of RSG Specialty, LLC, and RSG Underwriting Managers, LLC,
                                                    both Delaware limited liability companies which conduct business
                                                    through their divisions, series, and licensed subsidiaries. In
                                                    California: RSG Specialty Insurance Services, LLC (License #0G97516)
                                                    and RSG Insurance Services, LLC License #0E50879.
                                                </p>
                                            </div>
                                            <div>
                                                <p style={{
                                                    fontSize: '20px',
                                                    fontWeight: 'bold',
                                                    display: 'inline'
                                                }}>XGen Charges and Fees :</p>
                                                <p style={{fontStyle: 'italic', display: 'inline'}}>
                                                    {' '}xGen charges a fee for your payment and a fee for each
                                                    installment, if selected. Although you will see these charges to
                                                    your credit card, this fee does not go to Ryan Specialty Group and
                                                    is separate and distinct from payment of your invoice. This xGen
                                                    service does not guarantee a same day payment receipt. Please refer
                                                    to your invoice for other payment options.
                                                </p>
                                            </div>
                                            <div>
                                                <p style={{
                                                    fontSize: '20px',
                                                    fontWeight: 'bold',
                                                    display: 'inline'
                                                }}>Authorization and Consent :</p>
                                                <p style={{fontStyle: 'italic', display: 'inline'}}>
                                                    {' '}By clicking Send, I consent and authorize Ryan Specialty Group
                                                    or its representative to collect my insurance premiums by charging
                                                    my credit/debit card, including repeat payments if I have selected a
                                                    repeat payment option. My authorization for recurring payments, if
                                                    any, will remain in effect until I withdraw that authorization. If I
                                                    wish to withdraw authorization for recurring payments, I will log
                                                    into the xGen portal and turn off this feature under the Scheduled
                                                    Payments tab. I agree that it is my responsibility to have
                                                    sufficient funds in my bank account to cover the payments withdrawn
                                                    from my account, and that otherwise my policy may be canceled or
                                                    expire. I am responsible for reviewing any billing notices presented
                                                    to me via mail or electronically at the email address on file with
                                                    my insurance agent. If my email has changed, I will enter the new
                                                    email into xGEN and will let my insurance agent know.
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                }
                                {(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId) !== PartnerId.rt &&
                                    <>
                                        <div>

                                            <h5>The fees associated with the payment option and payment method will be
                                                disclosed on the portal portrayed as a monthly installment fee.</h5>

                                            <ul>
                                                <li>
                                                    If you select the monthly installment option and default on your
                                                    payment obligation, you hereby irrevocably
                                                    appoint {JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomain} the
                                                    power of attorney to cancel your insurance policy and collect
                                                    any unearned premiums or other amounts under your policy directly
                                                    from the insurance carrier and apply any proceeds from the insurance
                                                    carrier to any payment obligations that are rightly due
                                                    to {JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomain}.
                                                </li>

                                                <li> You agree
                                                    that {JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomain} may
                                                    assign or transfer any and all of its interest of this agreement and
                                                    your power of attorney to any other person or entity
                                                </li>
                                            </ul>
                                        </div>
                                    </>
                                }
                                <div className="mt-5 d-flex justify-content-center mt-4">
                                    <div className="d-flex ps-3"><Button type="button"
                                                                         className="btn-next center-aligned"
                                                                         onClick={() => this.onTermsandConditionsAccept()}><span
                                        className="label">Accept</span></Button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" tabIndex="-1" id="modalautopayack"
                     aria-labelledby="modal AutoPay Acknowledgment selection" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" >
                        <div className="modal-content">
                            <div className="modal-header pt-4 border-0">
                                <h4 className="modal-title text-center">Consent to Automatic Recurring Payment</h4>
                                <button type="button" id="btncloseMdl" className="btn-close position-absolute"
                                        data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <div>
                                        <p style={{fontStyle: 'italic', display: 'inline'}}>
                                            {' '}By checking the <span
                                            style={{fontWeight: 'bold'}}>&quot;Auto-Pay&quot;</span> box in the payment
                                            portal, I hereby elect to set-up my account with recurring auto-pay using
                                            the payment method used for my down payment. My authorization for recurring
                                            payments, if any, will remain in effect until I withdraw that authorization.
                                            If I wish to withdraw authorization for recurring payments, I may log into
                                            the AIS portal and turn off recurring payments. I agree that it is my
                                            responsibility to have sufficient funds in my bank account to cover the
                                            payments withdrawn from my account, and that otherwise my policy may be
                                            canceled or expire. I am responsible for reviewing any notices presented to
                                            me via mail or electronically at the email address on file with my insurance
                                            agent. <span style={{fontWeight: 'bold'}}> If my email has changed, I will enter the new email address into the AIS portal and will let my insurance agent know.</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-5 d-flex justify-content-center mt-4">
                                    <div className="d-flex ps-3"><Button type="button"
                                                                         className="btn-next center-aligned"
                                                                         onClick={() => this.onAutoPayAccept()}><span
                                        className="label">Accept</span></Button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" tabIndex="-1" id="modalectronicnotice"
                     aria-labelledby="modal Electronic Notice Acknowledgment selection" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" >
                        <div className="modal-content" style={{padding: '20px', borderRadius: '8px'}}>
                            <div className="modal-header pt-4 border-0 text-center">
                                <h4 className="modal-title">Consent to Electronic Billing and Notices</h4>
                                <button type="button" id="btncloseMdl" className="btn-close position-absolute"
                                        data-bs-dismiss="modal" aria-label="Close"
                                        style={{top: '10px', right: '10px'}}></button>
                            </div>
                            <div className="modal-body" style={{lineHeight: '1.8', fontSize: '1.1em', padding: '20px'}}>
                                <p style={{fontStyle: 'italic', marginBottom: '16px'}}>
                                    By checking the <span style={{fontWeight: 'bold'}}>"Email"</span> box in the payment
                                    portal, I am providing my consent and agreement to receive all notices (including,
                                    but not limited to, billing notices, late payment notices, and cancellation notices)
                                    via email.
                                </p>

                                <ol style={{fontStyle: 'italic', paddingLeft: '40px'}}>
                                    <li><b>Your right to consent.</b> To the extent permitted by law or regulation, this
                                        agreement authorizes RT Specialty, AIS, and Direct Billing Solutions, LLC
                                        ("DBS") to accept your electronic signature as your valid,, legally effective
                                        signature and to electronically send your notices related to your installment
                                        billing account (the "Notices"). You have the option, at any time, to receive a
                                        paper copy of any communication from us. You also have the choice to receive
                                        certain materials electronically and others on paper. The documents that may be
                                        delivered electronically are listed in Section 7. In accordance with law, RT
                                        Specialty or its designee we will notify you of any additional documents added
                                        to this list. You will continue to receive paper copies of documents that are
                                        not sent electronically.
                                    </li>
                                    <li><b>Withdrawal of consent.</b> You may withdraw your consent to receive
                                        electronic notices at any time, you must contact AIS at (314) 237-0085 to set up
                                        paper billing. If you opt to receive paper bills, you may be required to assume
                                        the cost of certified mailing. These rates may change depending upon certified
                                        mail costs.
                                    </li>
                                    <li><b>Methods and timing of delivery of electronic documents:</b>
                                        <ul style={{paddingLeft: '40px'}}>
                                            <li>When you consent to these terms and conditions, then the materials
                                                identified in Section 7 may be provided to you in electronic form.
                                            </li>
                                            <li>If you consent to electronic delivery of the notices, we will send these
                                                to you via email.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <b>Access your materials promptly.</b> Your rights relating to your notices may
                                        be time sensitive. Any document that can be accessed via a link we send to you
                                        in an email is deemed to be delivered on the date we send you the email. When
                                        you receive our email alerting you that a document or a message is waiting for
                                        you at a secure website, you should promptly access the site and read the
                                        documents and messages.
                                    </li>
                                    <li>
                                        <b>Updating your information.</b> If you consent to receive documents and
                                        communications electronically, please ensure that you update your email address
                                        by entering it into the AIS portal or by calling the number shown on your bill.
                                    </li>
                                    <li>
                                        <b>Hardware and Software requirements.</b> To receive documents and
                                        communications electronically, you must have access to a computer with an
                                        Internet connection. If you would like to be able to save the documents you
                                        receive, the computer should have a hard drive or other storage device, or be
                                        connected to a printer. You must also have an email account to receive
                                        communications.
                                        <br/>
                                        In order to receive your documents electronically, you will need Adobe Acrobat
                                        Reader 3.0 (or higher).
                                        <br/>
                                        You are responsible for ensuring that neither your software nor your Internet
                                        service provider inhibits or interferes with the electronic delivery of the
                                        materials described herein. We will notify you regarding hardware and software
                                        changes.
                                    </li>
                                    <li>
                                        <b>We may deliver the following documents electronically:</b>
                                        <ul style={{paddingLeft: '40px'}}>
                                            <li>Billing and payment materials, including billing statements and notices
                                                of premium changes
                                            </li>
                                            <li>Notices of Late payment</li>
                                            <li>Notice of Cancellation</li>
                                            <li>Request for Reinstatement</li>
                                        </ul>
                                    </li>
                                </ol>

                                <p style={{fontStyle: 'italic', marginBottom: '16px'}}>
                                    By checking the <span style={{fontWeight: 'bold'}}>"Email Notices"</span> box in the
                                    payment portal, you additionally confirm to us that:
                                </p>
                                <ul style={{fontStyle: 'italic', paddingLeft: '40px', marginBottom: '16px'}}>
                                    <li>You can access and read this Consent and that you agree to its terms and
                                        conditions;
                                    </li>
                                    <li>You consent to do business with us electronically;</li>
                                    <li>You acknowledge that documents delivered electronically may contain information
                                        regarding your personal financial matters and consent to the electronic delivery
                                        of such information;
                                    </li>
                                    <li>You can print this Consent or save/access it for future reference;</li>
                                    <li>This Consent remains valid until you withdraw it.</li>
                                </ul>
                                <p style={{fontWeight: 'bold', fontStyle: 'italic'}}>
                                    For purposes of receiving electronic transmission of documents from us, as set forth
                                    above, the email address you provided during registration will be used. This email
                                    address may be updated within your profile page.
                                </p>

                                <div className="mt-5 d-flex justify-content-center">
                                    <div className="d-flex ps-3">
                                        <Button type="button" className="btn-next center-aligned"
                                                onClick={() => this.onelectronicnoticAccept()}><span
                                            className="label">Accept</span></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" tabIndex="-1" id="modalEmailConfirm"
                     aria-labelledby="modal Email Confirmation selection" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered fppwidth" >
                        <div className="modal-content">
                            <div className="pt-4 border-0">
                                <h4 className="modal-title text-center">Email Confirmation</h4>
                                <button type="button" id="btncloseMdl" className="btn-close position-absolute"
                                        data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div style={{textAlign: "center"}} className="modal-body">
                                    <p style={{display: "inline"}}>
                                        Please confirm your email address: <span
                                        style={{fontWeight: 'bold'}}>{this.state.updatedEmail}</span>. This email will be used for your account setup
                                        and all future communications.
                                    </p>
                                <div className="button-container mt-4">
                                    <button type="button" className="btn btn-newDesign-foil"
                                            onClick={() => this.onEmailConfirmCancel()}>
                                        <span className="label">Go Back</span>
                                    </button>
                                    <button type="button" className="btn btn-newDesign"
                                            onClick={() => this.onEmailConfirm()}>
                                        <span className="label">Confirm</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );

    }

}

export default MakePayment;