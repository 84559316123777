import React, { useState } from 'react';
import { authHeader, subDomain } from "../../../common/Common";
import { AddAgaveCustomer, GetInsuredDetails } from '../../../services/PaymentServices';

const AgavePaymentPage = (props) => {
    const UserDetails = authHeader();
    const SubDomain = subDomain();
    const confirmationNumber = Math.floor((Math.random() * 1000000) + 1);
    const subDomainUrl = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl;
    const isAutoPay = props.props.isAutoPay;
    const isIframe = props.props.isIframe;
    const pi = props.props.payment_intent;
    const pics = props.props.payment_intent_client_secret;
    const paymentId = props.props.paymentId;
    const policyNumber = props.props.policyNumber;
    const fullName = props.props.fullName;
    const invoiceNumber = props.props.invoiceNumber;
    const isPFPaid = props.props.isPFPaid
    const isDBPaid = props.props.isDBPaid
    const isPaidInFull = props.props.isPaidInFull
    const isPayFull = props.props.isPayFull
    const isDownPayment = props.props.isDownPayment
    const amount = props.props.amount
    const textActive = props.props.textActive
    const emailActive = props.props.emailActive
    const customerId = props.props.customerId
    const transferGroup = props.props.transferGroup
    const isMonthlyPayment = props.props.isMonthlyPayment
    const [paymentType, setPaymentType] = useState('CARD');
    const [cardInfo, setCardInfo] = useState({
        cardNumber: '',
        expiry: '',
        cvc: '',
        country: '',
        zip: ''
    }); 
    const [bankAccountNumber, setBankAccountNumber] = useState();
    const [bankRoutingNumber, setBankRoutingNumber] = useState();
    const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
    const [brand, setBrand] = useState('');

    const handleCardInfoChange = (e) => {
        const { name, value } = e.target;

        if (name === 'expiry') {
            let formattedValue = value.replace(/\D/g, ''); // Remove all non-numeric characters

            if (formattedValue.length >= 3) {
                let month = parseInt(formattedValue.slice(0, 2), 10);
                const year = formattedValue.slice(2, 4);

                if (month > 12) {
                    month = (month.toString().charAt(0)).padStart(2, '0') % 12 || 12; // Map values like 13 -> 01, 35 -> 11
                }

                formattedValue = `${String(month).padStart(2, '0')}/${year}`;
            }

            setCardInfo((prevCardInfo) => ({
                ...prevCardInfo,
                [name]: formattedValue,
            }));
        } else if (name === 'zip') {
            const zipValue = value.replace(/\D/g, '');

            setCardInfo((prevCardInfo) => ({
                ...prevCardInfo,
                [name]: zipValue,
            }));
        } else if (name === 'cardNumber') {
            const cardNumberValue = value.replace(/\D/g, '').slice(0, 16); // Limit cardNumber to 16 characters
            setBrand(detectBrand(cardNumberValue));
            setCardInfo((prevCardInfo) => ({
                ...prevCardInfo,
                [name]: cardNumberValue,
            }));
        } else if (name === 'cvc') {
            const cvcValue = value.replace(/\D/g, '').slice(0, 3); // Limit CVC to 3 characters

            setCardInfo((prevCardInfo) => ({
                ...prevCardInfo,
                [name]: cvcValue,
            }));
        } else {
            setCardInfo((prevCardInfo) => ({
                ...prevCardInfo,
                [name]: value,
            }));
        }
    };


    const handlePay = () => {
        _PaymentSuccess();

        // Add payment submission logic here
    };

    const detectBrand = (number) => {
        // Visa
        if (/^4/.test(number)) {
            return 'Visa';
        }
        // MasterCard
        if (/^5[1-5]/.test(number) || /^(222[1-9]|22[3-9]\d|2[3-6]\d{2}|27[01]\d|2720)/.test(number)) {
            return 'MasterCard';
        }
        // American Express
        if (/^3[47]/.test(number)) {
            return 'American Express';
        }
        // Discover
        if (/^6(?:011|5[0-9]{2}|4[4-9][0-9]|22[1-9][0-9]{2})/.test(number)) {
            return 'Discover';
        }
        // JCB
        if (/^(?:2131|1800|35\d{3})/.test(number)) {
            return 'JCB';
        }
        // Diners Club
        if (/^3(?:0[0-5]|[68]\d)/.test(number)) {
            return 'Diners Club';
        }

        return 'Unknown';
    };
    
    const _PaymentSuccess = async () => {
        const expiryParts = cardInfo.expiry.split('/');
        let cardInformation = {
            cardNumber: cardInfo.cardNumber,
            exp_month: expiryParts[0],
            exp_year: '20' + expiryParts[1],
            country: cardInfo.country,
            zip: cardInfo.zip,
            cardBrand: (brand == 'Unknown') ? 'Visa' : brand
        } 
        let stripePaymentMdl = {
            amount: amount, //Amount in cents
            paymentId: paymentId,
            paymentIntentMethod: paymentType,
            policyNumber: policyNumber,
            fullName: fullName,
            isTextActive: textActive,
            isEmailActive: emailActive,
            confirmationNumber: confirmationNumber.toString(),
            transferGroup: transferGroup,
            logo: null,
            subDomain: null,
            subDomainId: 0,
            customerId: customerId,
            isAutoPay: isAutoPay,
            invoiceNumber: invoiceNumber,
            isPFPaid: isPFPaid,
            isDBPaid: isDBPaid,
            isPaidInFull: isPaidInFull,
            isPayFull: isPayFull,
            isDownPayment: isDownPayment,
            isMonthlyPayment: isMonthlyPayment,
            cardInfo: cardInformation,
            Last4: cardInfo?.cardNumber?.slice(-4),
            paymentType: paymentType == "CARD" ? "card" : "us_bank_account",
            bankAccountNumber: bankAccountNumber,
            bankRoutingNumber: bankRoutingNumber
        }
        if (UserDetails !== null) {
            stripePaymentMdl.subDomain = SubDomain.SubDomain;
            stripePaymentMdl.logo = SubDomain.LogoPath;
            stripePaymentMdl.subDomainId = parseInt(SubDomain.SubDomainId);
        }
        else {
            stripePaymentMdl.subDomain = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl;
            stripePaymentMdl.logo = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo;
            stripePaymentMdl.subDomainId = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId;
        }
       await AddAgaveCustomer(stripePaymentMdl);
        setIsPaymentProcessing(true);
    }

    return (
        <div className="payment-form">
            {isPaymentProcessing ? (
                <div className="full-screen-message">
                    <h1 className="pt-2 pb-2 text-center">Your payment is processing!</h1>
                    <p className="text-center">Thank you for your payment! Your transaction is currently being processed. You will receive a confirmation email shortly with the details of your payment.</p>
                    <p className="text-center font-weight-bold">"Your confirmation number is {confirmationNumber}</p>
                </div>
            ) : (
                <>
                    <div className="payment-types button-container">
                        <button
                            className={paymentType === 'CARD' ? 'active' : ''}
                            onClick={() => setPaymentType('CARD')}
                        >
                            Card
                        </button>
                        <button
                            className={paymentType === 'ACH' ? 'active' : ''}
                            onClick={() => setPaymentType('ACH')}
                        >
                            ACH
                        </button>
                    </div>
                    {paymentType === 'CARD' && (
                        <div className="card-info">
                            <input
                                type="text"
                                placeholder="Card number"
                                name="cardNumber"
                                value={cardInfo.cardNumber}
                                onChange={handleCardInfoChange}
                                required
                            />
                            <div className="card-details">
                                <input
                                    type="text"
                                    placeholder="MM/YY"
                                    name="expiry"
                                    maxLength="5"
                                    value={cardInfo.expiry}
                                    onChange={handleCardInfoChange}
                                    required
                                />
                                <input
                                    type="text"
                                    placeholder="CVC"
                                    name="cvc"
                                    value={cardInfo.cvc}
                                    onChange={handleCardInfoChange}
                                    required
                                />
                            </div>
                            <input
                                type="text"
                                placeholder="Country"
                                name="country"
                                value={cardInfo.country}
                                onChange={handleCardInfoChange}
                                required
                            />
                            <input
                                type="text"
                                placeholder="Zip code"
                                name="zip"
                                value={cardInfo.zip}
                                onChange={handleCardInfoChange}
                                required
                            />
                        </div>
                        )}
                        {paymentType === 'ACH' && (
                            <div className="card-info">
                                <input
                                    type="text"
                                    placeholder="bank account number"
                                    name="bankAccountNumber"
                                    value={bankAccountNumber}
                                    onChange={(e) => setBankAccountNumber(e.target.value)}
                                    required
                                />
                                <input
                                    type="text"
                                    placeholder="bank routing number"
                                    name="bankRoutingNumber"
                                    value={bankRoutingNumber}
                                    onChange={(e) => setBankRoutingNumber(e.target.value)}
                                    required
                                />
                            </div>
                        )}
                    <button className="pay-button" onClick={handlePay}>Pay now</button>
                    <p className="disclaimer">
                        By providing your card information, you allow Automated Installment Solutions to charge your card for future payments in accordance with their terms.
                    </p>
                </>
            )}
        </div>
    );
};

export default AgavePaymentPage;
