import React, { useState, useEffect } from 'react';
import validator from 'validator';
import {CustomCurrencyFormat, DateFormat, formatPhoneNumber, stripePromise} from "../../../common/Setting";
import { GetPaymentIntent } from '../../../services/PaymentServices';
import { Buffer } from 'buffer';
import { LoaderContents } from "../../layout/LoaderContents";
import PaymentCheckout from './PaymentCheckout';
import { Elements } from "@stripe/react-stripe-js";
import { ExternalDomain } from '../../../common/Common';

const PaymentProcess = () => {
    const [queryParams, setQueryParams] = useState(new URLSearchParams(window.location.search));
    const PaymentId = (new URLSearchParams(window.location.search).get("PaymentId"))
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [saveLoaderStatus, setSaveLoaderStatus]=useState(false)
    const [state, setState] = useState({
        totalpremium: '',
        InvoiceNumber: '',
        PhoneNumber:'',
        InvoiceId:'',
        PIFServiceFee: 0,
        PIFIsTermsandConditions: false,
        Errors: {
            IsEmpty_PIFIsTermsandConditions: false,
            PIFIsTermsandConditionsEmptyMsg: "Please accept the terms and conditions."
        },
        isPaidInFull: true,
        DBDocusignLoaderStatus: false,
        mdl: {
            addressLine2: '',
            addressLine3: '',
            PhoneNumber: '',
            emailAddress: '',  // Added to handle email address correctly
        },
        fullName: '',
        address: '',
        createdByName: '',
        agencyName: '',
        PFCoverageType: '',
        DBCoverageType: '',
        UserDetails: null,
        policyEffectiveDate: '',
        IsPaid:'',
        AllPolicyTaxes: [],
        UpdateAutoPayMdl: {
            IsAutoPay: true,
        },
        showPayInFull: true,  // Controls visibility of the Pay In Full section
        showPaymentDetails: false,  // Controls visibility of the Payment Details section
        updatedEmail: '',
        emailError: '',
        emailValid: true,
        CustomCurrencyFormat,
        techFee: 0.035,
        isCancelled: false,
        cancelledDate: null,
        pmtAmount: null,
        selectedPaymentMethod: "",
        clientSecret: '',

    });
    const paymentDetail = {
        paymentId: PaymentId,
        InvoiceId: state.InvoiceId,
        policyNumber: state.isPaid ?
            (state.isDirectBilling ? state.mdl.policyNumberDB : state.mdl.policyNumberFP) :
            (state.isPFPaid ? state.mdl.policyNumberFP : state.mdl.policyNumberDB),
        emailActive: state.IsEmailActive,
        fullName: state.fullName,
        transferGroup: state.transferGroup,
        subDomainUrl: (window.location.pathname.replace(/^\/([^\/]*).*$/, '$1') === "make-payment" ? "Xgen" : window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')),
        color: state.color,
        hoverFocus: state.hoverFocus,
        subdomainId: state.LoggedOutSubDomainId ?? null,
        customerId: state.customerId,
        isAutoPay: state.UpdateAutoPayMdl.IsAutoPay,
        isIframe: false, 
        invoiceNumber: state.invoiceNumber,
        PhoneNumber: state.PhoneNumber,
        isPaidInFull: state.isPaidInFull,
        IsPaid: state.IsPaid,
        isPayFull: state.isPayFull,
        amount: state.pmtAmount,
        isAgavePayment: true,
        insuredId: state.mdl?.createdFor,
        calculatedTechFee: (state.pmtAmount * 0.035).toFixed(2),
    };

    useEffect(() => {
        // Initialize the query params
        setQueryParams(new URLSearchParams(window.location.search));
    }, []);

    const _encodeUrlValues = (amount) => {
        const urlparamenters = {
            amount: amount,
            paymentId: PaymentId,
            id: state.achMemoId,
            PhoneNumber: state.mdl.PhoneNumber || state.PhoneNumber,
            policyNumber: state.isPaid
                ? (state.isDirectBilling ? state.mdl.policyNumberDB : state.mdl.policyNumberFP)
                : (state.isPFPaid ? state.mdl.policyNumberFP : state.mdl.policyNumberDB),
            fullName: state.fullName,
            invoiceNumber: state.mdl.invoiceNumber,
        };

        const jsonString = JSON.stringify(urlparamenters);
        const encodedValue = Buffer.from(jsonString).toString('base64');

        setState((prevState) => ({
            ...prevState,
            urlEncodedValue: encodedValue
        }));
    };

    const PIFIsTermsandConditions = (event) => {
        setState({
            ...state,
            PIFIsTermsandConditions: event.target.checked
        });
    };

    const openTermsAndConditionsModal = (event) => {
        event.preventDefault();
        event.stopPropagation();
        window.$('#modalTermsandConditions').modal('show');
    };

    const onEmailConfirmCancel = () => {
        window.$('#modalEmailConfirm').modal('hide');
    };

    const onEmailConfirm = () => {
        window.$('#modalEmailConfirm').modal('hide');
        setSaveLoaderStatus(true)
        handleSubmit();  // Call the handleSubmit function directly
        setState({
            ...state,
            isAgavePayment: !state.isPFPaid,
            clientSecret: '',
        });
        _GetPaymentIntent();
       
        if (state.isPayFull) {
            const resultEmail = validateEmail();
            if (resultEmail) {
                window.$('#modalPayment').modal('show');
            }
        } else {
            window.$('#modalPayment').modal('show');
        }
    };

    const validateEmail = () => {
        const { updatedEmail } = state;
        let isValid = true;
        if (updatedEmail == null || updatedEmail === "") {
            setState({
                ...state,
                emailError: ''
            });
        } else {
            if (!validator.isEmail(updatedEmail)) {
                setState({
                    ...state,
                    emailError: 'Please enter a valid email address',
                    emailValid: false
                });
                isValid = false;
            } else {
                setState({
                    ...state,
                    emailError: '',
                    mdl: {
                        ...state.mdl,
                        emailAddress: updatedEmail
                    }
                });
            }
        }
        return isValid;
    };

    const Validation = () => {
        let errors = {};
        let count = 0;

        if (state.isPayFull && !state.updatedEmail && !validateEmail()) {
            errors['InvalidEmail'] = true;
            errors['EmailErrorMsg'] = "Please enter a valid email address.";
            count++;
        } else {
            state.emailValid = true;
        }
        setState({ ...state, Errors: errors });

        return count === 0;
    };

    const _SelectPaymentMethod = () => {
        window.$('#modalEmailConfirm').modal('hide');
        setSaveLoaderStatus(true)
        handleSubmit();  // Call the handleSubmit function directly
        setState({
            ...state,
            isAgavePayment: !state.isPFPaid,
            clientSecret: '',
        });
        _GetPaymentIntent();
       
        if (state.isPayFull) {
            const resultEmail = validateEmail();
            if (resultEmail) {
                window.$('#modalPayment').modal('show');
            }
        } else {
            window.$('#modalPayment').modal('show');
        }
    };
   const handlePaymentMethodSelect = (method) => {
        setState({ selectedPaymentMethod: method });
    };

   const handleModalClose = () => {
        setState({ selectedPaymentMethod: "ACH" });
    };

    const updateInfo = (updateFn, value) => {
        return updateFn(value, queryParams?.get("PaymentId")).then(() => {
            console.log(`Insured ${value} Updated Successfully`);
        });
    };

    // Example of how you might update phone number (change this to match your actual update function)
    const UpdateCommPhoneNumberForInsuredAPI = (PhoneNumber, paymentId) => {
        return Promise.resolve(); // Simulating an API call
    };

    const UpdateCommPhoneNumberForInsured = () => {
        // Ensure this does not cause recursion by calling the API function
        updateInfo(UpdateCommPhoneNumberForInsuredAPI, state.updatedPhoneNumber);
    };
    const UpdateEmailForInsuredAPI = (email, paymentId) => {
        return Promise.resolve(); // Simulating an API call
    };

    const UpdateEmailForInsured = () => {
        updateInfo(UpdateEmailForInsuredAPI, state.updatedEmail); // Pass the correct function and value
    };
    const handleEmailChange = (e) => {
        const email = e.target.value;
        if (email) {
            const isValid = validator.isEmail(email);
            setState((prevState) => ({
                ...prevState, // Preserve previous state
                updatedEmail: email,
                emailValid: isValid,
                mdl: {
                    ...prevState.mdl,
                    emailAddress: email, // Update mdl.emailAddress
                },
            }));
        } else {
            setState((prevState) => ({
                ...prevState, // Preserve previous state
                emailValid: true,
                updatedEmail: "",
                mdl: {
                    ...prevState.mdl,
                    emailAddress: "", // Reset mdl.emailAddress if email is empty
                },
            }));
        }
    };


    const handleSubmit = () => {
        if (state.updatedEmail == null || state.updatedEmail === "") {
            setState({
                ...state,
                emailFormCompleted: true
            });
        } else if (validateEmail()) {
            if (state.IsTextActive && validatePhoneNumber()) {
                UpdateCommPhoneNumberForInsured();
            }
            UpdateEmailForInsured();
            setState({
                ...state,
                emailFormCompleted: true,
                mdl: {
                    ...state.mdl,
                    emailAddress: state.updatedEmail
                }
            });
        }
    };

    const _ModelPayment_EnterButton = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            _SelectPaymentMethod();
        }
    };

    const onButtonEvent = async (NextStep) => {
        if (NextStep === 'PayFull') {
            let errors = {};
            let count = 0;
            if (!state.PIFIsTermsandConditions) {
                errors['IsEmpty_PIFIsTermsandConditions'] = true;
                errors['PIFIsTermsandConditionsEmptyMsg'] = "Please agree to terms and conditions";
                count++;
            }
            setState({
                ...state,
                paymentmode: "PIF",
                SelectedType: "",
                isPaidInFull: true,
                Errors: errors
            });

            if (count === 0) {
                setState({
                    ...state,
                    isPayFull: true,
                    isMonthlyPayment: false,
                    pmtAmount: state.totalpremium,
                    showPayInFull: false,
                    showPaymentDetails: true
                });
            }
        } else if (NextStep === 'PaymentTypeBack') {
            setState({
                ...state,
                showPayInFull: true,
                showPaymentDetails: false
            });
        }
    };

    const _GetPaymentIntent = async () => {
        const amount = state.pmtAmount;
        _encodeUrlValues(amount);
      
        await GetPaymentIntent(amount, state.fullName, state.updatedEmail, state.PhoneNumber, state.UpdateAutoPayMdl, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl).then((res) => {
            setState((prevState) => ({
                ...prevState,
                clientSecret: res?.clientSecret,
                transferGroup: res.transferGroup,
                customerId: res.customerId,
            }));
            setSaveLoaderStatus(false)
        });
    };
    const fetchPaymentDetails = async (paymentId) => {
        try {
            setSaveLoaderStatus(true)
            const response = await fetch(`https://paystreamapi-hna7hvg4h6aahjhk.centralus-01.azurewebsites.net/api/payment/details?paymentId=${PaymentId}`);
            if (!response.ok) { 
                throw new Error(`Failed to fetch payment details. Status: ${response.status}`);
            }
            const data = await response.json();
            setState({
                ...state,
                totalpremium: data.data.PremiumDue,
                pmtAmount: data.data.PremiumDue,
                InvoiceId: data.data.InvoiceId,
                InvoiceNumber: data.data.InvoiceNumber,
                PhoneNumber: data.data.PhoneNumber,
                mdl: {
                    addressLine1: data.data.AddressLine1,
                    addressLine2: data.data.AddressLine2,
                    PhoneNumber: data.data.PhoneNumber,
                    emailAddress: '',  // Added to handle email address correctly
                },
                fullName: data.data.InsuredName,
                IsPaid: data.data.IsPaid,
                address: data.data.AddressLine1 + ' ' + data.data.AddressLine2,
                policyEffectiveDate: new Date(data.data.InvoiceDueDate).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                }),
                createdByName: 'test',
                agencyName: 'test',
                PFCoverageType: 'cover',
                DBCoverageType: 'test',
                UserDetails: null
            });
            setSaveLoaderStatus(false)
        } catch (err) {
            setSaveLoaderStatus(false)
            console.log(err);
        }
    };

    const PaymentPaid = () => {
        return (
            <>
                <div className="container form-space p-space">
                    <div className="card">
                        <div className="Paymentcard">
                            <h1 className="pt-2 pb-2 text-center">Your Payment Has Already Been Made</h1>
                            <p className="text-center">This payment has already been paid successfully. No further action is required on this payment link.</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    const PaymentForm = () => {
        return (
            <>
                {/* Pay In Full Section */}
                {state.showPayInFull && (
                    <form className="step-form-wrapper-newDesign position-relative w-75 step-makepayment-paymentType payment-details-form-mahoney form-selected dashboard-card-newDesign">
                        <fieldset className="step-form flex-wrap px-3 row payment-form-field fullHeight pt20px centerPaymentCards hideVerticalScroll" data-field="paymentType">
                            {saveLoaderStatus && <LoaderContents />}
                            <div id="pay-in-full" className="form-floating input-wrapper-payment mahoney-width payment-wrapper-input col card payinFull make-payment-card" >
                                <h4 className="label-lower">Pay Today</h4>
                                <hr />
                                <div className="make-payment-body">
                                    <div className="make-payment-inner">
                                        <>
                                            <p className="make-payment-header">Invoice # </p>
                                            <span className="value">{state.InvoiceNumber}</span>
                                        </>
                                        <p className="make-payment-header">Amount Due</p>
                                        <span className="value">{CustomCurrencyFormat(state.totalpremium)}</span>
                                        <>
                                            <p className="make-payment-header">Services Fee Due</p>
                                            <span
                                                className="value">$10
                                            </span>
                                        </>
                                    </div>

                                    {/*{state.PIFServiceFee > 0 && (*/}
                                    {/*    <div className="make-payment-inner">*/}
                                    {/*        <p className="make-payment-header">Service Fee</p>*/}
                                    {/*        <span className="value">{CustomCurrencyFormat(state.PIFServiceFee)}</span>*/}
                                    {/*    </div>*/}
                                    {/*)}*/}
                                </div>
                                <div className="make-payment-bottom">
                                    {state.Errors['IsEmpty_PIFIsTermsandConditions'] && (
                                        <div className="has-err-msg">{state.Errors['PIFIsTermsandConditionsEmptyMsg']}</div>
                                    )}
                                    <div className='input-wrapper-radio remove-bg'>
                                        <div className="col-12 TermsandConditions-modal-check">
                                            <input
                                                type="checkbox"
                                                id="radio-Active"
                                                name="PIFIsTermsandConditions"
                                                checked={state.PIFIsTermsandConditions}
                                                required
                                                className={state.Errors['IsEmpty_PIFIsTermsandConditions'] ? 'has-err form-check-input form-control mx-1' : 'form-check-input form-control mx-1'}
                                                onChange={PIFIsTermsandConditions}
                                            />
                                            <label className="form-check-label">
                                                <h6 className="mb-0 AutoPay-label-text">
                                                    You agree to the <a href="" onClick={openTermsAndConditionsModal} style={{ textDecoration: 'underline' }}>terms and conditions</a>.
                                                </h6>
                                            </label>
                                        </div>
                                    </div>
                                    <Button type="button" className="btn-newDesign make-payment-btn" onClick={() => onButtonEvent('PayFull')}>
                                        <span className="label">Pay in full</span>
                                    </Button>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                )}

                {/* Payment Details Section */}
                {state.showPaymentDetails && (
                    <form className="step-form-wrapper-newDesign position-relative w-75 step-makepayment-pay payment-details-form-mahoney form-selected" onKeyDown={_ModelPayment_EnterButton}>
                        <fieldset className="step-form flex-wrap px-3 row payment-form-field fullHeight" data-field="verify" data-next="payment">
                            <div className="pt20px">
                                {/* Loader contents can be shown here */}
                            </div>
                            <div id="payment-details" className="form-floating payment-wrapper-input col card make-payment-card">
                                <h4 className="label-lower">Payment Details</h4>
                                <hr />
                                <div className="make-payment-body">
                                    <div className="make-payment-inner">
                                        <p className="make-payment-header">Invoice # </p>
                                        <span className="value">{state.InvoiceNumber}</span>
                                    </div>
                                    <div className="make-payment-inner">
                                        <p className="make-payment-header">Insured Name: </p>
                                        <span className="value">{state.fullName ?? ""}</span>
                                    </div>
                                    
                                    <div className="make-payment-inner">
                                        <p className="make-payment-header">Address: </p>
                                        <span className="value">
                                            {state.address ?? ""}
                                            {state.mdl.addressLine2 ? `, ${state.mdl.addressLine2}` : ''}
                                            {state.mdl.addressLine3 ? `, ${state.mdl.addressLine3}` : ''}
                                        </span>
                                    </div>
                                    <div className="make-payment-inner">
                                        <p className="make-payment-header">Phone Number: </p>
                                        <span className="value">{formatPhoneNumber(state.PhoneNumber) ?? ""}</span>
                                    </div>

                                    <div className="make-payment-inner">
                                        <p className="make-payment-header">Program: </p>
                                        <span className="value">{"Trucking Program"}</span>
                                    </div>
                                    <div className="make-payment-inner">
                                        <p className="make-payment-header">Policy Effective Date: </p>
                                        <span className="value">{state.policyEffectiveDate ?? ""}</span>
                                    </div>
                                    <div className="make-payment-inner">
                                        <p className="make-payment-header">Payment Amount: </p>
                                        <span className="value">{CustomCurrencyFormat(state.pmtAmount)}</span>
                                    </div>
                                    <div className="make-payment-inner">
                                        <p className="make-payment-header">Services Fee Due :</p>
                                        <span
                                            className="value">$10
                                        </span>
                                    </div>
                                    {state.AllPolicyTaxes.filter(items => items.amount !== 0).map((items, index) => (
                                        <div className="make-payment-inner" key={index}>
                                            <p className="make-payment-header">{items.description}: </p>
                                            <span className="value">{CustomCurrencyFormat(items.amount)}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className="payment-details-bottom">
                                    <div className="text-center d-flex justify-content-center gap-20">
                                        <button type="button" id="PayBack" className="btn-newDesign-foil make-payment-btn" onClick={() => onButtonEvent('PaymentTypeBack')}>
                                            <span className="label">Back</span>
                                        </button>
                                        <Button type="button" id="MethodofPayment" className="btn-newDesign make-payment-btn" onClick={() => _SelectPaymentMethod()}>
                                            <span className="label">Continue to payment</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>

                )}

                <div className="modal fade" tabIndex="-1" id="modalPayment" aria-labelledby="modal Payment methode selection" aria-hidden="true">
                    <div className={"modal-dialog modal-dialog-centered " + ((state.isPFPaid || state.paymentProcessId == 1 || state.isStetson) ? '' : 'fppwidth')}>
                        <div className="modal-content ">
                            <div className="modal-header justify-content-center pt-4 border-0">
                                <h5 className="modal-title-special">Complete Your Payment via Bank</h5>

                                <button type="button" id="btncloseMdl" className="btn-close position-absolute" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="">
                                    { /*loader show befoure load contents*/}
                                    {saveLoaderStatus && <LoaderContents />}
                                </div>
                                <div className="mx - auto mb-3 row Invoicedetails">
                                    <div className="pull-right"><span><b>Amount</b> {CustomCurrencyFormat(state.pmtAmount) ?? ''}</span></div>
                                    <>
                                        <div className="pull-right">
                                            <span><b>Service Fee </b>
                                                {CustomCurrencyFormat(10) ?? ''}</span>
                                        </div>
                                    </>

                                </div>
                                <div className=" m-auto modal-size" id="frmpaymentstatus">
                                    <div className="mx-auto mb-3" >
                                        <div className='row payment-element-form mb-0'>
                                            <div className="col-12 p-0 position-relative">
                                                {state.clientSecret &&
                                                    <Elements stripe={stripePromise} options={state.clientSecret}>
                                                        <PaymentCheckout props={paymentDetail}
                                                            onpaymentMethodSelect={handlePaymentMethodSelect} />
                                                    </Elements>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    useEffect(() => {
        fetchPaymentDetails();
    }, []);
    var Button = ExternalDomain(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutColor, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutHoverFocus, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId);
  
    return (
        <>
            <div className="main-wrapper-newDesign h-100 d-flex">
                <>
                    <div className="header d-flex justify-content-md-between align-items-center border-bottom bg-white">
                        <div className="d-flex align-items-center ps-3 header-left">
                            <img src="/assets/Content/images/mahoney01.png" alt="XgenPayment" className="logo-100p" />
                        </div>
                    </div>
                    {state.IsPaid && PaymentPaid()}
                    {!state.IsPaid && PaymentForm()}
                </>
            </div>
        </>
    );
};

export default PaymentProcess;
