import React, { useState, useEffect} from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

import {
    authHeader,
    subDomain,
    showError,
    ExternalDomain,
} from '../../../common/Common';
import { UpdatePaymentIntent, PaymentAlreadyMadeCheck } from '../../../services/PaymentServices';
import axios, { Axios } from 'axios';

const PaymentCheckout = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const UserDetails = authHeader();
    const SubDomain = subDomain();
    const confirmationNumber = Math.floor(Math.random() * 1000000) + 1;

    // Props Destructuring
    const {
        paymentId,
        InvoiceId,
        policyNumber,
        fullName,
        invoiceNumber,
        amount,
        transferGroup,
        insuredId,
        isAgavePayment,
        customerId,
        calculatedTechFee,
    } = props.props;

    console.log("props checkout : ", props)
    const [isLoading, setIsLoading] = useState(false);
    const [bankAccountNumber, setBankAccountNumber] = useState('');
    const [bankRoutingNumber, setBankRoutingNumber] = useState('');
    const [recipientName, setRecipientName] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [errors, setErrors] = useState({});

    const handleChange = (event) => {
        const parsedData = JSON.parse(event.data);
        if (parsedData.message.payload.data.value.type === 'us_bank_account') {
            setPaymentType('us_bank_account');
            props.onPaymentMethodSelect('us_bank_account');
        }
        else {
            setPaymentType('CARD');
            props.onPaymentMethodSelect('CARD');
        }
    }
   
    const BankDetails = async () => {
        try {
            const payload = {
                RoutingNumber: bankRoutingNumber,
                RecipientName: recipientName,
                AccountNumber: bankAccountNumber,
                InvoiceId: InvoiceId,
            };
            const response = await axios.post('https://paystreamapi-hna7hvg4h6aahjhk.centralus-01.azurewebsites.net/api/bank/add-details', payload);
            console.log('Response:', response?.data); 
        } catch (error) {
            console.error('Error:', error); 
        }
    };
    //const validateInputs = () => {
    //    const newErrors = {
    //        recipientName: !recipientName.trim(),
    //        bankAccountNumber: !bankAccountNumber.trim() || bankAccountNumber.length < 6 || bankAccountNumber.length > 17,
    //        bankRoutingNumber: !bankRoutingNumber.trim() || bankRoutingNumber.length !== 9,
    //    };
    //    setErrors(newErrors);
    //    return !Object.values(newErrors).includes(true); // If any value is `true`, validation fails
    //};
    const validateInputs = () => {
        const newErrors = {};

        // Validate recipientName
        if (recipientName.trim() === "") {
            newErrors.recipientName = true;
            newErrors.recipientNameMessage = "Recipient name is required.";
        }

        // Validate bankAccountNumber
        if (bankAccountNumber.trim() === "" || bankAccountNumber.length < 6 || bankAccountNumber.length > 17) {
            newErrors.bankAccountNumber = true;
            newErrors.bankAccountNumberMessage = "Bank account number should be between 6 and 17 digits.";
        }

        // Validate bankRoutingNumber
        if (bankRoutingNumber.trim() === "" || bankRoutingNumber.length !== 9) {
            newErrors.bankRoutingNumber = true;
            newErrors.bankRoutingNumberMessage = "Routing number should be exactly 9 digits.";
        }

        setErrors(newErrors);

        // Return true if no errors exist, otherwise false
        return Object.keys(newErrors).length === 0; // If no error keys, validation passed
    };


    const handlePay = async () => {
        if (validateInputs()) {
            setIsLoading(true);
            await BankDetails();

            let params1 = {
                pN: "PN-3245",
                fN: fullName,
                paymentId: paymentId,
                cN: confirmationNumber,
                cId: props.props.customerId,
                iAp: props.props.isAutoPay,
                isAgavePayment: false,
                uId: insuredId,
                last4: bankAccountNumber.slice(-4),
                bankAccountNumber: bankAccountNumber,
                recipientName: recipientName,
                amount: amount,
                paymentType: "us_bank_account",
                calculatedTechFee: 0,
                isPaidInFull: true,
                isPayFull: true,
            };

            let subDomainUrl = "mahoney-group";
            let queryString = Object.keys(params1)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params1[key])}`)
                .join("&");

            console.log("queryString", queryString);

            if (subDomainUrl === "xgen") {
                window.location.href = `${window.location.origin}/payment-progress?${queryString}`;
            } else {
                window.location.href = `${window.location.origin}/${subDomainUrl?.trim()?.toLowerCase()}/payment-progress?${queryString}`;
            }
        } else {
            console.log("Validation failed, not proceeding with API call.");
        }
    };


    var Button = ExternalDomain(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutColor, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutHoverFocus, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId);
    return (
        <>
            <div className="container">
                <div className="card-info">
                    <div className="row">
                        <p className="modal-header-newDesign col-8 col-md-6">
                            Recipient Name <span style={{ color: 'red' }}>*</span>
                        </p>
                    </div>

                    <div className="form-floating mb-3">
                        <input
                            type="text"
                            placeholder={errors.recipientName ? errors.recipientNameMessage : "Recipient Name"}
                            className={`form-control input-newDesign ${errors.recipientName ? "is-invalid mb-0" : ""}`}
                            name="recipientName"
                            value={recipientName}
                            onChange={(e) => setRecipientName(e.target.value.replace(/[^a-zA-Z\s]/g, ""))}
                        />
                        <label htmlFor="recipientName">Recipient Name</label>
                        {errors.recipientName && (
                            <p className="text-danger">{errors.recipientNameMessage}</p>
                        )}
                    </div>


                    <div className="row">
                        <p className="modal-header-newDesign">Bank Account Number <span style={{ color: 'red' }}>*</span></p>
                    </div>
                    <div className="form-floating mb-3">
                        <input
                            type="text"
                            className={`form-control input-newDesign ${errors.bankAccountNumber ? "is-invalid  mb-0" : ""}`}
                            placeholder={errors.bankAccountNumber ? errors.bankAccountNumberMessage : "Bank Account Number"}
                            name="bankAccountNumber"
                            value={bankAccountNumber}
                            onChange={(e) => setBankAccountNumber(e.target.value.replace(/\D/g, ""))}
                            maxLength={17} // Restrict input length to 17 characters
                        />
                        <label htmlFor="bankAccountNumber">Bank Account Number</label>
                        {errors.bankAccountNumber && (
                            <p className="text-danger">{errors.bankAccountNumberMessage}</p> 
                        )}
                    </div>

                    <div className="row">
                        <p className="modal-header-newDesign col-8 col-md-6">Bank Routing Number <span style={{ color: 'red' }}>*</span></p>
                    </div>
                    <div className="form-floating mb-3">
                        <input
                            type="text"
                            className={`form-control input-newDesign ${errors.bankRoutingNumber ? "is-invalid  mb-0" : ""}`}
                            placeholder={errors.bankRoutingNumber ? errors.bankRoutingNumberMessage : "123456789"}
                            name="bankRoutingNumber"
                            value={bankRoutingNumber}
                            onChange={(e) => setBankRoutingNumber(e.target.value.replace(/\D/g, ""))}
                            maxLength={9} 
                        />
                        <label htmlFor="bankRoutingNumber">Bank Routing Number</label>
                        {errors.bankRoutingNumber && (
                            <p className="text-danger">{errors.bankRoutingNumberMessage}</p> 
                        )}
                    </div>
                </div>
            </div>

            <div className="button-container">
                <Button
                    className="pay-button make-payment-btn btn btn-newDesign-foil"
                    onClick={handlePay}
                    disabled={isLoading}
                >
                    <span id="button-text">
                        {isLoading ? (
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        ) : (
                            "Pay now"
                        )}
                    </span>
                </Button>
            </div>
        </>
)};

export default PaymentCheckout;
