import $ from 'jquery';
import validator from 'validator';
import ACHForm from '../Common/ACHForm';
import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import GetPlace from "../../../services/GetPlace";
import { Elements } from "@stripe/react-stripe-js";
import { subDomain, StyledActiveLink } from '../../../common/Common';
import { SiteFooter } from "../../layout/SiteFooter";
import CreditCardForm from '../Common/CreditCardForm';
import { PaymentTypeList } from '../../../common/Enum';
import { LoaderContents } from "../../layout/LoaderContents";
import { showError, showSuccess, Button } from '../../../common/Common';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { ChangePassword, ChangeAddress, GetUserProfile, GetAgentInformation } from '../../../services/UserServices';
import { stripePromise, CustomCurrencyFormat, DateFormat, DateFormatShort, formatPhoneNumber } from "../../../common/Setting";
import { GetAllInsuredPoliciesByUserId, GetAllInsuredPoliciesByPaymentId, GetPaymentProfile, _GetAllMethodofPayments, UpsertPaymentProfile, GetStripeCustomer, GetAllMethodofPayments, GetAllRetrivePendingPayments, UpdatePaymentMethod, GetAllRetriveAccountInfo, GetAllRetrivePaymentAccount, GetCustomerDetailById } from '../../../services/PaymentServices';
import ReactPaginate from 'react-paginate';
import { DeleteCustomerPaymentMethod, DeleteAgentPaymentMethod } from '../../../services/PaymentAPIServices';
import { useSwipeable } from 'react-swipeable';
import Select from "react-select";

class AccountOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PaymentId: null,
            ChangePassLoaderStatus: false,
            ChangeAddressLoaderStatus: false,
            AllMethodOfPayments:[],
            Errors: {},
            PersonalInformation: {},
            AccountDetails: {},
            nextDueDate: "",
            currentPolicyIndex: 0,
            Pagecount: 0,
            PaymentProfileMdl:
            {
                "paymentProfileId": 0,
                "paymentTypeId": 1,
                "achAccountHolderName": null,
                "achRoutingNumber": null,
                "achAccountNumber": null,
                "creditCardHolderName": null,
                "creditCardNumber": null,
                "formattedCardNumber": null,
                "creditCardExpiryMonth": null,
                "creditCardExpiryYear": null,
                "creditCardCVV": null,
                "is_AutoPay": null
            },
            currentPolicy: {},
            AllInsuredPolicies: [],
            AllInsuredPolicyByPaymentId: [],
            searchMdl: {
                GeneralSearch: null,
                LimitTo: 25,
                PageNumber: 1
            },
            UserProfileMdl:
            {
                address: null
            },
            CustomerDetailMdl:
            {
                cardDetails: null,
                usBankDetails: null,
                customerDetails: null
            },
            Error: null,
            PageLoaderStatus: false,
            isOutAutoPay: true,
            paymentHistory: [],
            RetriveApiLoader: true,
            AutoPayLoaderStatus: true,
            InsuredId: '',
            InsuredName: '',
            UpdatePaymentMethodLoader: true,
            isAutoPay: false,
            subDomainId:"",
            AISAccounts: [],
            AgentInformation: [],
            SelectedAccountAgent: {}
        };
        this.handleChangePaymentMethod = this.handleChangePaymentMethod.bind(this);
        this.handleChangePaymentInfo = this.handleChangePaymentInfo.bind(this);
    }

    componentDidMount() {
        this.setState({ PageLoaderStatus: true });
        const user = JSON.parse(localStorage.getItem("UserCredential"));
        if (user != null) {
            this._GetUserProfile();
        }

        this.handleAnimationEnd = (e) => {
          if (e.animationName === 'fade-in') {
            e.target.classList.remove('fade-in-animation');
          }
        };
        
        document.addEventListener('animationend', this.handleAnimationEnd);
    };

    // get user profile
    _GetUserProfile = () => {
        this.setState({ LoaderStatus: true });
        GetUserProfile().then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    if (response.data != null) {
                        GetStripeCustomer(response.data.userInfoMdl.userId).then(data => {
                            this.setState({ CustomerDetailMdl: data });

                            if (this.state.CustomerDetailMdl.cardDetails !== null && this.state.CustomerDetailMdl.cardDetails !== "") {
                                this.setState(Object.assign(this.state.PaymentProfileMdl, { paymentTypeId: 1 }));
                                $("#radio-CreditCard").prop("checked", true);
                                $("#radio-ACH").prop("checked", false);
                                $("#radio-CreditCard").prop("disabled", true);
                                $("#radio-ACH").prop("disabled", true);
                            }
                            else if (this.state.CustomerDetailMdl.usBankDetails !== null && this.state.CustomerDetailMdl.usBankDetails !== "") {
                                this.setState(Object.assign(this.state.PaymentProfileMdl, { paymentTypeId: 2 }));
                                $("#radio-CreditCard").prop("checked", false);
                                $("#radio-ACH").prop("checked", true);
                                $("#radio-CreditCard").prop("disabled", true);
                                $("#radio-ACH").prop("disabled", true);
                            }
                            else if (this.state.CustomerDetailMdl.usBankDetails === "" && this.state.CustomerDetailMdl.cardDetails === "") {
                                if (this.state.PaymentProfileMdl.paymentTypeId === PaymentTypeList.ACH) {
                                    $("#radio-ACH").prop("checked", true);
                                    $("#radio-CreditCard").prop("checked", false);
                                }
                                if (this.state.PaymentProfileMdl.paymentTypeId === PaymentTypeList.CreditCard) {
                                    $("#radio-CreditCard").prop("checked", true);
                                    $("#radio-ACH").prop("checked", false);
                                }

                                $("#radio-CreditCard").prop("disabled", false);
                                $("#radio-ACH").prop("disabled", false);
                            }
                            else {
                                $("#radio-CreditCard").prop("checked", false);
                                $("#radio-ACH").prop("checked", false);
                            }
                        });
                        this.setState({ PersonalInformation: response.data.userInfoMdl });
                        this.setState({ PersonalInformation: { ...this.state.PersonalInformation, email: response.data.email } });
                        this.setState({ UserProfileMdl: response.data.userInfoMdl });
                        this.GetAllInsuredPoliciesByUserId(this.state.searchMdl, response.data.id)
                    }
                }
                else {
                    showError(response.message);
                }
            }
        });
    }

    _DeletePaymentMethod = (CusId, token) => {
        this.setState({ DeleteLoader: true }); // show loader
        if (subDomain().SubDomain?.toLowerCase() != 'geico') {
            DeleteCustomerPaymentMethod(CusId, token).then(data => {
                this.setState(prevState => ({ DeleteLoader: false, CustomerDetailMdl: { ...prevState.CustomerDetailMdl, cardDetails: null, usBankDetails: null } }));
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        this.setState({ isOutAutoPay: true });
                        this._GetUserProfile();
                        window.$('#confirmdeletemdl').modal('hide');
                        showSuccess(response.message);
                    }
                    else {
                        showError(response.message);
                    }
                    this.setState({ DeleteLoader: false }); // hide loader
                }
            });
        } else {
            this.setState(prevState => ({ DeleteLoader: false, CustomerDetailMdl: { ...prevState.CustomerDetailMdl, cardDetails: null, usBankDetails: null } }));
            //DeleteAgentPaymentMethod();
            window.$('#confirmdeletemdl').modal('hide');
        }
    }

    GetAllInsuredPoliciesByUserId = (searchMdl, userId) => {
        GetAllInsuredPoliciesByUserId(searchMdl, userId).then(res => {
            let response = res;
            this.setState({ LoaderStatus: true })
            this.setState({ subDomainId: response.data.data[0]?.subDomainId });
            if (response.status === true) {
                this._GetAllRetrivePaymentAccount(response.data.data[0]);
                this.setState({ AllInsuredPolicies: response.data.data, Pagecount: response.data.data.length });
                this.GetAllInsuredPoliciesByPaymentId(response.data.data[0]?.paymentId);
                this.setState({ InsuredId: response.data.data[0]?.userId, InsuredName: response.data.data[0]?.insuredName, currentPolicy: response.data.data[0] })
                this._GetAllMethodofPayments(response.data.data[0]?.paymentId, response.data.data[0]?.emailAddress);
                //this.GetAllRetrivePendingPayments(response.data.data[0]);
                this._GetAgentInformation(userId, response.data.data[0]?.paymentId);
            } else {
                showError(response.message);
            }
        });
    }

    GetAllInsuredPoliciesByPaymentId = (paymentId) => {
        this.setState({ ContentsLoaderStatus: true }); // show loader befour load data
        GetAllInsuredPoliciesByPaymentId(paymentId).then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ AccountDetails: response.data[0] });
                } else {
                    showError(response.message);
                }
                this.setState({ ContentsLoaderStatus: false }); // hide loader after load data
            }
        });
    }

    //GetAllRetrivePendingPayments = async (paymentDetail) => {
    //    this.setState({ ContentsLoaderStatus: true });

    //    var requestModel = {
    //        BillingAccountNumber: paymentDetail.billingAccountNumber,
    //        ConsumerReferenceNumber: ""
    //    };
    //    GetAllRetrivePendingPayments(requestModel).then(res => {
    //        let response = res.data;
    //        console.log("response", response);
    //        this.setState({ payemntHistory: response.data?.uiPendingPayment })
    //        this.setState({ ContentsLoaderStatus: false });
    //    });

    //    GetAllRetriveAccountInfo(requestModel).then(resp => {
    //        let response1 = resp;
    //        console.log("response1", response1);
    //        this.setState({ ContentsLoaderStatus: false });
    //    });

    //    console.log("requestModel", requestModel);

    //    GetAllRetrivePaymentAccount(requestModel).then(resp => {
    //        let response2 = resp;
    //        console.log("response2", response2);
    //        this.setState({ ContentsLoaderStatus: false });
    //    });
    //}

    _GetAllMethodofPayments = (paymentId, emailAddress) => {
        this.setState({ ContentsLoaderStatus: true }); // show loader befour load data
        GetAllMethodofPayments(paymentId, emailAddress).then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ AllMethodOfPayments: response.data });
                    const totalAmountPaid = (response.data).reduce((total, payment) => {
                        return total + parseFloat(payment.amountPaid);
                    }, 0);

                    this.setState({ TotalAmountPaid: totalAmountPaid });
                    var myDate = new Date(response.data[0]?.datePaid);
                    var result = new Date(myDate.setMonth(myDate.getMonth() + 1));
                    var month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
                    var t = (month[result.getMonth()] +
                        " " + result.getDate() +
                        ", " + result.getFullYear());
                    this.setState({ nextDueDate: t });
                } else {
                    showError(response.message);
                }
                this.setState({ ContentsLoaderStatus: false, PageLoaderStatus: false }); // hide loader after load data
            }
        });
    }

    // this is for update payment method
    _UpdatePaymentMethod = async () => {
        this.setState({ UpdatePaymentMethodLoader: true });
        const paymentProfileValue = this.state.PaymentProfileMdl;
        const paymentProfile = {
            CardExpMonth: paymentProfileValue.creditCardExpiryMonth,
            CardExpYear: paymentProfileValue.creditCardExpiryYear,
            CardNickName: paymentProfileValue.creditCardHolderName,
            CardNumber: paymentProfileValue.creditCardNumber,
            CvvNumber: paymentProfileValue.creditCardCVV,
            BankAccountNumber: paymentProfileValue.achAccountNumber,
            BankRoutingNumber: paymentProfileValue.achRoutingNumber,
            BankAccountNickName: paymentProfileValue.achAccountHolderName,
            PaymentAccountType: paymentProfileValue.achAccountNumber ? "ACH" : "CARD",
            InsuredId: this.state.InsuredId,
            InsuredName: this.state.InsuredName,
            IsAutoPay: this.state.isAutoPay,
            EmailAddress: this.state.currentPolicy.emailAddress,
            aisAccountNumber: this.state.currentPolicy?.billingAccountNumber,
            cardScheme: this.detectBrand(paymentProfileValue.creditCardNumber),
            subDomainId: this.state.subDomainId,
            PaymentId: this.state.currentPolicy.paymentId,
        };
        await UpdatePaymentMethod(paymentProfile).then(data => {
            let response = data.data;
            document.getElementById("btnclosePaymentMethodMdl")?.click();
            if (response.status == "true") {
                showSuccess(response.message);
                this._GetAllRetrivePaymentAccount(this.state.currentPolicy);
            } else {
                showError(response.message);
            }
            this.setState({ UpdatePaymentMethodLoader: false });
        });
        this.setState({
            PaymentProfileMdl: {
                creditCardExpiry: '',
                creditCardExpiryMonth: '',
                creditCardExpiryYear: '',
                creditCardHolderName: '',
                creditCardNumber: '',
                formattedCardNumber: '',
                creditCardCVV: '',
                achAccountNumber: '',
                achRoutingNumber: '',
                achAccountHolderName: '',
                paymentTypeId: 1 
            }
        });
        const modalElement = document.getElementById('modalChangePaymentMethod');
        const modal = bootstrap.Modal.getInstance(modalElement);
        modal?.hide();
    }

    //handle payment deatils chnage
    handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'creditCardExpiry') {
            let formattedValue = value.replace(/\D/g, ''); // Remove all non-numeric characters
            let month = '';
            let year = '';
            if (formattedValue.length >= 3) {
                month = parseInt(formattedValue.slice(0, 2), 10);
                year = formattedValue.slice(2, 4);

                if (month > 12) {
                    month = (month.toString().charAt(0)).padStart(2, '0') % 12 || 12; // Map values like 13 -> 01, 35 -> 11
                }

                formattedValue = `${String(month).padStart(2, '0')}/${year}`;

            }
            this.setState(prevState => ({
                PaymentProfileMdl: {
                    ...prevState.PaymentProfileMdl,
                    creditCardExpiryMonth: String(month).padStart(2, '0'),
                    creditCardExpiryYear: `20${year}`,
                    creditCardExpiry: formattedValue
                }
            }));

        }
        else if (name === 'creditCardCVV') {
            const cvcValue = value.replace(/\D/g, '').slice(0, 3); // Limit CVC to 3 characters
            this.setState(prevState => ({
                PaymentProfileMdl: {
                    ...prevState.PaymentProfileMdl,
                    creditCardCVV: cvcValue
                }
            }));
        }
        else if (name == 'creditCardNumber') {
            const cardNumber = value.replace(/\D/g, '').slice(0, 16); 
            let formattedValue = value.replace(/\D/g, "");

            formattedValue = formattedValue.replace(/(.{4})/g, "$1 ").trim();
            if (formattedValue.length > 18) {
                formattedValue = formattedValue.substring(0, 19);
            }
            const cardNumberWithoutSpaces = formattedValue.replace(/\s+/g, "");
            this.setState(prevState => ({
                PaymentProfileMdl: {
                    ...prevState.PaymentProfileMdl,
                    creditCardNumber: cardNumber,
                    formattedCardNumber: formattedValue
                }
            }));
        }
        else {  
            this.setState(prevState => ({
                PaymentProfileMdl: {
                    ...prevState.PaymentProfileMdl,
                    [name]: value
                }
            }));
        }
    };

    emptyInputFeild = () => {
        this.setState({
            PaymentProfileMdl: {
                creditCardHolderName: '',
                creditCardExpiry: '',
                creditCardNumber: '',
                formattedCardNumber: '',
                creditCardCVV: '',
                achAccountHolderName: '',
                achBankName: '',
                achRoutingNumber: '',
                achAccountNumber: ''
            },
        });
    };
    _ChangePassword_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this._ChangePassword();
        };
    };
    _ChangeAddress_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this._ChangeAddress();
        };
    };
    //form submit on enter key down (change payment method)
    _UpsertPaymentProfile_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this._UpsertPaymentProfile();
        };
    };
    _ChangePassword = () => {
        let ChangePasswordMdl = {
            currentPassword: this.refs.currentPassword.value,
            newPassword: this.refs.newPassword.value,
            confirmPassword: this.refs.confirmPassword.value,
        }
        let status = this.ChangePasswordValidation(ChangePasswordMdl);
        if (status) {
            this.setState({ ChangePassLoaderStatus: true }); // show loader befour  change pass response form server
            ChangePassword(ChangePasswordMdl).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        document.getElementById('btncloseMdl').click();
                        showSuccess(response.message);
                        //document.getElementById("frmchangepassword").reset();
                        $("#frmchangepassword").trigger("reset");
                    }
                    else {
                        showError(response.message);
                    }
                    this.setState({ ChangePassLoaderStatus: false }); // hide loader
                }
            });
        }
    }
    // validtion for change password validation
    ChangePasswordValidation = (mdl) => {
        let errors = {};
        let count = 0;
        if (mdl.currentPassword === null || mdl.currentPassword.trim() === "" || mdl.currentPassword === undefined) {
            errors['IsEmpty_currentPassword'] = true;
            errors['currentPasswordMsg'] = "Required fields : Current password";
            count++;
        }
        if (mdl.newPassword === null || mdl.newPassword.trim() === "" || mdl.newPassword === undefined) {
            errors['IsEmpty_newPassword'] = true;
            errors['newPasswordMsg'] = "Required fields : New password";
            count++;
        }
        else {
            if (!validator.isLength(mdl.newPassword, 8)) {
                errors['IsEmpty_newPassword'] = true;
                errors['newPasswordMsg'] = "Password must at least 8 characters long";
                count++;
            }
        }
        if (mdl.confirmPassword === null || mdl.confirmPassword === undefined || mdl.confirmPassword.trim() === "") {
            errors['IsEmpty_confirmPassword'] = true;
            errors['confirmPasswordMsg'] = "Required fields : Confirm password";
            count++;
        }
        else {
            if (!validator.equals(mdl.newPassword, mdl.confirmPassword)) {
                errors['IsEmpty_confirmPassword'] = true;
                errors['confirmPasswordMsg'] = "Passwords doesn't match : Confirm password";
                count++;
            }
        }
        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }
    //ais retrive apis
    _GetAllRetrivePaymentAccount = async (paymentDetail) => {
        this.setState({ UpdatePaymentMethodLoader: true })
        if (paymentDetail?.billingAccountNumber) {
            this.setState({ RetriveApiLoader: true });
            this.setState({ AutoPayLoaderStatus: true });
            const requestModel = {
                BillingAccountNumber: paymentDetail?.billingAccountNumber,
                ConsumerReferenceNumber: "",
                subDomainId: paymentDetail?.subDomainId
            };

            try {
                GetAllRetrivePendingPayments(requestModel).then(res => {
                    let response = res.data;
                    this.setState({ paymentHistory: response.data?.uiPendingPayment })
                });
                this.setState({ RetriveApiLoader: false });
                const res = await GetAllRetrivePaymentAccount(requestModel);
                const response = res.data?.data;

                const CcdPaymentAccount = response?.uiCcdPaymentAccounts;
                const AchPaymentAccount = response?.uiAchPaymentAccounts;

                const resp = await GetAllRetriveAccountInfo(requestModel);
                const response1 = resp.data?.data?.uiBillingAccountInfo[0];
                const isAutoPay = response1?.autoPaySetup;
                this.setState( {isAutoPay: isAutoPay.toLowerCase() === 'yes' ? true : false } );
                const autoPayAccountType = response1?.autoPayAccountType;
                if (isAutoPay === "YES") {
                    if (autoPayAccountType === "CCD") {
                        const ccdData = await GetCustomerDetailById(paymentDetail.userId, 1);
                        const tokenSetValues = new Set(ccdData.data?.token.map(item => item.token));
                        const account = CcdPaymentAccount.find(
                            (account) => tokenSetValues.has(account.cardToken) && (account.usedForAutopay === "YES" || account.usedOnlyForAutopay == "YES")
                        );
                        if (account) {
                            this.setState(prevState => ({ CustomerDetailMdl: { ...prevState.CustomerDetailMdl, customerDetails: { ...prevState.CustomerDetailMdl.customerDetails, token: account.cardToken, id: paymentDetail.userId, name: response1.insuredName1 }, cardDetails: { ...prevState.CustomerDetailMdl.cardDetails, CardBrand: account.cardScheme, last4: account.cardLastFourDigit, token: account.cardToken } } }));
                            this.setState(prevState => ({ PaymentProfileMdl: { ...prevState.PaymentProfileMdl } }));
                        }
                    } else {
                        const achData = await GetCustomerDetailById(paymentDetail.userId, 2);
                        const tokenSetValues = new Set(achData.data?.token.map(item => item.token));
                        const account = AchPaymentAccount.find(
                            (account) => tokenSetValues.has(account.bankAccountToken) && (account.usedForAutopay === "YES" || account.usedOnlyForAutopay == "YES")
                        );
                        if (account) {
                            this.setState(prevState => ({ CustomerDetailMdl: { ...prevState.CustomerDetailMdl, customerDetails: { ...prevState.CustomerDetailMdl.customerDetails, token: account.bankAccountToken, id: paymentDetail.userId, name: response1.insuredName1 }, usBankDetails: { ...prevState.CustomerDetailMdl.usBankDetails, routingNumber: account.bankAbaNumber, last4: account.bankAccountLastFourDigit, token: account.bankAccountToken } } }));
                            this.setState(prevState => ({ PaymentProfileMdl: { ...prevState.PaymentProfileMdl } }));
                        }
                    }
                }
                this.setState({ AutoPayLoaderStatus: false })
            } catch (error) {
                console.error("Error occurred while retrieving payment account:", error);
            } finally {
                this.setState({ RetriveApiLoader: false, UpdatePaymentMethodLoader: false });
            }
        } else {
            this.setState({ AutoPayLoaderStatus: false, UpdatePaymentMethodLoader: false })
        }
        this.setState({ RetriveApiLoader: false, UpdatePaymentMethodLoader: false });
    };
    //detectCardBrand
    detectBrand = (number) => {
        // Check if the number is null or empty
        if (!number) {
            return '';
        }

        // Visa
        if (/^4/.test(number)) {
            return 'Visa';
        }
        // MasterCard
        if (/^5[1-5]/.test(number) || /^(222[1-9]|22[3-9]\d|2[3-6]\d{2}|27[01]\d|2720)/.test(number)) {
            return 'MasterCard';
        }
        // American Express
        if (/^3[47]/.test(number)) {
            return 'American Express';
        }
        // Discover
        if (/^6(?:011|5[0-9]{2}|4[4-9][0-9]|22[1-9][0-9]{2})/.test(number)) {
            return 'Discover';
        }
        // JCB
        if (/^(?:2131|1800|35\d{3})/.test(number)) {
            return 'JCB';
        }
        // Diners Club
        if (/^3(?:0[0-5]|[68]\d)/.test(number)) {
            return 'Diners Club';
        }

        // Default case for unknown card brand
        return 'Visa';
    };
    // this is for change addredd
    _ChangeAddress = () => {
        let changeAddressmdl = {
            Address: this.refs.address.value
        }

        let status = this.ChangeAddressValidation(changeAddressmdl);
        if (status) {
            this.setState({ ChangeAddressLoaderStatus: true }); // show loader befour  change address response form server
            this.setState(this.setState(Object.assign(this.state.UserProfileMdl, { address: this.refs.address.value })));
            ChangeAddress(this.state.UserProfileMdl).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        document.getElementById('btncloseAddressMdl').click();
                        showSuccess(response.message);
                    }
                    else {
                        showError(response.message);
                    }
                    this.setState({ ChangeAddressLoaderStatus: false }); // hide loader
                }
            });
        }
    }
    //validtion for address validtion 
    ChangeAddressValidation = (mdl) => {
        let errors = {};
        let count = 0;
        if (mdl.Address === null || mdl.Address === undefined || mdl.Address.trim() === "") {
            errors['IsEmpty_Address'] = true;
            errors['AddressMsg'] = "Required fields : Address";
            count++;
        }
        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }
    //get payment profile
    _GetPaymentProfile = () => {
        this.setState({ LoaderStatus: true });
        this._GetUserProfile();
        GetPaymentProfile().then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    if (response.data !== null) {
                        this.setState({ PaymentProfileMdl: response.data });
                        if (response.data.paymentTypeId === PaymentTypeList.ACH) {
                            $("#radio-ACH").prop("checked", true);
                            $("#radio-CreditCard").prop("checked", false);
                        }
                        if (response.data.paymentTypeId === PaymentTypeList.CreditCard) {
                            $("#radio-CreditCard").prop("checked", true);
                            $("#radio-ACH").prop("checked", false);
                        }
                    }
                }
                else {
                    showError(response.message);
                }
                this.setState({ LoaderStatus: false }); // hide loader
            }
        });
    }
    PaymentInfoValidation = (mdl) => {
        let errors = {};
        let count = 0;
        if (mdl.paymentTypeId === PaymentTypeList.ACH) {
            if (mdl.achAccountHolderName === null || mdl.achAccountHolderName.trim() === "" || mdl.achAccountHolderName === undefined) {
                errors['IsEmpty_achAccountHolderName'] = true;
                errors['achAccountHolderNameMsg'] = "Required field: Account Holder Name";
                count++;
            }
            if (mdl.achRoutingNumber === null || mdl.achRoutingNumber.trim() === "" || mdl.achRoutingNumber === undefined) {
                errors['IsEmpty_achRoutingNumber'] = true;
                errors['achRoutingNumberMsg'] = "Required field: Routing Number";
                count++;
            }
            else {
                if (!validator.isNumeric(mdl.achRoutingNumber)) {
                    errors['IsEmpty_achRoutingNumber'] = true;
                    errors['achRoutingNumberMsg'] = "Not a valid number";
                    count++;
                }
                else if (!validator.isLength(mdl.achRoutingNumber, 9, 9)) {
                    errors['IsEmpty_achRoutingNumber'] = true;
                    errors['achRoutingNumberMsg'] = "Should contain at least 9 digit";
                    count++;
                }
            }
            if (mdl.achAccountNumber === null || mdl.achAccountNumber.trim() === "" || mdl.achAccountNumber === undefined) {
                errors['IsEmpty_achAccountNumber'] = true;
                errors['achAccountNumberMsg'] = "Required field: Account Number";
                count++;
            }
            else {
                if (!validator.isNumeric(mdl.achAccountNumber)) {
                    errors['IsEmpty_achAccountNumber'] = true;
                    errors['achAccountNumberMsg'] = "Not a valid number";
                    count++;
                }
            }
        }
        if (mdl.paymentTypeId === PaymentTypeList.CreditCard) {
            if (mdl.creditCardHolderName === null || mdl.creditCardHolderName.trim() === "" || mdl.creditCardHolderName === undefined) {
                errors['IsEmpty_creditCardHolderName'] = true;
                errors['creditCardHolderNameMsg'] = "Required field: Card Holder Name";
                count++;
            }
            if (mdl.creditCardNumber === null || mdl.creditCardNumber.trim() === "" || mdl.creditCardNumber === undefined) {
                errors['IsEmpty_creditCardNumber'] = true;
                errors['creditCardNumberMsg'] = "Required field: Card Number";
                count++;
            }
            else {
                if (!validator.isNumeric(mdl.creditCardNumber)) {
                    errors['IsEmpty_creditCardNumber'] = true;
                    errors['creditCardNumberMsg'] = "Not a valid number";
                    count++;
                }
            }
            if (mdl.creditCardExpiryMonth === null || mdl.creditCardExpiryMonth.trim() === "" || mdl.creditCardExpiryMonth === undefined) {
                errors['IsEmpty_creditCardExpiryMonth'] = true;
                errors['creditCardExpiryMonthMsg'] = "Required field: Month";
                count++;
            }
            if (mdl.creditCardExpiryYear === null || mdl.creditCardExpiryYear.trim() === "" || mdl.creditCardExpiryYear === undefined) {
                errors['IsEmpty_creditCardExpiryYear'] = true;
                errors['creditCardExpiryYearMsg'] = "Required field: Year";
                count++;
            }
            else {
                if (!validator.isNumeric(mdl.creditCardExpiryYear)) {
                    errors['IsEmpty_creditCardExpiryYear'] = true;
                    errors['creditCardExpiryYearMsg'] = "Invalid contain : year";
                    count++;
                }
                else if (!validator.isLength(mdl.creditCardExpiryYear, 4, 4)) {
                    errors['IsEmpty_creditCardExpiryYear'] = true;
                    errors['creditCardExpiryYearMsg'] = "Invalid year format";
                    count++;
                }
            }
            if (mdl.creditCardCVV === null || mdl.creditCardCVV.trim() === "" || mdl.creditCardCVV === undefined) {
                errors['IsEmpty_creditCardCVV'] = true;
                errors['creditCardCVVMsg'] = "Required field: CVV";
                count++;
            }
            else {
                if (!validator.isNumeric(mdl.creditCardCVV)) {
                    errors['IsEmpty_creditCardCVV'] = true;
                    errors['creditCardCVVMsg'] = "Not a valid number";
                    count++;
                }
                else if (!validator.isLength(mdl.creditCardCVV, 3, 4)) {
                    errors['IsEmpty_creditCardCVV'] = true;
                    errors['creditCardCVVMsg'] = "Should contain at least 3 or 4 digit";
                    count++;
                }
            }
        }
        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }
    //change payment method
    _UpsertPaymentProfile = () => {
        this.setState({ LoaderStatus: true }); // show loader
        UpsertPaymentProfile(this.state.PaymentProfileMdl).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ PaymentProfileMdl: response.data });
                    window.$('#modalChangePaymentMethod').modal('hide');
                    showSuccess(response.message);
                    window.$('#modalChangeAccountInfo').modal('show');
                }
                else {
                    showError(response.message);
                }
                this.setState({ LoaderStatus: false }); // hide loader
            }
        });
    }
    // change payment info
    _UpsertPaymentInfo = () => {
        let status = this.PaymentInfoValidation(this.state.PaymentProfileMdl);
        if (status) {
            this.setState({ LoaderStatus: true }); // show loader
            UpsertPaymentProfile(this.state.PaymentProfileMdl).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        this.setState({ PaymentProfileMdl: response.data });
                        window.$('#modalChangeAccountInfo').modal('hide');
                        showSuccess(response.message);
                    }
                    else {
                        showError(response.message);
                    }
                    this.setState({ LoaderStatus: false }); // hide loader
                }
            });
        }
    }
    
    _GetAgentInformation = (userId, paymentId) => {
        GetAgentInformation(userId).then(data => {
            let response = data;
            if (response.message !== Error) {
                const selectedAgent = response.data.find(x => x.paymentId === paymentId);
                this.setState({ 
                    AgentInformation: response.data,
                    SelectedAccountAgent: selectedAgent
                });
            }
            this.setState({ LoaderStatus: false });
        })
    }

    GetFullAddress = () => {
        return (this.state.PersonalInformation.address) ? this.state.PersonalInformation.address + ', ' + this.state.PersonalInformation.city + ', ' + this.state.PersonalInformation.state + ' ' + this.state.PersonalInformation.zip : ''
    }
    //for change payment method
    handleChangePaymentMethod = (event) => {
        //if (this.state.CustomerDetailMdl.usBankDetails != null && this.state.CustomerDetailMdl.cardDetails != null) {
        let PaymentType = parseInt(event.target.value);
            this.setState(Object.assign(this.state.PaymentProfileMdl, { paymentTypeId: PaymentType }));
        //}
        let dropDown = document.getElementById('payment-method-dd');
        this.emptyInputFeild();
        if (event.target.value === "1") {
            dropDown.src = './assets/Content/images/cc-small.png';
            document.getElementById('cc-form').classList.remove('d-none');
            document.getElementById('ach-form').classList.add('d-none');
        }
        else {
            dropDown.src = './assets/Content/images/dollar-sign-small.png';
            document.getElementById('cc-form').classList.add('d-none');
            document.getElementById('ach-form').classList.remove('d-none');
        }
    }
    //for change payment info
    handleChangePaymentInfo = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState(Object.assign(this.state.PaymentProfileMdl, { [name]: value }));
    }

    //start funstions for pagination
    //handlePageClick = (data) => {
    //    let PageNumber = data.selected + 1;
    //    this.setState(Object.assign(this.state.searchMdl, { PageNumber: PageNumber }));
    //    this.GetAllInsuredPoliciesByUserId(this.state.searchMdl, this.state.userId);
    //}
    handlePageClick = (data) => {
        const selectedPage = data.selected;

        // Check for boundary conditions
        if (selectedPage < 0 || selectedPage >= this.state.AllInsuredPolicies.length) {
            return;
        }

        const policyIndex = selectedPage;
        const PaymentId = this.state.AllInsuredPolicies[policyIndex].paymentId;
        const Email = this.state.AllInsuredPolicies[policyIndex].emailAddress;
        const InsuredId = this.state.AllInsuredPolicies[policyIndex].userId;
        const InsuredName = this.state.AllInsuredPolicies[policyIndex].insuredName;
        this.setState({ currentPolicy: this.state.AllInsuredPolicies[policyIndex] });
        this.setState({ paymentId: PaymentId, InsuredId: InsuredId, InsuredName: InsuredName });
        this.setState({
            currentPolicyIndex: policyIndex,
            searchMdl: {
                PageNumber: selectedPage + 1
            }
        });
        this.GetAllInsuredPoliciesByPaymentId(PaymentId);

        this._GetAllMethodofPayments(PaymentId, Email);
        this._GetAllRetrivePaymentAccount(this.state.AllInsuredPolicies[policyIndex]);
        this.transitionPage();
        this.setState({SelectedAccountAgent: this.state.AgentInformation.find(x => x.paymentId === PaymentId)});
    };

    
    SwipeableWrapper = (props) => {
        const handlers = useSwipeable({
            onSwipedLeft: () => {
                let data = {
                     selected: (this.state.searchMdl.PageNumber - 1) + 1
                }
                if (data.selected < this.state.AllInsuredPolicies.length)
                    this.handlePageClick(data)
            },
            onSwipedRight: () => {
                let data = {
                     selected: (this.state.searchMdl.PageNumber - 1) - 1
                }
                if (data.selected >= 0)
                    this.handlePageClick(data)
            },
            preventDefaultTouchmoveEvent: true,
        });

        return (
            <div {...handlers} style={{ touchAction: 'pan-y' }}>
                {props.children}
            </div>
        );
    };
    
    transitionPage = () => {
        let element = document.getElementById("transition");
        // element.classList.add("visibility-transition-hide");
        // element.classList.remove("visibility-transition-hide");
        element.classList.add("fade-in-animation");
    }
    CheckIsDBPaid = (Policy) => {
        const prefix = "AISDB";
        const isAISDB = Policy.policyNumber && typeof Policy.policyNumber === 'string' &&
            Policy.policyNumber.slice(0, prefix.length) === prefix;
        return isAISDB;
    }
    IsOutAutoPay = (event) => {
        let element = document.getElementById("autopay-table");
        if (event.target.checked) {
            this.setState({ isOutAutoPay: true });
            element.classList.remove("disabled");
        }
        else {
            this.setState({ isOutAutoPay: false });
            element.classList.add("disabled");
        }
    }
    
    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
        const billingAccountNumber = selectedOption.aisBillingAccountNumber;
        const userId = selectedOption.value;
        const label = selectedOption.label;
        localStorage.setItem("cachedBillingAccount", JSON.stringify({billingAccountNumber: billingAccountNumber, value: userId, label: label}));
        
        this.changeSelectedAisAccount(billingAccountNumber);
    };
    
    changeSelectedAisAccount = (aisAccountNumber) => {
        const selectedPolicy = this.state.AllInsuredPolicies.find(x => x.billingAccountNumber === aisAccountNumber);
        const PaymentId = selectedPolicy.paymentId;
        const Email = selectedPolicy.emailAddress;
        const InsuredId = selectedPolicy.userId;
        const InsuredName = selectedPolicy.insuredName;
        this.setState({ currentPolicy: selectedPolicy });
        this.setState({ paymentId: PaymentId, InsuredId: InsuredId, InsuredName: InsuredName });
        // this.setState({
        //     currentPolicyIndex: policyIndex,
        //     searchMdl: {
        //         PageNumber: selectedPage + 1
        //     }
        // });
        this.GetAllInsuredPoliciesByPaymentId(PaymentId);

        this._GetAllMethodofPayments(PaymentId, Email);
        this._GetAllRetrivePaymentAccount(selectedPolicy);
        this.transitionPage();
        this.setState({SelectedAccountAgent: this.state.AgentInformation.find(x => x.paymentId === PaymentId)});
    }
    render() {
        //const modalLink = (this.state.CustomerDetailMdl.usBankDetails || this.state.CustomerDetailMdl.cardDetails) ? "#modalChangeAccountInfo" : "#modalChangePaymentMethod";
        const modalLink = "#modalChangePaymentMethod";
        let url = subDomain().SubDomain === 'Xgen' ? "" : "/" + subDomain().SubDomain;
        const { AllInsuredPolicies, currentPolicy, Pagecount, searchMdl, PageLoaderStatus, SelectedAccountAgent } = this.state;
        // const currentPolicy = AllInsuredPolicies[currentPolicyIndex] || {};
        const isAISDB = this.CheckIsDBPaid(currentPolicy);
        const lastIndex = this.state.AllMethodOfPayments.length - 1;
        const options = this.state.AllInsuredPolicies.map((items) => ({
            value: items.billingAccountNumber,
            label: items.insuredName + ' | ' +  items.coverageType + " | " + CustomCurrencyFormat(items.accountBalance),
            aisBillingAccountNumber: items.billingAccountNumber
        }));
        // const handlers = useSwipeable({
        //     onSwipedLeft: () => this.handlePageClick({ selected: this.state.currentPolicyIndex + 1 }),
        //     onSwipedRight: () => this.handlePageClick({ selected: this.state.currentPolicyIndex - 1 }),
        //     preventDefaultTouchmoveEvent: true,
        //     trackMouse: true,
        // });
        return (
            <>
           {PageLoaderStatus && <LoaderContents />}
           {!PageLoaderStatus &&
                <div className="main-wrapper-newDesign account-overview">
                    <InternalLayoutHeader />
                    {/*<div className="d-flex paginate-div">*/}
                    {/*    <ReactPaginate*/}
                    {/*        previousLabel={"Previous"}*/}
                    {/*        nextLabel={"Next"}*/}
                    {/*        onPageChange={this.handlePageClick}*/}
                    {/*        containerClassName={"pagination justify-content-start"}*/}
                    {/*        pageCount={Pagecount ?? 0}*/}
                    {/*        pageClassName={"page-item"}*/}
                    {/*        pageLinkClassName={"page-link"}*/}
                    {/*        previousClassName={"page-item"}*/}
                    {/*        nextClassName={"page-item"}*/}
                    {/*        nextLinkClassName={"page-link"}*/}
                    {/*        breakClassName={"page-item"}*/}
                    {/*        breakLinkClassName={"page-link"}*/}
                    {/*        activeClassName={"active"}*/}
                    {/*        disabledLinkClassName={"disabled"}*/}
                    {/*    />*/}
                    {/*    <div className="align-items-end">*/}
                    {/*        Page {searchMdl.PageNumber} of {Pagecount}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <Fragment>
                        <div className="content-main p-lg-4 p-3">
                            <h1 className="h3 mb-0 fw-normal"><strong>Account Overview</strong></h1>
                            <Select className="form-control input-newDesign input-wrapper-payment font-size-small" 
                                    options={options}
                                    onChange={this.handleChange}
                                    isSearchable
                                    defaultValue={options.find(option => option.value === currentPolicy.billingAccountNumber)}>
                            </Select>
                            <div id="transition" className="mt-4 container-overview account-overview position-relative container">
                                <this.SwipeableWrapper>
                                    {/*<div className="d-flex justify-content-between paginate-div pagination-overview">*/}
                                    {/*    <button*/}
                                    {/*        className="page-link page-item"*/}
                                    {/*        onClick={() => this.handlePageClick({ selected: this.state.currentPolicyIndex - 1 })}*/}
                                    {/*        disabled={this.state.currentPolicyIndex === 0}*/}
                                    {/*    >*/}
                                    {/*        Previous*/}
                                    {/*    </button>*/}
                                    {/*    <button*/}
                                    {/*        className="page-link page-item"*/}
                                    {/*        onClick={() => this.handlePageClick({ selected: this.state.currentPolicyIndex + 1 })}*/}
                                    {/*        disabled={this.state.currentPolicyIndex === this.state.AllInsuredPolicies.length - 1}*/}
                                    {/*    >*/}
                                    {/*        Next*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}

                                    <div className="row">
                                        <div className="grid-container-newDesign-left col">
                                            <div className="overview-card account-details">
                                                <div className="account-info">
                                                    <div>
                                                        <p className="label-overview-newDesign">Account Number</p>
                                                        <p className="value">{currentPolicy.billingAccountNumber}</p>
                                                    </div>
                                                    <div className='account-detail'>
                                                        <div className="account-detail-labels">
                                                            <p className="table-label">Policy Type</p>
                                                            <p className="table-label">Premium Amount</p>
                                                            <p className="table-label">Account Balance</p>
                                                                <p className="table-label">{isAISDB ? "Installment Fee" : "APR" }</p>
                                                        </div>
                                                        <div className="account-detail-info">
                                                            <p className="table-data big-label">{currentPolicy.coverageType || "\u00A0" }</p>
                                                            <p className="table-data small-label">{currentPolicy.coverageTypeId || "\u00A0" }</p>
                                                            <p className="table-data">{CustomCurrencyFormat(this.state.AccountDetails?.premimumAmount)}</p>
                                                            <p className="table-data">{CustomCurrencyFormat(currentPolicy.minimumPaymentDue * currentPolicy.policyPeriod)}</p>
                                                                <p className="table-data">{isAISDB ? CustomCurrencyFormat(currentPolicy.installmentFee) : "$17.66"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overview-card agent-info">
                                                <p className="label-lower">Agent's Information</p>
                                                <hr />
                                                {SelectedAccountAgent != null ? (
                                                    <>
                                                            <p className="agent-header">Name <span className="value">{SelectedAccountAgent?.fullName}</span></p>
                                                            <p className="agent-header">Phone <span className="value">{formatPhoneNumber(SelectedAccountAgent?.phoneNumber)}</span></p>
                                                            <p className="agent-header">Email <span className="value">{SelectedAccountAgent?.email}</span></p>
                                                            <p className="agent-header">Address <span className="value">{SelectedAccountAgent?.address}</span></p>
                                                            </>)
                                                    : (<>
                                                            <p className="agent-header">Agent not found <span
                                                                className="value">Please contact your insurance provider to update your connected agent</span>
                                                            </p>
                                                            <p className="agent-header">&nbsp; <span
                                                                className="value"></span></p>
                                                            <p className="agent-header">&nbsp; <span
                                                                className="value"></span></p>
                                                            <p className="agent-header">&nbsp; <span
                                                                className="value"></span></p>
                                                        </>

                                                    )
                                                }
                                            </div>
                                                <div className="overview-card account-options">
                                                    <p className="label-lower">Account Options</p>
                                                    <hr />
                                                    <div></div>
                                                    <div></div>
                                                    <a href="#modalChangePassword" data-bs-toggle="modal" className="text-black option-btn first-button text-decoration-none">
                                                        <span className="icon-svg-overview">
                                                            <img src="./assets/Content/images/key.png"
                                                                alt="Change Password"></img>
                                                        </span>
                                                        <div className="btn-content-newDesign">Change Password</div>
                                                    </a>
                                                    <a href={modalLink} data-bs-toggle="modal" className="text-black option-btn first-button text-decoration-none">
                                                        <span className="icon-svg-overview">
                                                            <img src="./assets/Content/images/cc.png"
                                                                alt="Update Payment Method"></img>
                                                        </span>
                                                        <div className="btn-content-newDesign">Update Payment Method</div>
                                                    </a>
                                                    <a href="#modalPendingPayment" data-bs-toggle="modal" className="text-black option-btn first-button text-decoration-none">
                                                        <span className="icon-svg-overview">
                                                            <img src="./assets/Content/images/coins.png"></img>
                                                        </span>
                                                        <div className="btn-content-newDesign">Pending Payments</div>
                                                    </a>
                                                    <a href="#modalPolicyInformation" data-bs-toggle="modal" className="text-black option-btn first-button text-decoration-none">
                                                        <span className="icon-svg-overview">
                                                            <img src="./assets/Content/images/map-pin.png"
                                                                alt="Policy Information"></img>
                                                        </span>
                                                        <div className="btn-content-newDesign">Policy Information</div>
                                                    </a>
                                                </div>
                                        </div>
                                        <div className="grid-container-newDesign-right col-lg">
                                            <div className="overview-card payment-amount d-flex justify-content-start">
                                                <div className="payment-amount-upper">
                                                    <p className="label-overview-newDesign">Next Payment Amount</p>
                                                    <span className="payment-value">${currentPolicy.minimumPaymentDue}</span>
                                                </div>
                                                <hr />
                                                <div className="payment-info">
                                                    <div className="payment-info-top">
                                                        <p className="bold">Next Payment Due: </p>
                                                        <p className="payment-card-value">{this.state.nextDueDate}</p>
                                                    </div>
                                                    <div className="payment-info-bottom">
                                                        <p className="bold">Payments Remaining: </p>
                                                        <p className="payment-card-value">{this.state.AccountDetails?.remainingMonths}</p>
                                                    </div>
                                                </div>
                                                {subDomain().SubDomain?.toLowerCase() === 'xgen' &&
                                                    <NavLink to={url + "/make-payment?PaymentId=" + currentPolicy.paymentId + ""} className={`btn btn-newDesign pay-now-btn`}>Pay Now</NavLink>
                                                }
                                                {subDomain().SubDomain?.toLowerCase() !== 'xgen' &&
                                                    <NavLink to={url + "/make-payment?PaymentId=" + currentPolicy.paymentId + ""} className={`btn btn-newDesign pay-now-btn`}>Pay Now</NavLink>
                                                }
    
                                            </div>
                                            <div className="overview-card payment-history">
                                                <p className="fw-bold">Payment History</p>
                                                    <hr />
                                                    <div className="payment-items">
                                                    { /*loader show befoure load contents*/}
                                                        {this.state.RetriveApiLoader && <LoaderContents />}
                                                        {
                                                            this.state.AllMethodOfPayments.map((items1) => (
                                                                <div className="payment-item" key={items1.id}>
                                                                    <div className="payment-description">
                                                                        <p>{items1.methodofPayment} Payment</p>
                                                                        <p className="payment-date">{DateFormat(items1.datePaid)}</p>
                                                                    </div>
                                                                    <p className="payment-amount">{CustomCurrencyFormat(items1.amountPaid)}</p>
                                                                </div>
                                                            ))
                                                        }
                                                        {/*{!this.state.RetriveApiLoader && this.state.payemntHistory?.map((items1) => {*/}
                                                        {/*    const year = items1.paymentMadeDate.slice(4);*/}
                                                        {/*    const month = items1.paymentMadeDate.slice(0, 2) - 1; // JavaScript months are 0-indexed*/}
                                                        {/*    const day = items1.paymentMadeDate.slice(2, 4);*/}
                                                        {/*    const date = new Date(year, month, day);*/}
                                                        {/*    */}
                                                        {/*    const formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' });*/}
                                                        
                                                        {/*    return (*/}
                                                        {/*        <div className="payment-item" key={items1.sequenceNumber}>*/}
                                                        {/*            <div className="payment-description">*/}
                                                        {/*                <p>{(items1.paymentAccountType == "CARD" || items1.paymentAccountType == "Credit Card") ? "Credit Card" : "ACH" } Payment</p>*/}
                                                        {/*                <p className="payment-date">{formattedDate}</p>*/}
                                                        {/*            </div>*/}
                                                        {/*            <p className="payment-amount">{CustomCurrencyFormat(items1.paymentAmount)}</p>*/}
                                                        {/*        </div>*/}
                                                        {/*    );*/}
                                                        {/*})}*/}

                                                </div>
    
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card dashboard-card-newDesign mt-4">
                                        <div className="row">
                                            <div className="col-lg-5 col-sm-5 col-12 ">
    
                                            </div>
                                        </div>
                                        <div className="card-body details-card">
                                            <div className="w-100 table-responsive">
                                                <table className="table insured-table mb-0">
                                                    <thead>
                                                        <h1 className="Details-table-header">Details</h1>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="details-table-label">Late Charge</td>
                                                            <td className="details-table-data">$0.00</td>
    
                                                            <td className="details-table-label">Return Check Charge</td>
                                                            <td className="details-table-data">$0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-table-label">Cancellation Charge</td>
                                                            <td className="details-table-data">$0.00</td>
    
                                                            <td className="details-table-label">Finance Charge</td>
                                                            <td className="details-table-data">$0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-table-label">Payoff Amount</td>
                                                            <td className="details-table-data">{CustomCurrencyFormat(currentPolicy.minimumPaymentDue * currentPolicy.policyPeriod)}</td>
    
                                                            <td className="details-table-label">Refund and Adjustments</td>
                                                            <td className="details-table-data">$0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-table-label">Payments Made</td>
                                                            <td className="details-table-data">{this.state.AllMethodOfPayments.length}</td>
    
                                                            <td className="details-table-label">Payments Left</td>
                                                            <td className="details-table-data">{this.state.AccountDetails?.remainingMonths}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-table-label">Down Payment</td>
                                                            <td className="details-table-data">{CustomCurrencyFormat(currentPolicy.downPayment)}</td>
    
                                                            <td className="details-table-label">{isAISDB ? "Installment Fee" : "Annual Percentage Rate"}</td>
                                                                <td className="details-table-data">{isAISDB ? CustomCurrencyFormat(currentPolicy.installmentFee) : "$17.66"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-table-label">Min. Earned Premium Percentage</td>
                                                            <td className="details-table-data">{currentPolicy.mepPercentage} %</td>
    
                                                            <td className="details-table-label">Original Amount Financed</td>
                                                            <td className="details-table-data">{CustomCurrencyFormat(this.state.AccountDetails?.premimumAmount - currentPolicy.downPayment)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-table-label">First Payment Date</td>
                                                                <td className="details-table-data">{this.state.AllMethodOfPayments[0] && this.state.AllMethodOfPayments[lastIndex].datePaid}</td>
                                                            
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </this.SwipeableWrapper>

                                { /*model for change Auto Pay Account Info*/}
                                <div className="modal fade" tabIndex="-1" id="modalChangeAccountInfo" aria-labelledby="modalChangeAccountInfo" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header justify-content-center pt-3 border-0">
                                                <img className="modal-image" src="./assets/Content/images/cc-glow.png"></img>
                                                <h5 className="modal-title">Update Autopay</h5>
                                            </div>
                                            <div className="modal-body-newDesign">
                                                <div>
                                                    { /*loader show befoure load contents*/}
                                                    {this.state.AutoPayLoaderStatus && <LoaderContents />}
                                                </div>
                                                <form className="w-75 m-auto" id="frmpaymentsinfo" onChange={this.handleChangePaymentInfo} onKeyDown={this._UpsertPaymentInfo_EnterButton}>
                                                    {this.state.PaymentProfileMdl.paymentTypeId === PaymentTypeList.ACH &&
                                                        <div>
                                                            {(this.state.CustomerDetailMdl?.usBankDetails !== null && this.state.CustomerDetailMdl?.usBankDetails !== "") &&
                                                                <div>
                                                                    <div className="table-responsive">
                                                                        <table id="autopay-table" className="table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Account Holder Name</th>
                                                                                    <th>Routing Number</th>
                                                                                    <th>Account Last4</th>
                                                                                    <th>Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>{this.state.CustomerDetailMdl?.customerDetails?.name}</td>
                                                                                    <td>{this.state.CustomerDetailMdl?.usBankDetails?.routingNumber}</td>
                                                                                    <td>{this.state.CustomerDetailMdl?.usBankDetails?.last4}</td>
                                                                                    {/*<td><NavLink to="#modalChangePaymentMethod" data-bs-toggle="modal">Edit</NavLink></td>*/}
                                                                                    <td><NavLink to="#confirmdeletemdl" data-bs-toggle="modal">Cancel</NavLink></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>

                                                                            <div className="col-12 promotional-modal-check w-auto">
                                                                                <input type="checkbox" id="radio-Active" name="IsTextActive" checked={true} required className='form-check-input form-control' onChange={this.IsOutAutoPay} />
                                                                                <label className="form-check-label mt-2">
                                                                                    <h6 className="mb-0 AutoPay-label-text">Auto Pay</h6>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    {/*<p className="pt-3">Note: This will be the account which is used for Term Deduction. </p>*/}
                                                                </div>
                                                            }
                                                            {this.state.CustomerDetailMdl?.usBankDetails === "" &&
                                                                <div className='ACH-Section' id='ACH-Section'>
                                                                    <ACHForm />
                                                                </div>
                                                            }


                                                        </div>
                                                    }
                                                    {this.state.PaymentProfileMdl.paymentTypeId === PaymentTypeList.CreditCard &&
                                                        <div>
                                                            {
                                                                (this.state.CustomerDetailMdl.cardDetails !== null && this.state.CustomerDetailMdl.cardDetails !== "") &&
                                                                <div>
                                                                    <div className="table-responsive">
                                                                        <table id="autopay-table" className="table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Name</th>
                                                                                    <th>Last4</th>
                                                                                    <th>Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Visa</td>
                                                                                    <td>{this.state.CustomerDetailMdl.cardDetails?.last4}</td>
                                                                                    {/*<td><NavLink to="#modalChangePaymentMethod" data-bs-toggle="modal">Edit</NavLink></td>*/}
                                                                                    <td><NavLink to="#confirmdeletemdl" data-bs-toggle="modal">Cancel</NavLink></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                            <div className="col-12 promotional-modal-check w-auto">
                                                                                <input type="checkbox" id="radio-Active" name="IsTextActive" checked={this.state.isOutAutoPay} required className='form-check-input form-control' onChange={this.IsOutAutoPay} />
                                                                                <label className="form-check-label mt-2">
                                                                                    <h6 className="mb-0 AutoPay-label-text">Auto Pay</h6>
                                                                                </label>
                                                                            </div>
                                                                    </div>
                                                                    {/*<p className="pt-3">Note*: This will be the account which is used for Term Deduction. </p>*/}
                                                                </div>
                                                            }
                                                            {(this.state.CustomerDetailMdl.cardDetails === "" &&
                                                                <div className='CreditCard-Section' id='CreditCard-Section'>
                                                                    <Elements stripe={stripePromise}>
                                                                        <CreditCardForm />
                                                                    </Elements>
                                                                </div>)
                                                            }
                                                        </div>
                                                    }

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { /*model for change password*/}
                                <div className="modal fade" tabIndex="-1" id="modalChangePassword" aria-labelledby="chagePassword" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="justify-content-end"
                                                        style={{
                                                            top: '0',
                                                            right: '0',
                                                            zIndex: '999',
                                                            position: 'absolute',
                                                            cursor: 'pointer',
                                                            padding: 14,
                                                        }}>
                                                        <img
                                                            style={{
                                                                width: 30,
                                                                height: 30,
                                                            }}
                                                            src="./assets/Content/images/x.png" data-bs-dismiss="modal">
                                                        </img>
                                                    </div>
                                            <div className="modal-header justify-content-start pt-4 border-0">
                                                <img className="modal-image" src="./assets/Content/images/key-glow.png"></img>
                                                <h5 className="modal-title text-center">Change Password</h5>
                                            </div>
                                            <div className="modal-body-newDesign">
                                                <div>
                                                    { /*loader show befoure load contents*/}
                                                    {this.state.ChangePassLoaderStatus && <LoaderContents />}
                                                </div>
                                                <form className="w-75 m-auto" id="frmchangepassword" onKeyDown={this._ChangePassword_EnterButton}>
                                                    <p className="modal-header-newDesign">Current Password</p>
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                        <input type="password" className={(this.state.Errors['IsEmpty_currentPassword'] === true ? ' has-err form-control input-newDesign' : 'form-control input-newDesign')} ref="currentPassword" id="currentPassword" aria-describedby="currentPassword" placeholder="Enter your current password *" required />
                                                        {this.state.Errors['IsEmpty_currentPassword'] === true ? <div className="has-err-msg">{this.state.Errors['currentPasswordMsg']}</div> : ''}
                                                        <label htmlFor="currentPassword">Enter your current password</label>
                                                    </div>
                                                    <p>New Password</p>
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                        <input type="password" className={(this.state.Errors['IsEmpty_newPassword'] === true ? ' has-err form-control input-newDesign' : 'form-control input-newDesign')} ref="newPassword" id="newPassword" aria-describedby="newPassword" placeholder="Enter your new password *" required />
                                                        {this.state.Errors['IsEmpty_newPassword'] === true ? <div className="has-err-msg">{this.state.Errors['newPasswordMsg']}</div> : ''}
                                                        <label htmlFor="newPassword">Enter your new password</label>
                                                    </div>
                                                    <p>Confirm Password</p>
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                        <input type="password" className={(this.state.Errors['IsEmpty_confirmPassword'] === true ? ' has-err form-control input-newDesign' : 'form-control input-newDesign')} ref="confirmPassword" id="confirmPassword" name="confirmPassword" placeholder="Confirm password *" required />
                                                        {this.state.Errors['IsEmpty_confirmPassword'] === true ? <div className="has-err-msg">{this.state.Errors['confirmPasswordMsg']}</div> : ''}
                                                        <label htmlFor="confirmPassword">Confirm Password</label>
                                                    </div>
                                                    <div className="text-center d-flex justify-content-center">
                                                        {/*<button type="button" id="btncloseMdl" data-bs-dismiss="modal" className="btn btn-newDesign-reverse me-2">Close</button>*/}
                                                        <button type="button" className="btn btn-newDesign" onClick={() => this._ChangePassword()}>Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { /*model for change payment method*/}
                                <div className="modal fade" tabIndex="-1" id="modalChangePaymentMethod" aria-labelledby="modalChangePaymentMethod" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content payment-method-modal">
                                            <div className="modal-header justify-content-center pt-4 border-0">
                                                <img className="modal-image" src="./assets/Content/images/cc-glow.png"></img>
                                                <h5 className="modal-title text-center">Update Payment Method</h5>
                                            </div>
                                            <div className="modal-body">
                                                <div>
                                                    { /*loader show befoure load contents*/}
                                                        {this.state.UpdatePaymentMethodLoader ? (
                                                                <div className="loaderBody" >
                                                            <LoaderContents />
                                                                </div>

                                                        ) : (
                                                            <form className="m-auto" id="frmchangePaymentMethod" onKeyDown={this._UpsertPaymentProfile_EnterButton}>
                                                                <div className="mx-auto mb-3" >
                                                                    <div className='input-wrapper-radio row' onChange={this.handleChangePaymentMethod}>
                                                                        <div className="card-header bg-white border-white p-2">
                                                                            <p>Payment Method</p>
                                                                            <div className="custom-select-wrapper">
                                                                                <div className="custom-select-container">
                                                                                    <img id="payment-method-dd" className="nav-img" src="./assets/Content/images/cc-small.png" alt="Payment Method" />
                                                                                    <select className="form-select input-newDesign"
                                                                                        defaultValue={this.state.PaymentProfileMdl.paymentTypeId}>
                                                                                        <option value="1">Credit Card</option>
                                                                                        <option value="2" >ACH</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id="cc-form" className="credit-card-form">
                                                                        <div className="payment-method-cc-forms">
                                                                            <div className="col-left">
                                                                                <p className="modal-header-newDesign">Name on Card</p>
                                                                                <div className="form-floating input-wrapper-payment mb-3">
                                                                                    <input name="creditCardHolderName" value={this.state.PaymentProfileMdl.creditCardHolderName} className="form-control input-newDesign" onChange={this.handleInputChange} placeholder="Full Name" />
                                                                                    <label>Full Name</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-right">
                                                                                <p className="modal-header-newDesign">Expiration</p>
                                                                                <div className="form-floating input-wrapper-payment mb-3">
                                                                                    <input name="creditCardExpiry" value={this.state.PaymentProfileMdl.creditCardExpiry} className="form-control input-newDesign" placeholder="MM/YY" onChange={this.handleInputChange} />
                                                                                    <label>MM/YY</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="payment-method-cc-forms">
                                                                            <div className="col-left">
                                                                                <p className="modal-header-newDesign">Card Number</p>
                                                                                <div className="form-floating input-wrapper-payment mb-3">
                                                                                    <input name="creditCardNumber" value={this.state.PaymentProfileMdl.formattedCardNumber} className="form-control input-newDesign" placeholder="Card Number" onChange={this.handleInputChange} />
                                                                                    <label>Card Number</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-right">
                                                                                <p className="modal-header-newDesign">CVV</p>
                                                                                <div className="form-floating input-wrapper-payment mb-3">
                                                                                    <input name="creditCardCVV" value={this.state.PaymentProfileMdl.creditCardCVV} className="form-control input-newDesign" onChange={this.handleInputChange} placeholder="CVV" />
                                                                                    <label>CVV</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id="ach-form" className="credit-card-form d-none">
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <p className="modal-header-newDesign">Name on Account</p>
                                                                                <div className="form-floating input-wrapper-payment mb-3">
                                                                                    <input name="achAccountHolderName" value={this.state.PaymentProfileMdl.achAccountHolderName} className="form-control input-newDesign" onChange={this.handleInputChange} placeholder="Full Name" />
                                                                                    <label>Full Name</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col">
                                                                                <p className="modal-header-newDesign">Bank Name</p>
                                                                                <div className="form-floating input-wrapper-payment mb-3">
                                                                                    <input name="achBankName" value={this.state.PaymentProfileMdl.achBankName} className="form-control input-newDesign" onChange={this.handleInputChange} placeholder="Legal Name" />
                                                                                    <label>Legal Name</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <p className="modal-header-newDesign">Routing Number</p>
                                                                                <div className="form-floating input-wrapper-payment mb-3">
                                                                                    <input name="achRoutingNumber" value={this.state.PaymentProfileMdl.achRoutingNumber} className="form-control input-newDesign" onChange={this.handleInputChange} placeholder="Routing Number" />
                                                                                    <label>Routing Number</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col">
                                                                                <p className="modal-header-newDesign">Accounting Number</p>
                                                                                <div className="form-floating input-wrapper-payment mb-3">
                                                                                    <input name="achAccountNumber" value={this.state.PaymentProfileMdl.achAccountNumber} className="form-control input-newDesign" onChange={this.handleInputChange} placeholder="Account Number" />
                                                                                    <label>Accounting Number</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                    <div className="text-center d-flex justify-content-center">
                                                                        <button type="button" id="btnclosePaymentMethodMdl" data-bs-dismiss="modal" className="btn btn-newDesign-reverse me-2" onClick={ this.emptyInputFeild }>Close</button>
                                                                    <button type="button" className="btn btn-newDesign" onClick={this._UpdatePaymentMethod}>Submit</button>
                                                                </div>
                                                                {/*<div className="text-center">*/}
                                                                {/*    <Button type="button" className="btn btn-primary btn-lg px-5 submit-btn" onClick={() => this._UpsertPaymentProfile()}>Submit</Button>*/}
                                                                {/*</div>*/}
                                                            </form>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { /*model for change address*/}
                                <div className="modal fade" tabIndex="-1" id="modalChangeAddress" aria-labelledby="changeAddress" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header justify-content-center pt-4 border-0">
                                                <img className="modal-image" src="./assets/Content/images/map-pin-glow-cropped.png"></img>
                                                <h5 className="modal-title">Change Address</h5>
                                            </div>
                                            <div className="modal-body">
                                                <div className="pt20px">
                                                    { /*loader show befoure load contents*/}
                                                        {this.state.ChangeAddressLoaderStatus ? (
                                                            <LoaderContents />
                                                        ) : (
                                                            <form className="w-75 m-auto" id="frmchangeaddress" onKeyDown={this._ChangeAddress_EnterButton}>
                                                                {/*<div className="form-floating input-wrapper-payment mb-3">*/}
                                                                {/*    */}{/*<input type="text" className={'form-control' + (this.state.Errors['IsEmpty_Address'] === true ? ' has-err' : '')} ref="Address" id="autocomplete_address" aria-describedby="Address" placeholder="Enter your address *" name="address" defaultValue={this.state.UserProfileMdl.address ?? ""} required />*/}
                                                                {/*    <GetPlace*/}
                                                                {/*        className={'form-control' + (this.state.Errors['IsEmpty_Address'] === true ? ' has-err' : '')}*/}
                                                                {/*        id="address"*/}
                                                                {/*        placeholder="Enter your address *"*/}
                                                                {/*        name="address"*/}
                                                                {/*        inputAutocompleteValue="off"*/}
                                                                {/*        defaultValue={this.state.UserProfileMdl.address ?? ""}*/}
                                                                {/*        onPlaceSelected={(place) => {*/}
                                                                {/*            this.setState(this.setState(Object.assign(this.state.UserProfileMdl, { address: place.formatted_address })));*/}
                                                                {/*        }}*/}
                                                                {/*        onChange={(place) => {*/}
                                                                {/*            this.setState(this.setState(Object.assign(this.state.UserProfileMdl, { address: place.formatted_address })));*/}
                                                                {/*        }}*/}
                                                                {/*        required*/}
                                                                {/*    />*/}
                                                                {/*    {this.state.Errors['IsEmpty_Address'] === true ? <div className="has-err-msg">{this.state.Errors['AddressMsg']}</div> : ''}*/}
                                                                {/*    <label htmlFor="address">Enter your Address</label>*/}
                                                                {/*</div>*/}
                                                                <p>Enter your address</p>
                                                                <div className="form-floating input-wrapper-payment mb-3">
                                                                    <input type="text" className={'form-control' + (this.state.Errors['IsEmpty_Address'] === true ? ' has-err' : '')} defaultValue={this.state.UserProfileMdl.address ?? ""} ref="address" id="address" aria-describedby="address" placeholder="Enter your Address *" required />
                                                                    {this.state.Errors['IsEmpty_Address'] === true ? <div className="has-err-msg">{this.state.Errors['AddressMsg']}</div> : ''}
                                                                    <label htmlFor="address">Enter your Address</label>
                                                                </div>

                                                                <div className="text-center d-flex justify-content-center">
                                                                    <button type="button" id="btncloseAddressMdl" data-bs-dismiss="modal" className="btn btn-newDesign-reverse me-2">Close</button>
                                                                    <button type="button" className="btn btn-newDesign" onClick={() => this._ChangeAddress()}>Submit</button>
                                                                </div>
                                                            </form>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { /*model for change Pending Payment*/}
                                <div className="modal fade" tabIndex="-1" id="modalPendingPayment" aria-labelledby="pendingPayment" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered pending-payment-modal">
                                            <div className="modal-content-PolicyInfo">
                                                <div className="justify-content-end "
                                                    style={{
                                                        top: '0',
                                                        right: '0',
                                                        zIndex: '999',
                                                        position: 'absolute',
                                                        cursor: 'pointer',
                                                        padding: 14,
                                                    }}>
                                                    <img
                                                        style={{
                                                            width: 30,
                                                            height: 30,
                                                        }}
                                                        src="./assets/Content/images/x.png" data-bs-dismiss="modal"></img>
                                                </div>
                                                <div className="modal-header justify-content-start pt-4 border-0">

                                                <img className="modal-image" src="./assets/Content/images/coin-glow.png"></img>
                                                <h5 className="modal-title text-center">Pending Payments</h5>
                                            </div>
                                                <div className="modal-body-newDesign">
                                                    <div>
                                                        { /*loader show befoure load contents*/}
                                                    </div>
                                                    <table className="table insured-table mb-0">
                                                        <thead>
                                                        <tr>
                                                            <th>Type</th>
                                                            <th>Amount</th>
                                                            <th>Fee</th>
                                                            <th>Date</th>
                                                            <th>Confirmation Number</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            this.state.paymentHistory?.map((paymentHistory) => (
                                                                <tr>
                                                                    <td>{paymentHistory.paymentAccountType}</td>
                                                                    <td>{paymentHistory.paymentAmount}</td>
                                                                    <td>{paymentHistory.feeAmount}</td>
                                                                    <td>{`${paymentHistory.paymentMadeDate.substring(0, 2)}/${paymentHistory.paymentMadeDate.substring(2, 4)}/${paymentHistory.paymentMadeDate.substring(4)}`}</td>
                                                                    <td>{paymentHistory.paymentReferenceNumber}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>

                                        </div>
                                    </div>
                                </div>
                                { /*model for change Policy Information*/}
                                <div className="modal fade" tabIndex="-1" id="modalPolicyInformation" aria-labelledby="policyInformation" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content-PolicyInfo">
                                                <div className="justify-content-end "
                                                    style={{
                                                        top: '0',
                                                        right: '0',
                                                        zIndex: '999',
                                                        position: 'absolute',
                                                        cursor: 'pointer',
                                                        padding: 14,
                                                    }}>
                                                    <img
                                                        style={{
                                                            width: 30,
                                                            height: 30,
                                                        }}
                                                        src="./assets/Content/images/x.png" data-bs-dismiss="modal"></img>
                                                </div>
                                            <div className="modal-header justify-content-start pt-4 border-0">
                                                <img className="modal-image" src="./assets/Content/images/map-pin-glow.png"></img>
                                                <h5 className="modal-title text-center">Policy Information</h5>
                                            </div>
                                            <div className="modal-body-newDesign">
                                                <div>
                                                    { /*loader show befoure load contents*/}
                                                </div>
                                                <table className="table insured-table mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Account Number</th>
                                                            <th>Company</th>
                                                            <th>Coverage</th>
                                                            <th>Amount</th>
                                                            <th>Effective Date</th>
                                                            <th>Expiration Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr >
                                                            <td id={currentPolicy.paymentId}>{currentPolicy.billingAccountNumber}</td>
                                                            <td id={currentPolicy.paymentId}>{currentPolicy.agencyName}</td>
                                                            <td id={currentPolicy.paymentId}>{currentPolicy.coverageType}</td>
                                                            <td id={currentPolicy.paymentId}>{currentPolicy.downPayment + (currentPolicy.minimumPaymentDue * currentPolicy.policyPeriod)}</td>
                                                            <td id={currentPolicy.paymentId}>{DateFormatShort(currentPolicy?.policyEffectiveDate)}</td>
                                                            <td id={currentPolicy.paymentId}>{DateFormatShort(currentPolicy?.expirationDate)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                { /*model for confirm delete payment method */}
                                <div className="modal fade" id="confirmdeletemdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="staticBackdropLabel">Payment Method</h5>
                                                {/*    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>*/}
                                            </div>
                                            <div className="modal-body">
                                                Do you want to delete this payment method ? {this.state.DeleteLoader && <i className="fa fa-refresh fa-spin fa-1x"></i>}
                                            </div>
                                            <div className="modal-footer">
                                                <Button type="button" id="btncancle" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</Button>
                                                    <Button type="button" id="btndelete" className="btn btn-primary" onClick={() => this._DeletePaymentMethod(this.state.CustomerDetailMdl.customerDetails.id, this.state.CustomerDetailMdl.customerDetails.token)}>Delete</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                    <SiteFooter />
                </div>
            }
           </>
        );
    }
}

export default AccountOverview;