import React, { Component, useState, useEffect } from 'react';
import { SiteFooter } from "../../layout/SiteFooter";
import { authHeader, showError, subDomain, StyledActiveLink, ExternalDomainNavLink } from "../../../common/Common";
import { NavLink } from 'react-router-dom';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { PaymentSuccess, GetPaymentSuccessDetail, GeneratePaymentReceipt, GetPaymentMethodStripe, GetCardInfo, SavePaymentDetails, PaymentProcessing, GenerateDynamicReceipt, SendEnvelopeAfterPayment } from '../../../services/PaymentServices';
import { useStripe, useElements } from '@stripe/react-stripe-js';


export default function PaymentProgress() {
    //const elements = useElements();
    const UserDetails = authHeader();
    const SubDomain = subDomain();
    const queryParams = new URLSearchParams(window.location.search);
    const subDomainUrl = (window.location.pathname.replace(/^\/([^\/]*).*$/, '$1') === "payment-status" ? "Xgen" : window.location.pathname.replace(/^\/([^\/]*).*$/, '$1'));
    const confirmationNumber = (new URLSearchParams(window.location.search)).get("cN");
    const piClientSecret = (new URLSearchParams(window.location.search)).get("payment_intent_client_secret");
    const policyNumber = (new URLSearchParams(window.location.search)).get("pN");
    const fullName = (new URLSearchParams(window.location.search)).get("fN");
    const textActive = Boolean((new URLSearchParams(window.location.search)).get("textActive"));
    const emailActive = Boolean((new URLSearchParams(window.location.search)).get("emailActive"));
    const paymentId = (new URLSearchParams(window.location.search)).get("paymentId");
    const transferGroup = (new URLSearchParams(window.location.search)).get("tG");
    const customerId = (new URLSearchParams(window.location.search)).get("cId");
    const isAutoPay = (new URLSearchParams(window.location.search)).get("iAp");
    const invoiceNumber = (new URLSearchParams(window.location.search)).get("invoiceNumber");
    const isPaidInFull = (new URLSearchParams(window.location.search)).get("isPaidInFull");
    const isPayFull = (new URLSearchParams(window.location.search)).get("isPayFull");
    const isAgavePayment = (new URLSearchParams(window.location.search)).get("isAgavePayment");
    const insuredId = (new URLSearchParams(window.location.search)).get("uId");
    const amount = (new URLSearchParams(window.location.search)).get("amount");
    const last4 = (new URLSearchParams(window.location.search)).get("last4");
    const paymentType = (new URLSearchParams(window.location.search)).get("paymentType");
    const bankAccountNumber = (new URLSearchParams(window.location.search)).get("bankAccountNumber");
    const calculatedTechFee = (new URLSearchParams(window.location.search)).get("calculatedTechFee");

    const [paymentIntent, setPaymentIntent] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    const [result, setResult] = useState({});
    const [intervalId, setIntervalId] = useState(null);
    const [PaymentReceipt, setPaymentReceipt] = useState(null);
    const [IsProcessing, setIsProcessing] = useState(false);
     const [LoaderStatus, setLoaderStatus] = useState(false);
    

    useEffect(() => {
        let id
        if (isAgavePayment == 'true') {
            if (paymentType === "card" && isScheduled != 'true') {
                setPaymentStatus("succeeded");
                const checkPaymentStatus = () => {
                    handleAgaveSubmit(confirmationNumber);
                };
                id = setInterval(checkPaymentStatus, 5000);
                setIntervalId(id);
            }
            else {
                setPaymentStatus("processing");
            }
            _GenerateReceipt();

            return () => {
                if (id) {
                    clearInterval(id);
                }
            };
        }
    }, []);

    const handleAgaveSubmit = (confirmationNumber) => {
       
    }

    const _GenerateReceipt = async () => {
        let stripePaymentMdl = {
            amount: parseFloat(amount).toFixed(2),
            paymentId: paymentId,
            paymentIntentMethod: "US_Bank_Account",
            policyNumber: policyNumber,
            fullName: fullName,
            status: paymentStatus !== 'succeeded' ? false : true,
            isTextActive: textActive,
            isEmailActive: emailActive,
            confirmationNumber: confirmationNumber,
            transferGroup: transferGroup,
            paymentIntentId: paymentIntent.id,
            logo: null,
            subDomain: null,
            subDomainId: 0,
            customerId: customerId,
            isAutoPay: isAutoPay === 'true' ? true : false,
            invoiceNumber: invoiceNumber,
            isPFPaid: isPFPaid === 'true' ? true : false,
            isDBPaid: isDBPaid === 'true' ? true : false,
            isPaidInFull: isPaidInFull === 'true' ? true : false,
            isPayFull: isPayFull == 'true' ? true : false,
            isDownPayment: isDownPayment == 'true' ? true : false,
            isMonthlyPayment: isMonthlyPayment == 'true' ? true : false,
            ReceiptLink: null,
            isProcessing: false,
            last4: last4,
            bankAccountNumber: bankAccountNumber,
            paymentType: paymentType,
            ScheduledDate: scheduledDate,
            calculatedTechFee: calculatedTechFee
        }

        stripePaymentMdl.subDomain = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl;
        stripePaymentMdl.logo = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo;
        stripePaymentMdl.subDomainId = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId;
        const paymentInfo = await GetCardInfo(paymentIntent.payment_method)

        if (!stripePaymentMdl.last4 && stripePaymentMdl.bankAccountNumber === "undefined") {

            if (paymentInfo.type == "Card")
                stripePaymentMdl.last4 = paymentInfo.last4
            else
                stripePaymentMdl.bankAccountNumber = paymentInfo.last4
        }


        var receiptPath = parseInt(stripePaymentMdl.subDomainId) === 2 ? await GenerateDynamicReceipt(stripePaymentMdl) : await GeneratePaymentReceipt(stripePaymentMdl);
        if (receiptPath) {
            setPaymentReceipt(receiptPath.path);  // Assuming setPaymentReceipt updates the state in a React component
            //stripePaymentMdl.ReceiptLink = receiptPath.path;
        }
        return receiptPath?.path;
    }

    var Button = ExternalDomainNavLink(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutColor, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutHoverFocus, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId);

    return (
        <>
            {UserDetails !== null ?
                <div className="main-wrapper-newDesign w-100">
                    <InternalLayoutHeader />	 {/*internal header user wise */}
                    <div className="content-main p-lg-4 p-3">
                        <div className="row">
                            <div className="col-xl-12 col-xxl-12 text-center">
                                <div className="Paymentcard">
                                    {subDomainUrl.toLowerCase() !== 'payment-status' && subDomainUrl.toLowerCase() !== 'xgen' &&
                                        <>
                                            <div className="PaymentLogoWrapper">
                                                <img src={JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo} width="" height="" alt="Partner Logo" className="PaymentLogoImg" />
                                            </div>
                                        {/* {paymentStatus === 'succeeded' && (PaymentReceipt !== null || subDomainUrl.toLowerCase() != 'geico') && paymentType === "card" &&
                                                <>
                                                    <h1 className="pt-2 pb-2 text-center">Your payment is successful!</h1>
                                                    <p className="text-center">Thank you so much for your payment! Your confirmation number is {confirmationNumber}. We are so excited to have you on board. You can download a copy of your receipt <a target="_blank" href={subDomainUrl.toLowerCase() === 'flux' ? "/assets/Content/dummy/ShowMeAlternativesLLC-DOBinderflux.pdf" : PaymentReceipt} download>here</a>. If you have any questions, please contact our agency. </p>
                                                </>
                                            }
                                            {(paymentStatus === 'processing' || paymentStatus === 'requires_action') && paymentType === "card" &&
                                                <>
                                                    <h1 className="pt-2 pb-2 text-center">Your payment is processing!</h1>
                                                    <p className="text-center">Thank you so much for your payment! Your confirmation number is {confirmationNumber}. Your Payment is still processing, So Please refresh this page after sometime. If you have any questions, please contact our agency. </p>
                                                </>
                                            }
                                            {(paymentStatus === 'requires_payment_method' && paymentType === "card") &&
                                                <>
                                                    <h1 className="pt-2 pb-2 text-center">We are unable to process your payment. </h1>
                                                    <p className="text-center">Please re-enter your payment information or use an alternative payment method. </p>
                                                </>
                                            } */}
                                            {(paymentType !== "card" && paymentStatus !== 'scheduled') &&
                                                <>
                                                    <h1 className="pt-2 pb-2 text-center">Your payment is processing!</h1>
                                                    <p className="text-center">Thank you so much for your payment! Your
                                                        confirmation number is {confirmationNumber}. The ACH payment can
                                                        take a couple of business days to clear, and you will receive an
                                                        email with a final confirmation, or if there is any issue with your payment. </p>
                                                </>
                                            }
                                            <div className="button-container">
                                                <Button to={"/" + subDomainUrl} className="btn btn-newDesign btn-large">Back to dashboard</Button>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <SiteFooter />  {/*this call for site footer */}
                </div>
                :
                <>
                    <main>
                        <div className="container form-space p-space">
                            <div className="row justify-content-md-center">
                                <div className="col-md-auto">
                                    <div className="Paymentcard">
                                        {console.log(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo) }
                                        {subDomainUrl.toLowerCase() !== 'payment-status' &&
                                            <>
                                            <div className="PaymentLogoWrapper">
                                                <img src={JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo} width="" height="" alt="Partner Logo" className="PaymentLogoImg" />
                                            </div>
                                                {(paymentType !== "card" && paymentStatus !== 'scheduled') &&
                                                <>
                                                        <div className="button-container">
                                                            <Button to={"/" + subDomainUrl} className="btn btn-newDesign btn-large">Back to Login</Button>
                                                        </div>
                                                        <h1 className="pt-2 pb-2 text-center">Your payment is processing!</h1>
                                                        <p className="text-center">Thank you so much for your payment! Your
                                                            confirmation number is {confirmationNumber}. The ACH payment can
                                                            take a couple of business days to clear, and you will receive an
                                                            email with a final confirmation, or if there is any issue with your payment. </p>
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <SiteFooter />
                </>
            }
        </>
    )
}